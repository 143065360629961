import React, { useState, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import CarTable from '../components/CarTable';
import { fetchAllCars, searchCars } from '../store/slices/carSlice';
import Loader from '../components/Loader';
import { useLocation } from 'react-router-dom';

const CarLists = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const apiLimit = 10;
  const pageSize = 10;
  const location = useLocation();
  const updatedStatus = location.state?.status || 'pending';
  const [selectedTab, setSelectedTab] = useState(updatedStatus);

  const getDefaultHeaders = () => [
    'S/N',
    'Car Brand',
    'Car Model',
    'Availability',
    'Trips Completed',
    'Rating',
    'Price/Day',
    'Status',
    'Car Owner',
    'Location',
    'Others',
  ];

  const getHeaders = (tab) => {
    if (tab === 'Approved') return getDefaultHeaders();
    if (tab === 'Pending') return ['S/N', 'Car Brand', 'Car Model', 'Availability', 'Trips Completed', 'Price/Day', 'Car Owner', 'Location', 'Others'];
    if (tab === 'Declined') return ['S/N', 'Car Brand', 'Car Model', 'Availability', 'Trips Completed', 'Price/Day', 'Car Owner', 'Location', 'Others'];
    return getDefaultHeaders();
  };

  const tableHeaders = getHeaders(selectedTab);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1500); 

    return () => clearTimeout(timerId);
  }, [searchTerm]);
  
  useEffect(() => {
    const fetchCars = async () => {
      try {
        if (debouncedSearchTerm.trim()) {
          await dispatch(searchCars(debouncedSearchTerm));
          setCurrentPage(1); 
        } else {
          await dispatch(fetchAllCars({
            queryParams: {
              status: selectedTab,
              limit: apiLimit,
              skip: (currentPage - 1) * apiLimit
            }
          }));
        }
      } catch (error) {
        console.error('Error fetching users:', error.message);
      }
    };

    fetchCars();
  }, [dispatch, debouncedSearchTerm, currentPage, apiLimit, selectedTab]);

  const carState = useSelector((state) => state.car);
  const allCars = carState.allCars;
  const count = carState.carCount;
  const loading = carState.isLoading;

  const filterCars = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return allCars.slice(startIndex, endIndex);
  };

  let tableData = [];

  if (allCars) {
    tableData = filterCars().map((car, index) => ({
      'S/N': index + 1,
      'Car Brand': car.brandName,
      'Car Model': car.modelName,
      'Plate Number': car?.plateNumber,
      'Availability': car.availability,
      'Trips Completed': car.trip,
      'Rating': car.rating,
      'Price/Day': car.pricePerDay,
      'Status': car.status,
      'Car Owner': {
        userID: car.partner.userID,
        fullName: car.partner.fullName,
        profilePic: car.partner.profilePic,
      },
      'Location': car.state,
      'Others': {
        Text: 'View More',
        carID: car.carID,
      },
    }));
  }

  const handlePageChange = (direction) => {
    if (direction === 'next') setCurrentPage((prev) => prev + 1);
    else if (direction === 'prev' && currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="w-full py-4">
      <div className="flex gap-4 items-center mb-8">
        <div className="w-[150px] lg:w-[200px] relative">
          <input
            className="w-full py-2.5 pe-2 ps-10 outline-none text-sm bg-transparent border border-grey-3 rounded-lg text-black"
            type="text"
            placeholder="Search Cars..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <AiOutlineSearch className="absolute top-3.5 left-3 text-black" />
        </div>
      </div>
      {!debouncedSearchTerm.trim() && (
        <div className="overflow-x-scroll hide-scrollbar">
          <div className="flex gap-4 mb-4">
            <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'pending' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => setSelectedTab('pending')}
            >
              Pending Cars
              {selectedTab === 'pending' && (
                <span className="bg-yellow-500 ml-1 text-white rounded-md px-3 py-1 text-[12px]">{count}</span>
              )}
            </button>
            <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'approved' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => setSelectedTab('approved')}
            >
              Approved Cars
              {selectedTab === 'approved' && (
                <span className="bg-success ml-1 text-white rounded-md px-3 py-1 text-[12px]">{count}</span>
              )}
            </button>
            <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'declined' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => setSelectedTab('declined')}
            >
              Declined Cars
              {selectedTab === 'declined' && (
                <span className="bg-red-800 ml-1 text-white rounded-md px-3 py-1 text-[12px]">{count}</span>
              )}
            </button>
            <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'suspended' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => setSelectedTab('suspended')}
            >
              Suspended Cars
              {selectedTab === 'suspended' && (
                <span className="bg-red-500 ml-1 text-white rounded-md px-3 py-1 text-[12px]">{count}</span>
              )}
            </button>
          </div>
        </div>
      )}
      {loading ? (
        <Loader loading={loading} />
      ) : tableData.length > 0 ? (
        <div className="overflow-x-scroll scrollbar flex flex-col justify-between min-h-[60vh]">
          <CarTable data={tableData} headers={tableHeaders} />
        </div>
      ) : (
        <div className="flex flex-col h-[60vh] items-center justify-center">
          <p>No cars found</p>
        </div>
      )}
      {!debouncedSearchTerm.trim() && (
        <div className="flex justify-center mt-4">
          <button
            className={`py-2 px-4 rounded mr-2 font-bold 
              ${currentPage === 1 ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-brand hover:bg-comp-2 text-white'}
            `}
            onClick={() => handlePageChange('prev')}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className={`py-2 px-4 rounded font-bold 
              ${tableData && tableData.length < pageSize ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-brand hover:bg-comp-2 text-white'}
            `}
            onClick={() => handlePageChange('next')}
            disabled={tableData && tableData.length < pageSize}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default CarLists;
