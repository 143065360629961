import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getPaymentListApi, countPaymentListApi, addPaymentListApi, searchPaymentApi } from '../../helpers/api'; // Import your API functions

export const getPaymentList = createAsyncThunk(
  'payment/getPaymentList',
  async ({ queryParams }) => {
    try {
      const response = await getPaymentListApi({queryParams});
      if (response.status_code === 200) {
        toast.success(response.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

export const countPaymentList = createAsyncThunk(
  'payment/countPaymentList',
  async (_, { dispatch }) => {
    try {
      const response = await countPaymentListApi();
      return response.data;
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

export const addPaymentList = createAsyncThunk(
  'payment/addPaymentList',
  async ({ paymentID, paymentReference, paymentDate, paymentStatus }, { dispatch }) => {
    try {
      const response = await addPaymentListApi({ paymentID, paymentReference, paymentDate, paymentStatus });
    if (response.status_code === 200) {
      toast.success(response.message);

      dispatch(getPaymentList({ 
        queryParams: { 
          paymentStatus: paymentStatus, 
          limit: 100, 
          skip: 0 
        }
      }));
    }
    return response.data;
      
    } catch (error) {
      toast.error(error.message)
    }
  }
);

export const searchPayment = createAsyncThunk(
  'payment/searchPayment',
  async (searchTerm) => {
    const response = await searchPaymentApi(searchTerm);
    return response;
  }
);


const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    paymentList: [],
    count: 0,
    status: 'idle',
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentList.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getPaymentList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.paymentList = action.payload;
        state.loading = false;
      })
      .addCase(getPaymentList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(countPaymentList.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(countPaymentList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.count = action.payload;
        state.loading = false;
      })
      .addCase(countPaymentList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(addPaymentList.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(addPaymentList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        // You can handle state updates after adding payment if needed
      })
      .addCase(addPaymentList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default paymentSlice.reducer;
