import React from 'react';

const NotFound = () => {
  return (
    <main className="w-full flex bg-main-bg h-screen justify-center items-center text-black text-center">
      <div>
        <h1 className='text-3xl font-semibold mb-4'>404 Error - Page Not Found</h1>
        <p className='font-medium'>The page you are looking for does not exist.</p>
      </div>
    </main>
  );
};

export default NotFound;
