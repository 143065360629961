import React from "react";

const ProgressLine = ({ progress, unshadedColor, shadedColor }) => {
  return (
    <div className={`relative h-2 bg-blue-500 rounded font-poppins ${unshadedColor}`}>
        <div className={`absolute top-0 left-0 h-2 bg-blue-700 rounded ${shadedColor}`} style={{ width: `${progress}%`}}></div>
      <div className={`relative h-2 rounded ${unshadedColor}`}>
        <div
        className={`absolute top-0 left-0 h-2 rounded ${shadedColor}`}
        style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressLine;
