import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import { addBrandModel, updateBrandModel } from '../../store/slices/vehicleSlice'; // Import updateBrandModel action
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const ModelForm = ({ selectedBrand, onClose, setSelectedTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle); 
  const loading = vehicle.isLoading;
  const brands = vehicle.brands || []; 
  const goBack = () => {
    navigate(-1);
  };



  const initialValues = {
    name: selectedBrand ? selectedBrand.modelName : '',
    brandCode: selectedBrand ? selectedBrand.brandCode : '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Model name is required')
      .min(2, 'Model name must be at least 2 characters')
      .max(50, 'Model name must be at most 50 characters'),
    brandCode: Yup.string().required('Brand is required'),
  });

  const handleSubmit = async (values) => {
    if (selectedBrand) {
      dispatch(updateBrandModel({ modelCode: selectedBrand.modelCode, name: values.name, brandCode: values.brandCode }));
      onClose();
    } else {
      dispatch(addBrandModel({ name: values.name, brandCode: values.brandCode }));
      setSelectedTab("list");
    }
  };

  return (
    <>
      <div className="w-full md:w-1/3">
        <div className="flex items-center gap-3 h-12 hover:cursor-pointer" onClick={goBack}>
          <button
            className="rounded-[100%] bg-primary flex p-2 justify-center items-center"
          >
            <BsArrowLeft className="text-white font-bold h-4 w-4" />
          </button>
          <h4 className="text-md text-tertiary font-semibold">Back</h4>
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
          {({ isSubmitting, setFieldValue }) => (
            <Form className="w-full flex flex-col gap-4 mt-6">
              <div className="flex flex-col w-full gap-2">
                <label className="text-[14px] font-semibold text-custom-secondary">
                  Select Brand
                </label>
                <Field
                  id="brandCode"
                  name="brandCode"
                  as="select"
                  className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
                >
                  <option value="">Select Brand Model</option>
                    {brands.map((brand) => (
                      <option key={brand?.brandCode} value={brand?.brandCode}>
                        {brand?.brandName}
                      </option>
                    ))}
                </Field>
              </div>
              <div className="flex flex-col w-full gap-2">
                <label className="text-[14px] font-semibold text-custom-secondary">
                  Enter Model
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Input Model Name"
                  className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
                />
                <ErrorMessage name="name" component="div" className="text-red-500" />
              </div>
              <button
                type="submit"
                className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                disabled={loading}
              >
                {loading ? (
                  <ClipLoader
                    color="custom-primary"
                    loading="loading"
                    css="override"
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  `${selectedBrand ? 'Update' : 'Save'} Model`
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ModelForm;
