import React from 'react'
import LoginForm from '../../containers/Auth/LoginForm'

const Login = () => {
  return (
    <div>
        <LoginForm/>
    </div>
  )
}

export default Login