import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { addTransmission, updateTransmission } from '../../store/slices/vehicleSlice'; // Import updateTransmission action
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';

const TransmissionForm = ({ onClose, setShowContent, setDataDisplay, editItem, setEditItem }) => {
    const dispatch = useDispatch();

    const goBack = () => {
        setDataDisplay("default");
        setEditItem(null)

    };

    const initialValues = {
        name: editItem ? editItem.transmissionName : '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Transmission name is required')
            .min(2, 'Transmission name must be at least 2 characters')
            .max(50, 'Transmission name must be at most 50 characters'),
    });

    const handleSubmit = async (values) => {
        if (editItem) {
            dispatch(updateTransmission({ transmissionCode: editItem.transmissionCode, name: values.name }));
        } else {
            dispatch(addTransmission({ name: values.name }));
        }
        setShowContent("list");
        onClose();
    };

    const vehicleState = useSelector(state => state.vehicle);
    const loading = vehicleState.isLoading;

    return (
        <>
            <div className="w-full">
                <div className='flex flex-col items-center'>
                    <button className='rounded-[100%] bg-primary flex h-8 w-8 justify-center items-center mb-3' onClick={goBack}>
                        <BsArrowLeft className='text-white font-bold h-4 w-4' />
                    </button>
                    <h4 className='text-lg text-tertiary font-semibold'>{editItem ? 'Update' : 'Add'} Transmission</h4>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting }) => (
                        <Form className='w-full flex flex-col gap-4 mt-6'>
                            <div className={`flex flex-col w-full gap-2 `}>
                                <label className='text-[14px] font-semibold text-custom-secondary'>Enter Vehicle Transmission</label>
                                <Field
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Input Vehicle Transmission"
                                    className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                                />
                                <ErrorMessage name="name" component="div" className="text-red-500 text-sm" /> 
                            </div>
                            
                            <button
                                type="submit"
                                className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                                disabled={loading}
                            >
                                {loading ? (
                                    <ClipLoader
                                        color="custom-primary"
                                        loading="loading"
                                        css="override"
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                ) : (
                                    `${editItem ? 'Update' : 'Save'} Transmission`
                                )}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default TransmissionForm;
