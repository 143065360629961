import React, { useState } from 'react';
import CarCard1 from '../components/CarCard1';
import { listedCar } from '../../../assets';
import { Link } from 'react-router-dom';

const ListedCar = ({userCars}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % userCars.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? userCars.length - 1 : prevIndex - 1));
    };

    if (!userCars) {
        return null;
    }

    const isLastCard = currentIndex === userCars.length - 1;
    const isFirstCard = currentIndex === 0;

    return (
        <div className='w-full relative pb-10 border-b-[1px]'>
            <button
                className={`absolute top-1/2 -left-5 transform -translate-y-1/2 bg-brand px-4 py-2 rounded-[100%] z-20 ${isFirstCard ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={prevSlide}
                disabled={isFirstCard}
            >
                {'<'}
            </button>

            <h4 className='text-grey-7 font-semibold mb-4 text-[18px]'>Listed Car ({userCars.length})</h4>
            <div className='w-full overflow-hidden'>
                <div
                    className='w-full gap-6 transition-transform duration-300 ease-in-out'
                    style={{
                        transform: `translateX(-${currentIndex * 25}%)`, // Adjusted translation
                        display: 'flex',
                    }}
                >
                    {userCars.map((card, index) => (
                        <div
                            key={index}
                            style={{
                                flex: '0 0 30%', 
                                justifyContent: 'space-between'
                            }}
                        >
                            <Link to={`/admin/manage-cars/${card.carID}`}>
                                <CarCard1
                                    status={card.status}
                                    availability={card.availability}
                                    photoUrl={card.photoUrl}
                                    brandName={card.brandName}
                                    brandModelName={card.brandModelName}
                                    pricePerDay={card.pricePerDay}
                                />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <button
                className={`absolute top-1/2 -right-5 transform -translate-y-1/2 bg-brand px-4 py-2 rounded-full ${isLastCard ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={nextSlide}
                disabled={isLastCard}
            >
                {'>'}
            </button>
        </div>
    );
};

export default ListedCar;
