import React from 'react'
import BrandLists from '../../containers/MasterData/BrandLists'

const Brands = () => {
  return (
    <BrandLists/>
    
  )
}

export default Brands