import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSearch } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import OrderTable from '../components/OrderTable';
import Loader from '../components/Loader';
import { fetchAllTrips, searchTrips } from '../store/slices/tripSlice';

const RentLists = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const apiLimit = 10;
  const pageSize = 10;

  const initialTab = location.state?.tab || 'chauffeur';
  const initialStatus = location.state?.status || 'pending';

  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);



  const getHeaders = (selectedTab, selectedStatus) => {
    let headers = ['S/N', 'Trip ID', 'Timer','Car Brand', 'Car Model', 'Duration', 'Total Cost', 'Trip Type', 'Request Profile', 'Trip Status', 'Admin Approval',  'Others'];

    if (selectedStatus !== 'active') {
      headers = headers.filter(header => header !== 'Timer');
    }

    if (selectedTab === 'chauffeur') {
      headers = headers.filter(header => header !== 'Admin Approval');
    }

    return headers;
  };

  const tableHeaders = getHeaders(selectedTab, selectedStatus);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchAllTrips({ 
  //     queryParams: { 
  //       tripType: selectedTab, 
  //       status: selectedStatus,  
  //       limit: 100, 
  //       skip: 0
  //     }}));
  // }, [dispatch, selectedTab, selectedStatus]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1500); 

    return () => clearTimeout(timerId);
  }, [searchTerm]);
    
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        if (debouncedSearchTerm.trim()) {
          await dispatch(searchTrips(debouncedSearchTerm));
          setCurrentPage(1); 
        } else {
          await dispatch(fetchAllTrips({
            queryParams: {
              tripType: selectedTab,
              status: selectedStatus,  
              limit: apiLimit,
              skip: (currentPage - 1) * apiLimit
            }
          }));
        }
      } catch (error) {
        console.error('Error fetching users:', error.message);
      }
    };

    fetchTrips();
  }, [dispatch, debouncedSearchTerm, currentPage, apiLimit, selectedTab, selectedStatus]);
  

  const tripsState = useSelector(state => state.trip);
  const allTrips = tripsState.allTrips || [];
  const count = tripsState.tripCount;
  const loading = tripsState.isLoading;

  const filterOrders = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return allTrips
      .filter(trip => trip.tripType === selectedTab && trip.status === selectedStatus)
      .slice(startIndex, endIndex)
      .map((trip, index) => ({ ...trip, serialNumber: startIndex + index + 1 }));
  };

  const handlePageChange = (direction) => {
    if (direction === 'next') {
      setCurrentPage(prevPage => prevPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div className="w-full py-4">
      <div className='flex gap-4 items-center mb-8'>
        <div className="w-[150px] lg:w-[200px] relative">
          <input
            className="w-full py-2.5 pe-2 ps-10 outline-none text-sm bg-transparent border border-grey-3 rounded-lg text-black"
            type="text"
            placeholder="Search Trip..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <AiOutlineSearch className="absolute top-3.5 left-3 text-black" />
        </div>
        <h4 className='font-semibold text-grey-7 text-[18px]'>Total Orders <span className='font-normal'> ({count}) </span></h4>
      </div>

      {!debouncedSearchTerm.trim() && (
        <div className='overflow-x-scroll hide-scrollbar'>
          <div className='flex gap-4 mb-4'>
            <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'chauffeur' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => {
                setSelectedTab('chauffeur');
                setSelectedStatus('active');
              }}
            >
              Chauffeur
              {selectedTab === 'chauffeur' && filterOrders.length > 0 ? (
                <span className='bg-red-500 p-1 rounded-md text-[12px] ml-1 text-white'>{count} new trips</span>
              ) : (
                <span className='bg-green-500 p-1 rounded-md text-[12px] ml-1 text-white'>{count} new trips</span>
              )}
            </button>
            <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'selfDrive' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => {
                setSelectedTab('selfDrive');
                setSelectedStatus('pending');
              }}
            >
              Self Drive 
              {selectedTab === 'selfDrive'  && filterOrders.length > 0 ? (
                <span className='bg-red-500 p-1 rounded-md text-[12px] ml-1 text-white'>{count} new trips</span>
              ) : (
                <span className='bg-green-500 p-1 rounded-md text-[12px] ml-1 text-white'>{count} new trips</span>
              )}
            </button>
          </div>
        </div>
      )}
      {!debouncedSearchTerm.trim() && (
        <div className=''>
          <h4 className='text-grey-5 font-semibold mb-4'>FILTER</h4>
          <div className='flex gap-4 mb-4 overflow-x-scroll hide-scrollbar'>
            {selectedTab === 'chauffeur' && (
              <>
                <button
                  className={`border whitespace-nowrap text-grey-7 rounded-md py-2 px-4 ${selectedStatus === 'pending' ? 'bg-brand text-white' : 'text-grey-7'}`}
                  onClick={() => setSelectedStatus('pending')}
                >
                  Pending
                </button>
                <button
                  className={`border whitespace-nowrap text-grey-7 rounded-md py-2 px-4 ${selectedStatus === 'active' ? 'bg-brand text-white' : 'text-grey-7'}`}
                  onClick={() => setSelectedStatus('active')}
                >
                  Active
                </button>
                <button
                  className={`border text-grey-7 rounded-md py-2 px-4 ${selectedStatus === 'completed' ? 'text-white bg-brand' : ''}`}
                  onClick={() => setSelectedStatus('completed')}
                >
                  Completed
                </button>
              </>
            )}
            {selectedTab === 'selfDrive' && (
              <>
                <button
                  className={`border text-grey-7 rounded-md py-2 px-4 ${selectedStatus === 'pending' ? 'bg-brand text-white' : 'text-grey-7'}`}
                  onClick={() => setSelectedStatus('pending')}
                >
                  Pending
                </button>
                <button
                  className={`border text-grey-7 rounded-md py-2 px-4 ${selectedStatus === 'active' ? 'bg-brand text-white' : 'text-grey-7'}`}
                  onClick={() => setSelectedStatus('active')}
                >
                  Active
                </button>
                <button
                  className={`border text-grey-7 rounded-md py-2 px-4 ${selectedStatus === 'completed' ? 'text-white bg-brand' : ''}`}
                  onClick={() => setSelectedStatus('completed')}
                >
                  Completed
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {loading ? (
        <Loader loading={loading}/>
      ) : (
        filterOrders().length > 0 ? (
          <div className='overflow-x-scroll flex flex-col justify-between min-h-[60vh] scrollbar'>
            <OrderTable data={filterOrders()} headers={tableHeaders} />
          </div>
        ):(
          <div className='flex flex-col h-[60vh] items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" width="145" height="144" viewBox="0 0 145 144" fill="none">
                <path opacity="0.2" d="M126.5 31.5V112.5C126.5 113.693 126.026 114.838 125.182 115.682C124.338 116.526 123.193 117 122 117H23C21.8065 117 20.6619 116.526 19.818 115.682C18.9741 114.838 18.5 113.693 18.5 112.5V31.5C18.5 30.3065 18.9741 29.1619 19.818 28.318C20.6619 27.4741 21.8065 27 23 27H122C123.193 27 124.338 27.4741 125.182 28.318C126.026 29.1619 126.5 30.3065 126.5 31.5Z" fill="#DAC1D9"/>
                <path d="M122 22.5H23C20.6131 22.5 18.3239 23.4482 16.636 25.136C14.9482 26.8239 14 29.1131 14 31.5V112.5C14 114.887 14.9482 117.176 16.636 118.864C18.3239 120.552 20.6131 121.5 23 121.5H122C124.387 121.5 126.676 120.552 128.364 118.864C130.052 117.176 131 114.887 131 112.5V31.5C131 29.1131 130.052 26.8239 128.364 25.136C126.676 23.4482 124.387 22.5 122 22.5ZM122 112.5H23V31.5H122V112.5ZM99.5 49.5C99.5 56.6608 96.6554 63.5284 91.5919 68.5919C86.5284 73.6554 79.6608 76.5 72.5 76.5C65.3392 76.5 58.4716 73.6554 53.4081 68.5919C48.3446 63.5284 45.5 56.6608 45.5 49.5C45.5 48.3065 45.9741 47.1619 46.818 46.318C47.6619 45.4741 48.8065 45 50 45C51.1935 45 52.3381 45.4741 53.182 46.318C54.0259 47.1619 54.5 48.3065 54.5 49.5C54.5 54.2739 56.3964 58.8523 59.7721 62.2279C63.1477 65.6036 67.7261 67.5 72.5 67.5C77.2739 67.5 81.8523 65.6036 85.2279 62.2279C88.6036 58.8523 90.5 54.2739 90.5 49.5C90.5 48.3065 90.9741 47.1619 91.818 46.318C92.6619 45.4741 93.8065 45 95 45C96.1935 45 97.3381 45.4741 98.182 46.318C99.0259 47.1619 99.5 48.3065 99.5 49.5Z" fill="#ECAE00"/>
            </svg>
            <p className='text-[24px] font-light text-black mt-4'>
                No data yet
            </p>
            </div>
        )
      )}
      {filterOrders().length > 0 && (
        !debouncedSearchTerm.trim() && (
        <div className="flex justify-center mt-4">
          <button
            className="bg-brand hover:bg-comp-2 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => handlePageChange('prev')}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="bg-brand hover:bg-comp-2 text-white font-bold py-2 px-4 rounded"
            onClick={() => handlePageChange('next')}
            disabled={filterOrders().length < pageSize}
          >
            Next
          </button>
        </div>
        )
      )}
    </div>
  );
};

export default RentLists;
