import React from 'react'
import BroadcastForm from '../containers/BroadcastForm'

const SendBroadcast = () => {
  return (
    <div className='w-full lg:w-[40%] flex flex-col gap-8'>
      <BroadcastForm/>
    </div>
  )
}

export default SendBroadcast