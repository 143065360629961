import React, { useEffect } from 'react';
import KycInfo from '../containers/KycInfo';
import ContactInfo from '../containers/ContactInfo';
import ProfileInfo from '../containers/ProfileInfo';
import ListedCar from '../containers/ListedCar';
import ListedReview from '../containers/ListedReview';
import ListedDrivers from '../containers/ListedDrivers';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
  fetchUserCars, 
  fetchUserDrivers, 
  fetchUserKYC, 
  fetchUserPaymentBank, 
  fetchUserProfile, 
  fetchUserReview, 
  resetUserState 
} from '../store/slices/userSlice';
import Loader from '../components/Loader';

const UserProfile = () => {
  let { userID } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(resetUserState());
        await Promise.all([
          dispatch(fetchUserProfile({ userID })),
          dispatch(fetchUserReview({ userID, limit: 10, skip: 0 })),
          dispatch(fetchUserKYC({ userID })),
          dispatch(fetchUserPaymentBank({ userID })),
          dispatch(fetchUserCars({ userID, limit: 10, skip: 0 })),
          dispatch(fetchUserDrivers({ userID, limit: 10, skip: 0 }))
        ]);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [dispatch, userID]);

  const loading = useSelector(state => state.user.isLoading);
  const userProfile = useSelector(state => state.user.userProfile);
  const userReview = useSelector(state => state.user.userReview);
  const userKYC = useSelector(state => state.user.userKYC);
  const userPaymentBank = useSelector(state => state.user.userPaymentBank);
  const userCars = useSelector(state => state.user.userCars);
  const userDrivers = useSelector(state => state.user.userDrivers);

  const [userProfileObj = {}] = userProfile || [];
  const { fullName } = userProfileObj;

  if (loading || !userProfile) {
    return (
        <Loader loading={loading}/>
    );
  }

  return (
    <div className='w-full flex flex-col gap-12'>
      <div className=''>
          <h4 className='font-semibold text-grey-4 text-[16px] py-4'>Total Users / <span className='text-grey-7'> {fullName}</span></h4>
          <ContactInfo userProfile={userProfile} userPaymentBank={userPaymentBank} />
      </div>
      <div className='flex flex-col lg:flex-row gap-3 h-full'>
          <div className='w-full lg:w-1/3'>
              <ProfileInfo userProfile={userProfile}/>
          </div>
          <div className='w-full lg:w-2/3'>
              <KycInfo userKYC={userKYC}/>
          </div>
      </div>
      <ListedCar userCars={userCars}/>
      <ListedReview userReview={userReview} />
      <ListedDrivers userDrivers={userDrivers}/>
    </div>
  )
}

export default UserProfile;
