import React from 'react';

import { statusIcon, wheel } from '../../assets';
import { Link } from 'react-router-dom';
import useImageLoader from './useImageLoader';

const CarTable = ({ headers, data }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();
    
  return (
    <table className="w-full table-auto text-grey-7">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header} className="text-left px-8 py-4 uppercase text-[12px] whitespace-nowrap text-gray-500">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className={`${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'} hover:cursor-pointer hover:bg-comp-7`}>
            {headers.map((header) => (
              <td key={header} className="py-2 px-8 whitespace-nowrap">
                {header === 'Car Brand' ? (
                  <div className="flex items-center gap-2">
                    <div className='w-6 h-6 flex justify-center items-center bg-black text-white rounded-sm'>
                    <img
                        src={wheel}
                        alt="Wheel"
                        onLoad={handleImageLoad}
                        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                    </div>
                    {row['Car Brand']}
                  </div>
                ) : header === 'Car Model' ? (
                  <div className="text-grey-7 font-medium flex flex-col gap-1">
                    <p>{row['Car Model']}</p>
                    <p className='text-xs capitalize'>{row['Plate Number']}</p>
                  </div>
                
               ) : header === 'Car Owner' ? (
                <div className="flex items-center gap-2">
                    <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                        <img
                            src={row['Car Owner'].profilePic}
                            className='w-4 h-4 rounded-full'
                            alt="Customer Pic"
                            onLoad={handleImageLoad}
                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                    </div>
                    <div className='flex flex-col gap-1'>
                        {row['Car Owner'].fullName}
                        <Link to={`/user-management/${row['Car Owner'].userID}`}><button className='text-success underline text-[14px]' onClick={() => console.log('Details clicked')}>See Profile</button></Link>
                    </div>
                </div>
                 ) : header === 'Status' ? (
                    <div className={`text-[14px] flex gap-3 items-center ${row['Status'] === 'suspended' ? 'text-failed' : 'text-black'}`}>
                      <div className={`h-6 w-6 rounded-full ${row['Status'] === 'suspended' ? 'bg-failed' : 'bg-black'} flex items-center justify-center`}>
                        <img
                          src={statusIcon}
                          className='w-4 h-4 rounded-full'
                          alt="Customer Pic"
                          onLoad={handleImageLoad}
                          style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                      </div>
                      {row['Status']}
                    </div>
                ) : header === 'Others' ? (
                      <Link to={`${row['Others'].carID}`}>
                        <button className='text-warning text-[14px]' onClick={() => console.log('Details clicked')}>{row['Others'].Text}</button>
                      </Link>
               ) : (
                  row[header]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CarTable;
