import React from 'react';
import TripStatus from '../components/TripStatus';
import { rocket, wallet } from '../../../assets';

const TripStats = () => {
  const statusList = [
    { number: 23400, statusType: 'Ongoing Trip', svgPath: rocket, progress: 75, },
    { number: 45000, statusType: 'Pending Order', svgPath: wallet, progress: 50, }
  ]


  return (
    <div className='w-full'>
      <TripStatus data={statusList} />

    </div>
  );
};

export default TripStats;
