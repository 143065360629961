import React, { useEffect, useState } from 'react';
import { updateAdminStatus } from '../store/slices/adminSlice';
import { useDispatch } from 'react-redux';
import useImageLoader from './useImageLoader';

const AdminTable = ({ headers, adminData }) => {
  const { imageLoaded, handleImageLoad } = useImageLoader();
  const [checkedStatus, setCheckedStatus] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const initialCheckedStatus = {};
    adminData.forEach((row, index) => {
      initialCheckedStatus[index] = row.status === true; // Check if status is true (boolean value)
    });
    setCheckedStatus(initialCheckedStatus);
  }, [adminData]);

  const handleStatusChange = async (userID, status, index) => {
    try {
      const newStatus = !checkedStatus[index]; 
      await dispatch(updateAdminStatus({ userID, status: newStatus }));

      setCheckedStatus({
        ...checkedStatus,
        [index]: newStatus
      });


    } catch (error) {
      console.error('Error updating admin status:', error.message);
    }
  };

  return (
    <table className="w-full table-auto text-grey-6">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header} className="px-6 py-3 text-left whitespace-nowrap">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {adminData.map((row, index) => (
          <tr key={index} className={`px-6 hover:cursor-pointer hover:bg-comp-7 ${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}`}>
            {headers.map((header) => (
              <td key={header} className="px-6 py-3">
                {header === 'Name' ? (
                  <div className="flex items-center gap-4">
                    <div className='w-8 h-8 flex justify-center items-center text-white rounded-md'>
                      <img
                        src={row.profilePic}
                        className='w-8 h-8 rounded-md'
                        alt="Customer Pic"
                        onLoad={handleImageLoad}
                        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <p className='text-[14px] capitalize font-semibold text-grey-6'>{row.fullName}</p>
                    </div>
                  </div>
                ) : header === 'Email' ? (
                  <div className='flex flex-col gap-1'>
                    <p className='text-[14px] font-semibold text-grey-6'>{row.emailAddress}</p>
                  </div>
                ) : header === 'Phone Number' ? (
                  <div className='flex flex-col gap-1'>
                    <p className='text-[14px] font-semibold text-grey-6'>{row.phoneNumber}</p>
                  </div>
                ) : header === 'Role' ? (
                  <div className='flex flex-col gap-1'>
                    <p className='text-[14px] font-semibold text-grey-6'>{row.userType}</p>
                  </div>
                ) : header === 'Status' ? (
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={checkedStatus[index] || false}
                      onChange={() => handleStatusChange(row.userID, row.status, index)}
                      className="sr-only peer"
                    />

                    <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-comp-6 dark:peer-focus:ring-comp-6 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary`}></div>
                  </label>
                ) : (
                  row[header]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AdminTable;
