import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../Loader';
import {  deleteCity } from '../../store/slices/vehicleSlice';
import { useDispatch } from 'react-redux';
import UpdateModal from './UpdateModal';
import CityForm from './CityForm';
import { showCustomConfirmAlert } from '../CustomConfirmAlert'; // Step 1: Import showCustomConfirmAlert
import { toast } from 'react-toastify';

const CityTable = ({ dataList, loading, stateCode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);

  const openModal = (city) => {
      setSelectedCity(city);
      setIsModalOpen(true);
  };
  
  const closeModal = () => {
      setSelectedCity(null);
      setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const { stateName } = location.state;

  const handleDeleteCity = (cityCode, stateCode) => {
    showCustomConfirmAlert(() => {
      dispatch(deleteCity({ cityCode, stateCode }))
       .unwrap()
       .catch(error => {
          toast.error(error.message);
        });
    });
  };

  return (
    <div className='w-full'>
      {loading? (
          <Loader loading={loading}/>
      ) : (
        <table className='w-full table-auto text-grey-6'>
          <thead>
            <tr>
              <th className='px-6 py-3 text-left whitespace-nowrap'>S/N</th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>State</th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>City</th>
              <th className='px-6 py-3 text-left whitespace-nowrap'></th>
              <th className='px-6 py-3 text-left whitespace-nowrap'></th>
              <th className='px-6 py-3 text-left whitespace-nowrap'></th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((data, index) => (
              <tr key={index} className={`px-6 ${index % 2 === 0? 'bg-comp-6' : 'bg-transparent'}`}>
                <td className='px-6 py-3 whitespace-nowrap'>{index + 1}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{stateName}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{data?.cityName}</td>
                <td className='px-6 py-3 whitespace-nowrap'>
                  <button
                      className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                      onClick={() => handleDeleteCity(data?.cityCode, data?.stateCode)} // Modified line
                  >
                      Delete City
                  </button>
                </td>
                <td className='px-6 py-3 whitespace-nowrap'>
                  <button
                      className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                      onClick={() => openModal(data)}
                  >
                      Edit City
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <UpdateModal isOpen={isModalOpen} onClose={closeModal}>
          <CityForm selectedCity={selectedCity} onClose={closeModal}/>
      </UpdateModal>
    </div>
  );
};

export default CityTable;
