import React from 'react';

const PaymentCard = ({ data }) => {
  
  //   const dateFrom = new Date(dateTimeFrom);
  // const timeFrom = dateFrom.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // const dateTo = new Date(dateTimeTo);
  // const timeTo = dateTo.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  //   const formattedDateFrom = new Date(dateFrom).toLocaleDateString('en-US', {
  //       weekday: 'short',
  //       day: 'numeric',
  //       month: 'short',
  //     });

  //     const formattedDateTo = new Date(dateTo).toLocaleDateString('en-US', {
  //       weekday: 'short',
  //       day: 'numeric',
  //       month: 'short',
  //     });
  
  return (
    <div className='w-full bg-transparent border-l-8 border-brand flex justify-between rounded-sm px-4 py-2 shadow-lg'>
      <div className='flex flex-col gap-2'>
        <p className='text-[12px] text-grey-5'>{data?.partner.userName}</p>
        <p className='text-[14px] text-grey-6 font-medium'>{data?.car.carBrand} {data?.car.carModel}</p>
        {/* <div className='flex gap-3 items-center'>
            <p className='text-[12px] text-grey-4'>
                {formattedDateFrom}, {timeFrom}
            </p>
            <span className='rounded-[100%] border border-grey-3 flex h-6 w-6 justify-center items-center'>
            <BsArrowRight className='text-grey-3' />
            </span>
            <p className='text-[12px] text-grey-4'>
                {formattedDateTo}, {timeTo}
            </p>
        </div> */}
      </div>
      {/* <p className='text-grey-6  text-[14px]'>₦<AmountFormatter amount={amount}/></p> */}
    </div>
  );
};

export default PaymentCard;
