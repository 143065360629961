import React from 'react'
import CreateAdminForm from '../containers/CreateAdminForm'

const CreateAdmin = () => {
  return (
    <div className='w-full lg:w-[40%]  flex flex-col gap-8'>
      <CreateAdminForm/>
    </div>
    
  )
}

export default CreateAdmin