import React from 'react';

const UpdateModal = ({ isOpen, onClose, children }) => {
  return (
    <div className={`fixed z-50 inset-0 ${isOpen ? 'block' : 'hidden'} overflow-y-auto`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" onClick={onClose}></div>

        <div className="relative bg-main-bg text-black  p-8 mx-auto rounded-md shadow-xl transform transition-all  flex flex-col items-center  gap-6 w-4/5 sm:w-3/5 lg:w-2/5 overflow-y-scroll scrollbar min-h-[60vh] max-h-[80vh]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
