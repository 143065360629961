import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { authenticateUserApi, verifyOtpApi, forgotPasswordApi, resetPasswordApi, changePasswordApi, resendOtpApi } from '../../helpers/api'; 
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { fetchAdminProfile } from './adminSlice';



export const authenticateUser = createAsyncThunk('auth/authenticateUser', async ({ email, password, navigate }, { dispatch }) => {
  try {
    const response = await authenticateUserApi(email, password);

    const responseData = {
      data: response.data.data,
      message: response.data.message,
      status: response.data.status_code,  
      token: response.data.data.accessToken
      
    };

    if (responseData.status === 200) {
      toast.success(responseData.message);

      Cookies.set('token', responseData.token);

      await dispatch(fetchAdminProfile({ userID: responseData.data.userID }));
      navigate('/dashboard');
    }

    return responseData;
  } catch (error) {
      toast.error(error.message);
  }
});



export const verifyOtp = createAsyncThunk('auth/verifyOtp', async ({ email, otp, navigate }) => {
  try {
    const response = await verifyOtpApi(email, otp);

    const responseData = {
      data: response.data.data,
      message: response.data.message,
      status: response.data.status_code,
      token: response.data.data.accessToken
      
    };

    if (responseData.status === 200) {
      toast.success("Otp verified successfully");
      Cookies.set('token', responseData.token);

      navigate('/auth/reset-password', { state: { email } });
    }

    return responseData;
  } catch (error) {
    toast.error(error.message)
  }
});


export const resendOtp = createAsyncThunk('auth/resendOtp', async ({ email }) => {
  try {
    const response = await resendOtpApi(email);

    const responseData = {
      data: response.data.data,
      message: response.data.message,
      status: response.data.status_code,
      
    };

    if (responseData.status === 200) {
      toast.success(responseData.message);
    }

    return responseData;
  } catch (error) {
    toast.error(error.message)
  }
});


export const forgotPassword = createAsyncThunk('auth/forgotPassword', async ({ email, navigate }) => {
  try {
    const response = await forgotPasswordApi(email);

    const responseData = {
      data: response.data.data,
      message: response.data.message,
      status: response.data.status_code,
      
    };

    if (responseData.status === 200) {
      toast.success(responseData.message);
      navigate('/auth/verify-otp', { state: { email } });
    }

    return responseData;
  } catch (error) {
    toast.error(error.message)
  }
});


export const resetPassword = createAsyncThunk('auth/resetPassword', async ({ email, password, navigate }) => {
  try {
    const response = await resetPasswordApi(email, password);
    const responseData = {
      data: response.data.data,
      message: response.data.message,
      status: response.data.status_code,
      
    };

    if (responseData.status === 200) {
      toast.success("Password reset successful!");
      navigate('/');
    }

    return responseData;
  } catch (error) {
    throw new Error('Error updating user: ' + error.message);
  }
});

export const changePassword = createAsyncThunk('auth/changePassword', async ({ password, navigate }) => {
  try {
    const response = await changePasswordApi(password);

    const responseData = {
      data: response.data.data,
      message: response.data.message,
      status: response.data.status_code,
      
    };

    if (responseData.status === 200) {
      toast.success(responseData.message);
      navigate('/profile');
    }

    return responseData;

  } catch (error) {
    toast.error(error.message)
  }
});




export const logout = () => async (dispatch) => {
  try {
    Cookies.remove('token'); 
    dispatch({ type: 'auth/logout' });

  } catch (error) {
    console.error('Error logging out:', error.message);
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: Cookies.get('token'),
    uniqueKey: null,
    user: null,
    isAuthenticated: false,
    status: 'idle',
    error: null,
    isLoading: false,
  },
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.isLoading = false;
        
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(resendOtp.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
        
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
        
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
        
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(changePassword.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
        
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase('auth/logout', (state) => {
        state.isAuthenticated = false;
        state.token = null;
        state.user = null;
        Cookies.remove('token');
      });
  },
});


export const selectAuthenticated = createSelector(
  (state) => state.auth.isAuthenticated,
  (isAuthenticated) => isAuthenticated
);

export const selectUserRole = (state) => state.auth.user?.data?.userType;

export default authSlice.reducer;
