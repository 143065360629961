import React from 'react';
import {  wheel } from '../../assets';
import DateTime from './DateTime';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { approveOrDeclineTrip } from '../store/slices/tripSlice';
import useImageLoader from './useImageLoader';

export default function OrderConfirmationModal({ message, isOpen, onClose, data }) {
  const dispatch = useDispatch();
  const { imageLoaded, handleImageLoad } = useImageLoader();
  
  if (!data) {
    return null;
  }

  const isSelfDrive = data.tripType === 'selfDrive';
  const renterData = data.renter;
  if (!renterData) {
    return null;
  }
  const tripOrder = data?.tripOrders[0];
  if (!tripOrder) {
    return null;
  }

  const handleApprove = () => {
    dispatch(approveOrDeclineTrip({
      tripID: data?.tripID, 
      status: 'approved', 
      tripOrderID: tripOrder.tripOrderID
    }));
    onClose();
  };

  // Handle decline request
  const handleDecline = () => {
    dispatch(approveOrDeclineTrip({
      tripID: data.tripID, 
      status: 'decline', 
      tripOrderID: tripOrder.tripOrderID
    }));
    onClose();
  };

  return (
    <div className={`fixed z-50 inset-0 ${isOpen ? 'block' : 'hidden'} overflow-y-auto`}>
      <div className="flex items-center justify-center h-full  mx-auto">
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" onClick={onClose}></div>

        <div className="relative bg-main-bg h-4/5 overflow-y-scroll hide-scrollbar mx-auto rounded-[4px] shadow-xl transform transition-all flex flex-col items-center gap-6 w-[90%] md:w-2/3 lg:w-2/5 p-8 lg:p-12">
          <div className='w-6 h-6 flex justify-center items-center bg-black text-white rounded-sm'>
            <img
                src={wheel}
                alt="Wheel"
                onLoad={handleImageLoad}
                style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                />
            </div>

            <h4 className='text-[18px] font-semibold text-grey-7'>{data.carBrand} {data.carModel}</h4>
            <Link to={`/manage-cars/${data.carID}`} className='text-[14px] text-primary'>See car info</Link>

            <div className='bg-[#FFF7E2] w-full flex flex-col pt-1 pb-4 px-4 rounded-sm shadow-sm'>
              <p className='text-[14px] text-grey-5 text-center'>Request Profile</p>
              <div className='w-full flex justify-between'>
                <div className='w-full flex gap-4'>
                  <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                    <img
                        src={renterData.userProfileUrl}
                        className='w-4 h-4 rounded-full'
                        alt="Customer Pic"
                        onLoad={handleImageLoad}
                        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                    />
                  </div>
                  <p className='text-tertiary text-[14px] font-semibold'>{renterData.userName}</p>
                </div>
                <Link to={`/user-management/${renterData.userID}`} className='text-success text-[14px] whitespace-nowrap'>See profile</Link>
              </div>
            </div>

            
              {data.escort === 'true' && (
                <div className='w-full flex gap-2 items-center'>
                  <div className='h-4 w-4 rounded-sm flex justify-center items-center border border-primary'>
                    <div className='w-[6px] h-[6px] bg-primary'/> 
                  </div>
                  <div className='w-full flex justify-between text-grey-7'>
                    <p className='text-grey-7'>{data.escortValue} Escort Service Needed</p>
                  </div>
                </div>
              )}
            

            <div className='w-full border-b py-4'>
              <DateTime
                dateTimeFrom={data.tripStartDate}
                dateTimeTo={data.tripEndDate}
              />
            </div>

            {isSelfDrive && (data.tripType === 'selfDrive' || 'selfDrive') ? (
              <div className='w-full flex justify-between text-grey-7'>
                <span>Self Pickup Address</span>
                <span>{data.pickUpAddress}</span>
              </div>
            ) : (
              <div className='w-full flex justify-between text-grey-7'>
                <span>Pickup Address</span>
                <span>{data.pickUpAddress}</span>
              </div>
            )}

            {isSelfDrive && (data.tripType === 'selfDrive' || 'selfDrive') && (
              <div className='w-full flex justify-between text-grey-7'>
                <span>Self DropOff Address</span>
                <span>{data.dropOffAddress}</span>
              </div>
            )}

            {data.tripType === 'chauffeur' && (
              <div className='w-full flex justify-between text-grey-7'>
                <span>Route From</span>
                <span>{data.routeStart || ''}</span>
              </div>
            )}


            {data.tripType === 'chauffeur' && (
              <div className='w-full flex justify-between text-grey-7'>
                <span>Route To</span>
                <span>{data.routeEnd || ''}</span>
              </div>
            )}

            <div className='w-full flex justify-between text-grey-7'>
              <span>{tripOrder.pricePerDay} * {tripOrder.tripsDays}days</span>
              <span>₦{tripOrder.pricePerDayTotal}</span>
            </div>
            
            <div className='w-full flex justify-between text-grey-7'>
              <span>VAT</span>
              <span>₦{tripOrder.vatFee}</span>
            </div>

            <div className='w-full flex justify-between text-grey-7'>
              <span>Drop off Fee</span>
              <span>₦{tripOrder.dropOffFee}</span>
            </div>

            <div className='w-full flex justify-between text-grey-7'>
              <span>Pickup Fee</span>
              <span>₦{tripOrder.pickUpFee}</span>
            </div>

            <div className='w-full flex justify-between text-grey-7'>
              <span>Escort Service Fee</span>
              <span>₦{tripOrder.escortFee}</span>
            </div>

            {data.tripType === "selfDrive" && 
              <div className='w-full flex justify-between text-grey-7'>
                <div>
                <span>Caution Fee (Self Drive)</span>
                <p className='text-primary text-[12px] w-2/3'>
                Fee will be Refunded when Vehicle is Returned in the same Condition
                </p>
                </div>
                <span>₦{tripOrder.cautionFee}</span>
              </div>
            }

            <div className='w-full flex justify-between text-grey-7'>
              <span>Total Discount</span>
              <span>₦{tripOrder.discountPerDayTotal}</span>
            </div>

            <div className='w-full flex justify-between text-grey-7'>
              <span>Payment Status</span>
              <span className='capitalize'>{tripOrder.paymentStatus}</span>
            </div>

            <div className='bg-[#FFF7E2] w-full flex justify-between px-4 py-3 rounded-[4px] shadow-sm'>
              <div className='w-full flex justify-between'>
                  <p className='text-tertiary text-[14px] font-semibold'>Total Payable</p>
                  <p className='text-tertiary text-[14px] font-semibold'>₦{tripOrder.totalFee}</p>
              </div>
            </div>

            

          <div className="w-full mt-4 flex flex-wrap gap-4 justify-between">
          {(isSelfDrive && data?.adminStatus !== 'approved')   && (
              <>
                <button
                  type="button"
                  className="mt-3 w-full md:w-[45%] justify-center rounded-sm border border-primary hover:bg-black hover:text-white hover:border-none md:px-8 py-2.5 text-sm font-semibold text-primary shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 whitespace-nowrap"
                  onClick={handleDecline} 
                  disabled={(data.adminStatus === 'decline') || (data.adminStatus === 'approved')}
                >
                  Decline Request
                </button>
                <button
                  type="button"
                  className="mt-3 w-full md:w-[45%] justify-center rounded-sm bg-primary hover:bg-black md:px-8 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 whitespace-nowrap"
                  onClick={handleApprove} 
                  disabled={data.adminStatus === 'approved'}

                >
                  Approve Request
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}