import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { wheel } from '../../assets';
import CarCard2 from '../components/CarCard2';
import VehicleInfo from '../containers/VehicleInfo';
import VehicleDocuments from '../containers/VehicleDocuments';
import VehiclePictures from '../containers/VehiclePictures';
import VehicleReview from '../containers/VehicleReview';
import TripsCompleted from '../containers/TripsCompleted';
import { fetchOneCar, fetchOneCarReview, fetchOneCarTrips } from '../store/slices/carSlice';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import useImageLoader from '../components/useImageLoader';

const CarDetails = () => {
    let { carID } = useParams();

    const { imageLoaded, handleImageLoad } = useImageLoader();
    const [selectedTab, setSelectedTab] = useState('Vehicle Info');

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(fetchOneCar({ carID })),
                    dispatch(fetchOneCarReview({ carID })),
                    dispatch(fetchOneCarTrips({ carID }))
                ]);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData();
    }, [dispatch, carID]);

    const loading = useSelector(state => state.car.isLoading);

    const selectedCarState = useSelector(state => state.car);
    const selectedCarDetails = selectedCarState.oneCar;
    const carReview = selectedCarState.oneCarReview;
    const carTrips = selectedCarState.oneCarTrips;

    if (loading || !selectedCarDetails) {
        return (
            <Loader loading={loading}/>
        );
    }

    return (
        <div className='w-full flex flex-col gap-6'>
            <div className='flex gap-2 whitespace-nowrap'>
                <h4 className='font-semibold text-grey-4 text-xs lg:text-[16px] py-4'>Manage Cars / </h4>
                <div className="flex items-center gap-2">
                    <div className='w-4 lg:w-6 h-4 lg:h-6flex justify-center items-center bg-black text-white rounded-sm'>
                        <img
                            src={wheel}
                            alt="Wheel"
                            onLoad={handleImageLoad}
                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                            />
                    </div>
                    {selectedCarDetails && (
                        <h4 className='font-semibold text-grey-7 text-sm lg:text-[16px] py-4'>
                            {selectedCarDetails[0]?.brand[0]?.brandName} {selectedCarDetails[0]?.brandModel[0]?.modelName} {selectedCarDetails[0]?.modelYear[0]?.yearName} 
                        </h4>
                    )}
                    
                </div>
            </div>
            <CarCard2 selectedCarDetails={selectedCarDetails}/>

            <div className=''>
                <div className='flex gap-2 mb-4 overflow-x-scroll hide-scrollbar lg:overflow-hidden'>
                    <button
                        className={`border-b-[3px] ${selectedTab === 'Vehicle Info' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-6 whitespace-nowrap text-[14px]`}
                        onClick={() => {
                            setSelectedTab('Vehicle Info');
                        }}
                    >
                        Vehicle Info
                    </button>
                    <button
                        className={`border-b-[3px] ${selectedTab === 'Documents' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-6 whitespace-nowrap text-[14px]`}
                        onClick={() => {
                            setSelectedTab('Documents');
                        }}
                        >
                        Documents
                    </button>
                    <button
                        className={`border-b-[3px] ${selectedTab === 'Vehicle Pictures' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-6 whitespace-nowrap text-[14px]`}
                        onClick={() => {
                            setSelectedTab('Vehicle Pictures');
                        }}
                        >
                        Vehicle Pictures
                    </button>
                    <button
                        className={`border-b-[3px] ${selectedTab === 'Car Reviews' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-6 whitespace-nowrap text-[14px]`}
                        onClick={() => {
                            setSelectedTab('Car Reviews');
                        }}
                        >
                        Car Reviews
                    </button>
                    <button
                        className={`border-b-[3px] ${selectedTab === 'Trips Completed' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-6 whitespace-nowrap text-[14px]`}
                        onClick={() => {
                            setSelectedTab('Trips Completed');
                        }}
                        >
                        Trips Completed By Car
                    </button>

                </div>
            </div>

            <div className=''>
                {selectedTab === 'Vehicle Info' &&
                    <VehicleInfo selectedCarDetails={selectedCarDetails}/>
                }
                {selectedTab === 'Documents' &&
                    <VehicleDocuments selectedCarDetails={selectedCarDetails}/>
                }
                {selectedTab === 'Vehicle Pictures' &&
                    <VehiclePictures selectedCarDetails={selectedCarDetails}/>
                }
                {selectedTab === 'Car Reviews' &&
                    <VehicleReview carReview={carReview}/>
                }
                {selectedTab === 'Trips Completed' &&
                    <TripsCompleted carTrips={carTrips}/>
                }
            </div>


        </div>
    );
};

export default CarDetails;
