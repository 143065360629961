import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDispatch, useSelector } from 'react-redux';
import { addTripsData } from '../../store/slices/vehicleSlice';

const TripsDataForm = ({ selectedTripData, onClose }) => {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.vehicle);
  
  const initialValues = {
    vatValue: selectedTripData?.vatValue || '',
    pickUp: selectedTripData?.pickUp || '',
    escortFee: selectedTripData?.escortFee || '',
    dropOffFee: selectedTripData?.dropOffFee || '',
  };

  const validationSchema = Yup.object().shape({
    vatValue: Yup.string().required('VAT Value is required'),
    pickUp: Yup.string().required('Pick Up Fee is required'),
    escortFee: Yup.string().required('Escort Fee is required'),
    dropOffFee: Yup.string().required('Drop Off Fee is required'),
  });

  const handleSubmit = async (values) => {
    if (selectedTripData) {
      dispatch(
        addTripsData({
          id: selectedTripData.id,
          vatValue: values.vatValue,
          pickUp: values.pickUp,
          escortFee: values.escortFee,
          dropOffFee: values.dropOffFee,
        })
      );
    } else {
      // Otherwise, add new trip data
      dispatch(
        addTripsData({
          vatValue: values.vatValue,
          pickUp: values.pickUp,
          escortFee: values.escortFee,
          dropOffFee: values.dropOffFee,
        })
      );
    }
    if (status === 'succeeded') {
      onClose();
    }
  };

  const tripsState = useSelector((state) => state.vehicle);
  const loading = tripsState.isLoading;

  return (
    <div className="w-full">
      <h4 className="font-extrabold text-xl text-center">
        {selectedTripData ? 'Update' : 'Add'} Trip Data
      </h4>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="w-full flex flex-col gap-4 mt-6">
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">VAT Value</label>
              <Field
                type="text"
                id="vatValue"
                name="vatValue"
                placeholder="Enter VAT Value"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage name="vatValue" component="div" className="text-red-500 text-sm" />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">Pick Up Fee</label>
              <Field
                type="text"
                id="pickUp"
                name="pickUp"
                placeholder="Enter Pick Up Fee"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage name="pickUp" component="div" className="text-red-500 text-sm" />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">Escort Fee</label>
              <Field
                type="text"
                id="escortFee"
                name="escortFee"
                placeholder="Enter Escort Fee"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage name="escortFee" component="div" className="text-red-500 text-sm" />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">Drop Off Fee</label>
              <Field
                type="text"
                id="dropOffFee"
                name="dropOffFee"
                placeholder="Enter Drop Off Fee"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage name="dropOffFee" component="div" className="text-red-500 text-sm" />
            </div>
            <button
              type="submit"
              className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader
                  color="custom-primary"
                  loading="loading"
                  css="override"
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                `${selectedTripData ? 'Update' : 'Save'} Trip Data`
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TripsDataForm;
