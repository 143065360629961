import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDashboardCountersApi } from '../../helpers/api';


export const fetchDashboardCounters = createAsyncThunk('admin/fetchDashboardCounters', async () => {
  try {
    const response = await getDashboardCountersApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});



const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardCounters: {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardCounters.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchDashboardCounters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dashboardCounters = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDashboardCounters.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      
  },
});

export default dashboardSlice.reducer;
