import React from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

const Wrapper = ({ Component, userRole }) => {
  return (
    <main className="w-full flex bg-main-bg">
      <div className='w-1/6 md:w-fit'>
        <Sidebar userRole={userRole}/>
      </div>
      <div className='flex flex-col w-5/6 px-4 lg:px-0'>
        <Navbar />
        <section className="relative flex-1  w-full  mt-4 mb-10 flex justify-center py-2">
          <div className='w-full flex flex-col gap-8 px-6'>
            <Component />
          </div>
        </section>
      </div>
    </main>
  );
};

export default Wrapper;
