import React from 'react';
import { customerIcon } from '../../assets';
import useImageLoader from './useImageLoader';

const ReviewCard = ({ createdAt, message }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();
    const formatDateTime = (createdAt) => {
        const date = new Date(createdAt);
        return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    };


    return (
        <div className='w-full flex flex-col'>
            <div className="flex items-center gap-4">
                <div className='w-8 h-8 flex justify-center items-center text-white rounded-md'>
                    <img
                        src={customerIcon}
                        className='w-8 h-8 rounded-md'
                        alt="Customer Pic"
                        onLoad={handleImageLoad}
                        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                </div>
                {/* <div className='flex flex-col'>
                    <p className='text-[14px] font-semibold text-grey-6'>{name}</p>
                    
                </div> */}
            </div>
            <p className='text-grey-7 text-[14px]'>{formatDateTime(createdAt)}</p>
            <p className='text-grey-7 text-[14px]'>{message}</p>
        </div>
    );
};

export default ReviewCard;
