import React from 'react'
import AdminLists from '../containers/AdminLists'

const Management = () => {
  return (
    <div className='w-full'>
      <AdminLists/>
    </div>
    
  )
}

export default Management