import React, { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { auth3 } from '../../../assets';
import { BsArrowLeft } from 'react-icons/bs';
import { Formik, Form, Field } from 'formik';
import { resendOtp, verifyOtp } from '../../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const VerifyOtp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.auth.isLoading);
    const email = location.state?.email || '';

    const goBack = () => {
        navigate(-1);
    };

    const handleResendCode = () => {
        dispatch(resendOtp({ email }));
    };

    const handleVerifyOtp = (otp) => {
        dispatch(verifyOtp({ email, otp, navigate }));
    };

    const handleSubmit = async (values) => {
        try {
            const otp = values.otp.join('');
            await handleVerifyOtp(otp);
        } catch (error) {
            console.error('An error occurred. Please try again later.');
        }
    };

    const divStyle = {
        backgroundImage: `url(${auth3})`,
    };

    const inputRefs = useRef([]);

    const handleKeyUp = (e, index) => {
        if (e.key === 'Backspace' && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (e.target.value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    return (
        <div className='w-full bg-black bg-cover bg-center bg-no-repeat h-screen flex flex-col items-center' style={divStyle}>
            <div className="flex w-[90%] mx-auto py-12">
                {/* SVG and path elements here */}
            </div>
            <div className='w-[85%] h-fit py-12 mt-8 lg:w-1/4 mx-auto bg-main-bg rounded-sm'>
                <div className='flex w-4/5 mx-auto flex-col items-center justify-center '>
                    <button onClick={goBack} className='rounded-[100%] bg-primary flex h-8 w-8 justify-center items-center mb-3'>
                        <BsArrowLeft className='text-white font-bold h-4 w-4' />
                    </button>
                    <h3 className='text-[24px] lg:text-[40px] text-tertiary font-semibold'>Verify OTP</h3>
                    <p className='text-[16px] text-tertiary font-medium'>Enter OTP sent to your email</p>

                    <Formik
                        initialValues={{ otp: ['', '', '', '', '', ''] }}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="w-full flex flex-col items-center text-center py-4">
                                <div className="grid grid-cols-6 gap-3 w-full">
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <Field
                                            key={index}
                                            type="text"
                                            name={`otp[${index}]`}
                                            maxLength="1"
                                            innerRef={(el) => (inputRefs.current[index] = el)}
                                            onKeyUp={(e) => handleKeyUp(e, index)}
                                            className={`py-[10px] rounded-[2.5px] w-full text-center placeholder:text-[13px] bg-transparent border-2 border-brand text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                                        />
                                    ))}
                                </div>

                                <div className="flex items-center w-full justify-center gap-2 my-8">
                                    <button
                                        type="button"
                                        className="text-brand font-semibold"
                                        onClick={handleResendCode}
                                        disabled={isSubmitting}
                                    >
                                        Resend Code
                                    </button>
                                </div>

                                <button
                                    type="submit"
                                    className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                                    disabled={isSubmitting}
                                >
                                    {isLoading ? (
                                        <ClipLoader
                                            color="custom-primary"
                                            loading="loading"
                                            css="override"
                                            size={20}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    ) : (
                                        'Continue'
                                    )}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default VerifyOtp;
