// ProtectedRoute.js
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import useRequireAuth from '../helpers/useRequireAuth';

const ProtectedRoute = ({ children, ...rest }) => {
  const isAuthenticated = useRequireAuth();

  return isAuthenticated ? children : <Navigate to="/admin" />;
};

export default ProtectedRoute;
