import { Field, Form, Formik } from 'formik';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createAdmin } from '../store/slices/adminSlice';

const CreateAdminForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isLoading = useSelector(state => state.auth.isLoading);


    const handleRegister = (fullName, email, password,  role, phoneNumber) => {
        dispatch(createAdmin({ fullName, email, password,  role, phoneNumber, navigate }))
         
      };

    const initialValues = {
        fullName: '',
        email: '',
        password: '',
        role: '',
        phoneNumber: '',
    };

    const handleSubmit = async (values) => {
        try {
            await handleRegister(values.fullName, values.email,  values.password, values.role, values.phoneNumber);
            
        } catch (error) {
            console.error('An error occurred. Please try again later.');
        }
    };

    return (
        <>
            <div className="w-full py-4">
                <h4 className='font-semibold text-grey-4 text-[16px]'>List of Admins / <span className='text-grey-7'> Create Admin</span></h4>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    <Form className='w-full flex flex-col gap-4 mt-6'>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Full Name</label>
                            <Field
                                type="text"
                                id="fullName"
                                name="fullName"
                                placeholder="Enter your full name"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Email</label>
                            <Field
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter your email address"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Role</label>
                            <Field
                                as="select"
                                id="role"
                                name="role"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            >
                                <option value="">Select role</option>
                                <option value="admin">Admin</option>
                                <option value="superAdmin">Super Admin</option>
                            </Field>
                        </div>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Phone Number</label>
                            <Field
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Enter your phone number"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Password</label>
                            <Field
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Enter your password"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <button
                            type="submit"
                            className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                        >
                            {isLoading ? 
                            <ClipLoader
                                color="custom-primary"
                                loading="loading"
                                css="override"
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                            :
                            'Create Admin'
                            }
                        </button>
                    </Form>
                </Formik>
            </div>
        </>
    );
};

export default CreateAdminForm;
