import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import { useSelector } from 'react-redux';

const MasterDataTable = ({ dataList }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const vehicle = useSelector(state => state.vehicle); 
  const states = vehicle.states;
  const cities = vehicle.cities;
  const features = vehicle.features;
  const transmissions = vehicle.transmissions;
  const seats = vehicle.seats;
  const vehicleTypes = vehicle.vehicleTypes;
  const insuranceTypes = vehicle.insuranceTypes;
  const [showContent, setShowContent] = useState("list");
  const [dataDisplay, setDataDisplay] = useState("default")

  if (!states) {
    return null;
  }

  
  const renderButtonLabel = (dataName) => {
    switch (dataName) {
      case 'Brand':
        return 'See list of Brands';
      case 'State':
        return 'See list of States';
      case 'City':
        return 'See list of Cities';
      case 'Features':
        return 'See list of Features';
      case 'Transmission':
        return 'See list of Transmissions';
      case 'Seats':
        return 'See list of Seats Number';
      case 'Vehicle Types':
        return 'See list of Vehicle Types';
      case 'Insurance Types':
        return 'See list of Insurance Types';
      case 'Trips Data':
        return 'See list of Trips Data';
      default:
        return 'Action';
    }
  };

  const renderButtonLink = (dataName) => {
    switch (dataName) {
      case 'Brand':
        return 'brands';
      case 'Brand Model':
        return 'models';
      case 'State':
        return 'states';
      case 'City':
        return 'cities';
      case 'Features':
        return 'features';
      case 'Transmission':
        return 'transmissions';
      case 'Trips Data':
        return 'trips';
      default:
        return '';
    }
  }


  const openModal = (dataName) => {
    setModalOpen(true);
    switch (dataName) {
      case 'Features':
        setModalData({ title: 'Features', list: features });
        setShowContent("featuresName")
        break;
      case 'Transmission':
        setModalData({ title: 'Transmissions', list: transmissions });
        setShowContent("transmissionName")
        break;
      case 'Seats':
        setModalData({ title: 'Seats', list: seats });
        setShowContent("seatName")
        break;
      case 'Vehicle Types':
        setModalData({ title: 'Vehicle Types', list: vehicleTypes });
        setShowContent("typeName")
        break;
      case 'Insurance Types':
        setModalData({ title: 'Insurance Types', list: insuranceTypes });
        setShowContent("insuranceName")
        break;
      default:
        console.warn("Unhandled dataName:", dataName);
        setModalData(null);
        break;
    }
  };

  

  return (
    <div className='w-full'>
      <table className='w-full table-auto text-grey-6'>
        <thead>
          <tr>
            <th className='px-6 py-3 text-left whitespace-nowrap'>
              S/N
            </th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>
              Data Name
            </th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>
              Total No of items under data
            </th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>
              Call to action
            </th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((data, index) => (
            <tr key={index} className={`px-6 ${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}`}>
              <td className='px-6 py-3 whitespace-nowrap'>{index + 1}</td>
              <td className='px-6 py-3 whitespace-nowrap'>{data.name}</td>
              <td className='px-6 py-3 whitespace-nowrap'>{data.total}</td>
              <td className='px-6 py-3 whitespace-nowrap'>
                {!['Brand'].includes(data.name) && !['Trips Data'].includes(data.name) && !['State'].includes(data.name) ? (
                  <button onClick={() => openModal(data.name)} className='bg-brand hover:bg-black text-white font-bold py-2 px-4 rounded w-full xl:w-2/3'>
                    {renderButtonLabel(data.name)}
                  </button>
                ) : (
                  <Link to={renderButtonLink(data.name)}>
                    <button className='bg-brand hover:bg-black text-white font-bold py-2 px-4 rounded w-full xl:w-2/3'>
                      {renderButtonLabel(data.name)}
                    </button>
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal isOpen={modalOpen} onClose={() => {
          setModalOpen(false);
          setDataDisplay("default"); 
      }} data={modalData} showContent={showContent} setShowContent={setShowContent} setDataDisplay={setDataDisplay} dataDisplay={dataDisplay} />

    </div>
        );
};

export default MasterDataTable;
