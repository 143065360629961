import React, { useState } from 'react';
import Loader from '../Loader';
import UpdateModal from './UpdateModal';
import TripsDataForm from './TripsDataForm';

const TripsDataTable = ({ dataList, loading }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTripData, setSelectedTripData] = useState(null);


    const openModal = (state) => {
      setSelectedTripData(state);
      setIsModalOpen(true);
  };
  
  const closeModal = () => {
      setSelectedTripData(null);
      setIsModalOpen(false);
  };




  return (
    <div className='w-full overflow-x-scroll lg:overflow-x-hidden scrollbar flex justify-between'>
      {loading ? (
          <Loader loading={loading}/>
      ) : (
        <table className='w-full table-auto text-grey-6'>
          <thead>
            <tr>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                S/N
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                Drop Off Fee
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                Escort Fee
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                Pick Up
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                Vat Value
              </th>

            </tr>
          </thead>
          <tbody>
            {dataList.map((data, index) => (
              <tr key={index} className={`px-6 ${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}`}>
                <td className='px-6 py-3 whitespace-nowrap'>{index + 1}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{data?.dropOffFee}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{data?.escortFee}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{data?.pickUp}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{data?.vatValue}</td>
                <td className='px-6 py-3 whitespace-nowrap'>
                  <button
                      className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                      onClick={() => openModal(data)}
                  >
                      Edit Trip Data
                  </button>

                </td>
               
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <UpdateModal isOpen={isModalOpen} onClose={closeModal}>
          <TripsDataForm selectedTripData={selectedTripData} onClose={closeModal}/>
      </UpdateModal>
    </div>
  );
};

export default TripsDataTable;
