import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import * as Yup from 'yup';
import { addBrand, updateBrand } from '../../store/slices/vehicleSlice';
import { useDispatch, useSelector } from 'react-redux';

const BrandForm = ({ selectedBrand, onClose, setSelectedTab }) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const vehicleState = useSelector(state => state.vehicle);
    const loading = vehicleState.isLoading;
    const [selectedFileName, setSelectedFileName] = useState('');

    const initialValues = {
        name: selectedBrand ? selectedBrand.brandName : '',
        icon: null,  
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Brand name is required'),
        icon: Yup.mixed().required('Brand icon is required'),
    });

    const handleFileChange = (e, setFieldValue) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFieldValue('icon', selectedFile);
            setSelectedFileName(selectedFile.name); 
        }
    };

    const handleSubmit = async (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('icon', values.icon);

        if (selectedBrand) {
            formData.append('brandCode', selectedBrand.brandCode);
            dispatch(updateBrand({ brandCode: selectedBrand?.brandCode, name: values.name, icon: values.icon }));
            onClose();
        } else {
            dispatch(addBrand({ name: values.name, icon: values.icon }));
            setSelectedTab("list");
        }
    };

    return (
        <div className="w-full">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className='w-full flex flex-col gap-4 mt-6'>
                        <h4 className='text-lg text-tertiary font-semibold'>{selectedBrand ? 'Update' : 'Add'} Brand</h4>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Enter Brand Name</label>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Input Brand Name"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                            <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
                        </div>
                        <div className='flex flex-col w-full gap-2'>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Upload Brand Icon</label>
                            <input
                                type="file"
                                name="icon"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={(e) => handleFileChange(e, setFieldValue)}
                            />
                            {selectedFileName && (
                                <div className="text-[14px] text-grey-7 mt-1">
                                    Selected file: {selectedFileName}
                                </div>
                            )}
                            <ErrorMessage name="icon" component="div" className="text-red-500" />
                        </div>

                        <button
                            type="submit"
                            className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                            disabled={loading}
                        >
                            {loading ? (
                                <ClipLoader
                                    color="custom-primary"
                                    loading="loading"
                                    css="override"
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            ) : (
                                `${selectedBrand ? 'Update' : 'Save'} Brand`
                            )}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default BrandForm;
