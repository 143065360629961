import React from 'react'
import Chart from '../containers/Chart'
import LineGraph from '../containers/LineGraph'
import PendingPayment from '../containers/PendingPayment'
import UserSummary from '../containers/UserSummary'
import PendingSelfDrive from '../containers/PendingSelfDrive'

const Dashboard = () => {
  return (
    <div className='w-full flex flex-col gap-8'>
      <UserSummary/>
      <div className='w-full flex flex-col lg:flex-row gap-6 h-fit'>
        <div className='w-full flex-1 h-full'>
          <Chart/>
        </div>
        <div className='flex-1'>
          <LineGraph/>
        </div>
      </div>
      <div className='w-full flex flex-col lg:flex-row gap-6'>
        <div className='w-full lg:w-2/3'>
          <PendingSelfDrive/>
        </div>
        <div className="w-full lg:w-1/3">
          <PendingPayment/>
        </div>
      </div>
    </div>
  )
}

export default Dashboard


    