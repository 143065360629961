import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  // const dispatch = useDispatch();
  const menu = [
    {
      title: "Dashboard",
      link: "/dashboard",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M4.16667 2C2.97005 2 2 2.97005 2 4.16667V7.33333H9.33333V2H4.16667ZM2 11.8333V8.33333H9.33333V14H4.16667C2.97005 14 2 13.0299 2 11.8333ZM10.3333 10.6667V14H11.8333C13.0299 14 14 13.0299 14 11.8333V10.6667H10.3333ZM14 9.66667V6.33333H10.3333V9.66667H14ZM14 5.33333H10.3333V2H11.8333C13.0299 2 14 2.97005 14 4.16667V5.33333Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Admin",
      link: "/management",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M7.99829 4.33337C8.82669 4.33337 9.49829 3.6618 9.49829 2.83337C9.49829 2.00495 8.82669 1.33337 7.99829 1.33337C7.16989 1.33337 6.49829 2.00495 6.49829 2.83337C6.49829 3.6618 7.16989 4.33337 7.99829 4.33337ZM4.09814 2.78539C3.33605 2.4619 2.4529 2.81497 2.12385 3.57467C1.79366 4.33701 2.14649 5.21955 2.91121 5.54416L4.92701 6.39981C5.17327 6.50434 5.33319 6.74597 5.33319 7.01351V9.03984L4.08054 12.6778C3.81082 13.4611 4.22717 14.3148 5.01046 14.5844C5.79375 14.8542 6.64738 14.4378 6.91709 13.6545L7.37936 12.312C7.34789 12.1015 7.33162 11.886 7.33162 11.6667C7.33162 9.61711 8.75462 7.89984 10.6665 7.44897V7.01211C10.6665 6.74457 10.8264 6.50296 11.0727 6.39843L13.0852 5.54416C13.85 5.21955 14.2028 4.33701 13.8726 3.57467C13.5436 2.81497 12.6604 2.4619 11.8983 2.78539L10.8276 3.23986C10.6005 3.33627 10.4421 3.52175 10.3644 3.72715C10.0031 4.68337 9.07929 5.36201 7.99829 5.36201C6.91722 5.36201 5.99344 4.68337 5.63207 3.72717C5.55444 3.52177 5.39601 3.3363 5.16888 3.23989L4.09814 2.78539ZM15.3316 11.6667C15.3316 13.6918 13.69 15.3334 11.665 15.3334C9.63989 15.3334 7.99829 13.6918 7.99829 11.6667C7.99829 9.64164 9.63989 8.00004 11.665 8.00004C13.69 8.00004 15.3316 9.64164 15.3316 11.6667ZM13.9007 10.0976C13.7705 9.96751 13.5594 9.96751 13.4292 10.0976L10.9983 12.5286L9.90069 11.431C9.77049 11.3008 9.55942 11.3008 9.42922 11.431C9.29909 11.5612 9.29909 11.7722 9.42922 11.9024L10.7626 13.2358C10.8928 13.3659 11.1038 13.3659 11.234 13.2358L13.9007 10.5691C14.0308 10.4389 14.0308 10.2278 13.9007 10.0976Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "User",
      link: "/user-management",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M4.16536 2.33337C3.52103 2.33337 2.9987 2.85571 2.9987 3.50004V5.16671C2.9987 5.81104 3.52103 6.33337 4.16536 6.33337H5.83203C6.47636 6.33337 6.9987 5.81104 6.9987 5.16671V3.50004C6.9987 2.85571 6.47636 2.33337 5.83203 2.33337H4.16536ZM8.9987 3.50004V5.16671C8.9987 5.81104 9.52103 6.33337 10.1654 6.33337H11.832C12.4764 6.33337 12.9987 5.81104 12.9987 5.16671V3.50004C12.9987 2.85571 12.4764 2.33337 11.832 2.33337H10.1654C9.52103 2.33337 8.9987 2.85571 8.9987 3.50004ZM10.1654 3.33337H11.832C11.9241 3.33337 11.9987 3.40799 11.9987 3.50004V5.16671C11.9987 5.25875 11.9241 5.33337 11.832 5.33337H10.1654C10.0733 5.33337 9.9987 5.25875 9.9987 5.16671V3.50004C9.9987 3.40799 10.0733 3.33337 10.1654 3.33337ZM4.33203 7.66671C2.67518 7.66671 1.33203 9.00984 1.33203 10.6667C1.33203 12.3236 2.67518 13.6667 4.33203 13.6667H7.82016C7.5083 13.0684 7.33203 12.3881 7.33203 11.6667C7.33203 11.5833 7.33436 11.5005 7.33903 11.4183C7.13036 11.235 6.9987 10.9662 6.9987 10.6667C6.9987 10.1786 7.3485 9.77211 7.81103 9.68431C8.27703 8.78017 9.0517 8.06124 9.99576 7.66671H4.33203ZM4.66536 11.6667C4.11308 11.6667 3.66536 11.219 3.66536 10.6667C3.66536 10.1144 4.11308 9.66671 4.66536 9.66671C5.21765 9.66671 5.66536 10.1144 5.66536 10.6667C5.66536 11.219 5.21765 11.6667 4.66536 11.6667ZM15.332 11.6667C15.332 13.6918 13.6904 15.3334 11.6654 15.3334C9.6403 15.3334 7.9987 13.6918 7.9987 11.6667C7.9987 9.64164 9.6403 8.00004 11.6654 8.00004C13.6904 8.00004 15.332 9.64164 15.332 11.6667ZM13.9011 10.0976C13.7709 9.96751 13.5598 9.96751 13.4296 10.0976L10.9987 12.5286L9.9011 11.431C9.7709 11.3008 9.55983 11.3008 9.42963 11.431C9.2995 11.5612 9.2995 11.7722 9.42963 11.9024L10.763 13.2358C10.8932 13.3659 11.1042 13.3659 11.2344 13.2358L13.9011 10.5691C14.0312 10.4389 14.0312 10.2278 13.9011 10.0976Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Rent Order",
      link: "/rent-order",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M1.33203 3.99996C1.33203 3.26358 1.92898 2.66663 2.66536 2.66663H3.33203V13.3333H2.66536C1.92898 13.3333 1.33203 12.7364 1.33203 12V3.99996ZM10.6654 5.66663H7.9987C7.81463 5.66663 7.66536 5.81587 7.66536 5.99996V6.66663C7.66536 6.85069 7.81463 6.99996 7.9987 6.99996H10.6654C10.8494 6.99996 10.9987 6.85069 10.9987 6.66663V5.99996C10.9987 5.81587 10.8494 5.66663 10.6654 5.66663ZM4.33203 13.3333H13.332C14.0684 13.3333 14.6654 12.7364 14.6654 12V3.99996C14.6654 3.26358 14.0684 2.66663 13.332 2.66663H4.33203V13.3333ZM7.9987 4.66663H10.6654C11.4018 4.66663 11.9987 5.26358 11.9987 5.99996V6.66663C11.9987 7.40303 11.4018 7.99996 10.6654 7.99996H7.9987C7.2623 7.99996 6.66536 7.40303 6.66536 6.66663V5.99996C6.66536 5.26358 7.2623 4.66663 7.9987 4.66663Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Send Broadcast",
      link: "/send-broadcast",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M11.4745 1.49169C11.5618 1.22973 11.4203 0.946549 11.1583 0.859189C10.8963 0.771829 10.6132 0.913376 10.5258 1.17534L10.0258 2.67466C9.93847 2.93662 10.08 3.2198 10.3419 3.30716C10.6039 3.39452 10.8871 3.25297 10.9745 2.99101L11.4745 1.49169ZM14.5202 1.47996C14.7155 1.67523 14.7155 1.99181 14.5202 2.18707L12.8535 3.85374C12.6583 4.049 12.3417 4.049 12.1465 3.85374C11.9512 3.65848 11.9512 3.3419 12.1465 3.14663L13.8131 1.47996C14.0084 1.2847 14.3249 1.2847 14.5202 1.47996ZM8.5804 2.4941C7.83647 1.69715 6.52407 1.89683 6.05086 2.87896L2.11899 11.0394C1.89197 11.5105 1.99625 12.0744 2.37675 12.4333L3.33591 13.3378C3.68745 13.6694 4.20354 13.7577 4.64533 13.562L5.5903 13.1433C6.01833 14.0441 6.93653 14.6668 8.0002 14.6668C9.473 14.6668 10.6669 13.473 10.6669 12.0002C10.6669 11.64 10.5955 11.2965 10.4661 10.9831L12.746 9.97298C13.7206 9.54118 13.9786 8.27731 13.2513 7.49811L8.5804 2.4941ZM9.55107 11.3884C9.6258 11.5778 9.66687 11.7842 9.66687 12.0002C9.66687 12.9206 8.92073 13.6668 8.0002 13.6668C7.3446 13.6668 6.77747 13.2883 6.5053 12.7379L9.55107 11.3884ZM12.6667 5.4997C12.6667 5.22356 12.8905 4.9997 13.1667 4.9997H14.5C14.7761 4.9997 15 5.22356 15 5.4997C15 5.77584 14.7761 5.9997 14.5 5.9997H13.1667C12.8905 5.9997 12.6667 5.77584 12.6667 5.4997Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Manage Cars",
      link: "/manage-cars",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M4.33464 0.666626C6.35968 0.666626 8.0013 2.30825 8.0013 4.33329C8.0013 6.35834 6.35968 7.99996 4.33464 7.99996C2.30959 7.99996 0.667969 6.35834 0.667969 4.33329C0.667969 2.30825 2.30959 0.666626 4.33464 0.666626ZM5.66797 4.33333H4.33464V2.66658C4.33464 2.48249 4.1854 2.33325 4.0013 2.33325C3.81721 2.33325 3.66797 2.48249 3.66797 2.66658V4.66565V4.66667C3.66797 4.85076 3.81721 5 4.0013 5H5.66797C5.85206 5 6.0013 4.85076 6.0013 4.66667C6.0013 4.48257 5.85206 4.33333 5.66797 4.33333ZM4.33464 8.66663C5.49896 8.66663 6.55604 8.20743 7.33464 7.46023V7.99996H10.6242L9.2911 5.74285C9.14124 5.48903 8.86837 5.33329 8.57357 5.33329H8.55204C8.62764 5.01303 8.66777 4.67904 8.66797 4.33571C9.2799 4.36717 9.83844 4.7031 10.1522 5.23431L11.8243 8.06556L13.2791 8.42909C14.0953 8.63309 14.668 9.36643 14.668 10.2078V11.3333C14.668 12.0682 14.2356 12.7021 13.6113 12.9945C13.3876 13.9528 12.5278 14.6666 11.5013 14.6666C10.5373 14.6666 9.7203 14.037 9.4391 13.1666H6.23014C5.94895 14.037 5.13198 14.6666 4.16797 14.6666C3.05943 14.6666 2.14533 13.8341 2.01678 12.7602C1.60072 12.4241 1.33464 11.9098 1.33464 11.3333V9.83329C1.33464 9.11249 1.7506 8.48883 2.35558 8.18929C2.94883 8.49443 3.62162 8.66663 4.33464 8.66663ZM4.16797 11.3333C3.52364 11.3333 3.0013 11.8556 3.0013 12.5C3.0013 13.1443 3.52364 13.6666 4.16797 13.6666C4.8123 13.6666 5.33464 13.1443 5.33464 12.5C5.33464 11.8556 4.8123 11.3333 4.16797 11.3333ZM10.3346 12.5C10.3346 13.1443 10.857 13.6666 11.5013 13.6666C12.1456 13.6666 12.668 13.1443 12.668 12.5C12.668 11.8556 12.1456 11.3333 11.5013 11.3333C10.857 11.3333 10.3346 11.8556 10.3346 12.5Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Partners Payment",
      link: "/partners-payment",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M3.4987 3.33337C2.30208 3.33337 1.33203 4.30342 1.33203 5.50004V6.33337H14.6654V5.50004C14.6654 4.30342 13.6953 3.33337 12.4987 3.33337H3.4987ZM14.6654 7.33337H1.33203V10.5C1.33203 11.6966 2.30208 12.6667 3.4987 12.6667H12.4987C13.6953 12.6667 14.6654 11.6966 14.6654 10.5V7.33337ZM10.4987 9.66671H12.1654C12.4415 9.66671 12.6654 9.89057 12.6654 10.1667C12.6654 10.4428 12.4415 10.6667 12.1654 10.6667H10.4987C10.2226 10.6667 9.9987 10.4428 9.9987 10.1667C9.9987 9.89057 10.2226 9.66671 10.4987 9.66671Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Profile",
      link: "/profile",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M11.835 9.33343C12.6631 9.33343 13.3343 10.0046 13.3343 10.8327V11.445C13.3343 11.8272 13.2148 12.1999 12.9926 12.5109C11.962 13.953 10.2791 14.6675 7.99896 14.6675C5.7183 14.6675 4.03632 13.9528 3.00821 12.5098C2.7869 12.1992 2.66797 11.8274 2.66797 11.446V10.8327C2.66797 10.0046 3.3392 9.33343 4.16722 9.33343H11.835ZM7.99896 1.33655C9.8399 1.33655 11.3323 2.82893 11.3323 4.66988C11.3323 6.51083 9.8399 8.00323 7.99896 8.00323C6.15799 8.00323 4.6656 6.51083 4.6656 4.66988C4.6656 2.82893 6.15799 1.33655 7.99896 1.33655Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Referrals",
      link: "/referrals",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M7.33463 11.6667C7.33463 10.8073 7.58477 10.0065 8.01617 9.3328H3.50315C2.67514 9.3328 2.00391 10.0041 2.00391 10.8321V11.4454C2.00391 11.8267 2.12284 12.1987 2.34415 12.5093C3.37225 13.9521 5.05423 14.6669 7.3349 14.6669C7.73477 14.6669 8.1163 14.6449 8.47917 14.6009C7.76857 13.8291 7.33463 12.7985 7.33463 11.6667ZM7.3349 1.33594C9.17583 1.33594 10.6682 2.82832 10.6682 4.66927C10.6682 6.51022 9.17583 8.0026 7.3349 8.0026C5.49393 8.0026 4.00154 6.51022 4.00154 4.66927C4.00154 2.82832 5.49393 1.33594 7.3349 1.33594ZM8.0013 11.6667C8.0013 13.6917 9.6429 15.3333 11.668 15.3333C13.693 15.3333 15.3346 13.6917 15.3346 11.6667C15.3346 9.6416 13.693 8 11.668 8C9.6429 8 8.0013 9.6416 8.0013 11.6667ZM13.668 9.33333C13.852 9.33333 14.0013 9.4826 14.0013 9.66666V11C14.0013 11.1841 13.852 11.3333 13.668 11.3333H12.3346C12.1506 11.3333 12.0013 11.1841 12.0013 11C12.0013 10.8159 12.1506 10.6667 12.3346 10.6667H13.0015C12.697 10.2613 12.2129 10 11.668 10C11.1124 10 10.6202 10.2715 10.3168 10.6907C10.2088 10.8398 10.0004 10.8732 9.8513 10.7652C9.70223 10.6573 9.66883 10.4489 9.77677 10.2997C10.2001 9.71493 10.8895 9.33333 11.668 9.33333C12.3212 9.33333 12.9114 9.6018 13.3346 10.0337V9.66666C13.3346 9.4826 13.4839 9.33333 13.668 9.33333ZM10.0013 13.2996V13.6667C10.0013 13.8507 9.85203 14 9.66797 14C9.4839 14 9.33463 13.8507 9.33463 13.6667V12.3333C9.33463 12.1493 9.4839 12 9.66797 12H11.0013C11.1854 12 11.3346 12.1493 11.3346 12.3333C11.3346 12.5174 11.1854 12.6667 11.0013 12.6667H10.3345C10.6389 13.072 11.1231 13.3333 11.668 13.3333C12.1788 13.3333 12.6358 13.1039 12.9421 12.7412C13.0608 12.6005 13.2712 12.5828 13.4118 12.7015C13.5525 12.8203 13.5702 13.0307 13.4514 13.1713C13.024 13.6776 12.3834 14 11.668 14C11.0148 14 10.4246 13.7315 10.0013 13.2996Z" fill="#ADADAD"/>
            </svg>,
    },
    {
      title: "Master Data",
      link: "/master-data",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M7.33463 11.6667C7.33463 10.8073 7.58477 10.0065 8.01617 9.3328H3.50315C2.67514 9.3328 2.00391 10.0041 2.00391 10.8321V11.4454C2.00391 11.8267 2.12284 12.1987 2.34415 12.5093C3.37225 13.9521 5.05423 14.6669 7.3349 14.6669C7.73477 14.6669 8.1163 14.6449 8.47917 14.6009C7.76857 13.8291 7.33463 12.7985 7.33463 11.6667ZM7.3349 1.33594C9.17583 1.33594 10.6682 2.82832 10.6682 4.66927C10.6682 6.51022 9.17583 8.0026 7.3349 8.0026C5.49393 8.0026 4.00154 6.51022 4.00154 4.66927C4.00154 2.82832 5.49393 1.33594 7.3349 1.33594ZM8.0013 11.6667C8.0013 13.6917 9.6429 15.3333 11.668 15.3333C13.693 15.3333 15.3346 13.6917 15.3346 11.6667C15.3346 9.6416 13.693 8 11.668 8C9.6429 8 8.0013 9.6416 8.0013 11.6667ZM13.668 9.33333C13.852 9.33333 14.0013 9.4826 14.0013 9.66666V11C14.0013 11.1841 13.852 11.3333 13.668 11.3333H12.3346C12.1506 11.3333 12.0013 11.1841 12.0013 11C12.0013 10.8159 12.1506 10.6667 12.3346 10.6667H13.0015C12.697 10.2613 12.2129 10 11.668 10C11.1124 10 10.6202 10.2715 10.3168 10.6907C10.2088 10.8398 10.0004 10.8732 9.8513 10.7652C9.70223 10.6573 9.66883 10.4489 9.77677 10.2997C10.2001 9.71493 10.8895 9.33333 11.668 9.33333C12.3212 9.33333 12.9114 9.6018 13.3346 10.0337V9.66666C13.3346 9.4826 13.4839 9.33333 13.668 9.33333ZM10.0013 13.2996V13.6667C10.0013 13.8507 9.85203 14 9.66797 14C9.4839 14 9.33463 13.8507 9.33463 13.6667V12.3333C9.33463 12.1493 9.4839 12 9.66797 12H11.0013C11.1854 12 11.3346 12.1493 11.3346 12.3333C11.3346 12.5174 11.1854 12.6667 11.0013 12.6667H10.3345C10.6389 13.072 11.1231 13.3333 11.668 13.3333C12.1788 13.3333 12.6358 13.1039 12.9421 12.7412C13.0608 12.6005 13.2712 12.5828 13.4118 12.7015C13.5525 12.8203 13.5702 13.0307 13.4514 13.1713C13.024 13.6776 12.3834 14 11.668 14C11.0148 14 10.4246 13.7315 10.0013 13.2996Z" fill="#ADADAD"/>
            </svg>,
    },
  ];

  const prefixes = menu.map((m) => m.link); // Add other prefixes here
  const { pathname } = location;
  const matchedPrefix = prefixes.find((prefix) => pathname.startsWith(prefix));

  return (
    <main className="flex sticky top-0 left-0 group  z-20 bg-main-bg h-screen border-r-[1px] overflow-y-auto flex-col gap-12 w-fit lg:w-full py-8 px-4">
      <div className="flex">
        <svg width="44" height="22" viewBox="0 0 44 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M28.7338 21.8089V10.7622H16.2174L20.1525 14.9344H24.1349V17.0204L11.5237 17.3049C9.53247 17.5103 4.64917 16.6885 4.64917 10.7622C4.91783 8.66034 6.66887 4.44712 11.5237 4.40919H38.8797L34.6601 0H9.15318C5.78703 0.237053 0.154645 3.40408 0.00293124 10.7622C-0.09189 14.3022 2.07952 21.4675 11.5237 21.8089H28.7338Z" fill="#ECAE00"/>
          <path d="M12.5193 6.21079L15.7907 9.71918H29.7768V21.8089H33.8067V9.71918H34.8497C34.3914 8.96061 33.8067 7.5857 34.8497 6.21079H12.5193Z" fill="#1A1300"/>
          <path d="M38.8797 9.71918C39.4012 8.99222 40.0649 7.72793 38.8797 6.21079H40.4916L44 9.71918H38.8797Z" fill="#1A1300"/>
          <path d="M34.8497 10.7622H38.8797V21.8089H34.8497V10.7622Z" fill="#ECAE00"/>
          <circle cx="36.9583" cy="7.89241" r="1.53938" fill="#ECAE00"/>
        </svg>
          <p className="hidden lg:block text-lg font-[500] text-brand whitespace-nowrap">GTI Rides</p>
        </div>
      <ul className="flex flex-col gap-3 ">
        {menu.map((m, i) => (
          <Link key={m.link + i} to={m.link}>
            <li
              className={`${
                m.link === matchedPrefix &&
                "bg-custom-secondary text-white"
              } text-grey-5 flex items-center gap-3 whitespace-nowrap text-base  px-6 py-3 rounded-md cursor-pointer hover:bg-custom-secondary hover:text-white transition-all duration-500`}
            >
                <span className="">{m.icon}</span>
                <span className="hidden lg:block">{m.title}</span>
            </li>
          </Link>
        ))}
      </ul>
    </main>
  );
};

export default Sidebar;
