// useRequireAuth.js
import { useSelector } from 'react-redux';
import { selectAuthenticated } from '../store/slices/authSlice';

const useRequireAuth = () => {
  const isAuthenticated = useSelector(selectAuthenticated);
  console.log("i am authenticated: ", isAuthenticated)

  return isAuthenticated;
};

export default useRequireAuth;
