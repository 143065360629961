import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useImageLoader from '../../../../components/useImageLoader';
import { deleteBrand, getBrandModel } from '../../store/slices/vehicleSlice';
import Loader from '../Loader';
import UpdateModal from './UpdateModal';
import BrandForm from './BrandForm';
import { toast } from 'react-toastify';

const BrandTable = ({ dataList }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);

    const openModal = (brand) => {
        setSelectedBrand(brand);
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setSelectedBrand(null);
        setIsModalOpen(false);
    };

    
    const dispatch = useDispatch();
    const [modelCounts, setModelCounts] = useState({});
    const navigate = useNavigate();
    const vehicle = useSelector((state) => state.vehicle); 
    const loading = vehicle.isLoading;

    useEffect(() => {
        const fetchModelCounts = async () => {
            const counts = {};
            for (const data of dataList) {
                try {
                    const response = await dispatch(getBrandModel({ brandCode: data.brandCode }));
                    const modelCount = response.payload.length; 
                    counts[data.brandCode] = modelCount;
                } catch (error) {
                    console.error(`Error fetching models for brand ${data?.brandCode}:`, error);
                }
            }
            setModelCounts(counts);
        };

        fetchModelCounts();

        return () => {
        };
    }, [dataList, dispatch]);

    const handleViewModels = (brandCode, brandName) => {
        navigate(`/admin/master-data/models/${brandCode}`, { state: { brandName } });
    };

    const handleDeleteClick = (brandCode) => {
        dispatch(deleteBrand(brandCode))
          .unwrap()
          .catch(error => {
            toast.error(error.message);
          });
      };

    return (
        <div className='w-full'>
            {loading ? (
                <Loader loading={loading}/>
            ) : (
                <table className='w-full table-auto text-grey-6'>
                    <thead>
                        <tr>
                            <th className='px-6 py-3 text-left whitespace-nowrap'>S/N</th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'>Brand Logo</th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'>Vehicle Brand</th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'>Vehicle Models</th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'></th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((data, index) => (
                            <tr key={index} className={`px-6 ${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}`}>
                                <td className='px-6 py-3 whitespace-nowrap'>{index + 1}</td>
                                <td className='px-6 py-3'>
                                    <div className='w-fit flex justify-center items-center text-white rounded-sm'>
                                        <img
                                            src={data?.brandIcon}
                                            alt='brand icon'
                                            className='w-8 h-6'
                                            onLoad={handleImageLoad}
                                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                                        />
                                    </div>
                                </td>
                                <td className='px-6 py-3 whitespace-nowrap'>{data?.brandName}</td>
                                <td className='px-6 py-3 whitespace-nowrap'>{modelCounts[data?.brandCode] || 0}</td>
                                <td className='px-6 py-3 whitespace-nowrap'>
                                        <button className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit' onClick={() => handleViewModels(data.brandCode, data.brandName)}>
                                            See Models
                                        </button>
                                </td>
                                <td className='px-6 py-3 whitespace-nowrap'>
                                <button
                                    className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                                    onClick={() => handleDeleteClick(data?.brandCode)}
                                >
                                    Delete Brand
                                </button>
                                </td>
                                <td className='px-6 py-3 whitespace-nowrap'>
                                <button
                                    className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                                    onClick={() => openModal(data)}
                                >
                                    Edit Brand
                                </button>

                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <UpdateModal isOpen={isModalOpen} onClose={closeModal}>
                <BrandForm selectedBrand={selectedBrand} onClose={closeModal}/>
            </UpdateModal>

        </div>
    );
};

export default BrandTable;
