import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import carReducer from './slices/carSlice';
import adminReducer from './slices/adminSlice';
import tripReducer from './slices/tripSlice';
import vehicleReducer from './slices/vehicleSlice';
import paymentReducer from './slices/paymentSlice';
import dashboardReducer from './slices/dashboardSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  car: carReducer,
  admin: adminReducer,
  trip: tripReducer,
  vehicle: vehicleReducer,
  payment: paymentReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
