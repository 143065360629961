import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BrandTable from '../../components/MasterData/BrandTable';
import BrandForm from '../../components/MasterData/BrandForm';

const BrandLists = () => {
    const [selectedTab, setSelectedTab] = useState('listed');
    const brands = useSelector(state => state.vehicle.brands); 

  return (
    <div className="w-full">
      <div className='overflow-x-scroll hide-scrollbar'>
        <div className='flex gap-4 mb-4'>
          <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'listed' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => {
                setSelectedTab('listed');
              }}
            >
              Listed Brands <span className='bg-success text-white rounded-md px-3 py-1 text-[12px]'>{brands.length}</span>
          </button>
          <button
            className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'new' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
            onClick={() => {
              setSelectedTab('new');
            }}
          >
            Add New Brand
          </button>
        </div>
      </div>
      {selectedTab === 'listed' ? (
            <div className='overflow-x-scroll hide-scrollbar'>
                <BrandTable dataList={brands} />
            </div>
      ):(
        <div className='w-[90%] md:w-1/3 mx-auto md:mx-0'>
          <BrandForm setSelectedTab={setSelectedTab}/>
        </div>
      )}
      
    </div>
  );
};

export default BrandLists;
