import { Field, Form, Formik } from 'formik';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../store/slices/authSlice';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const ChangePassForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isLoading = useSelector(state => state.auth.isLoading);

    const initialValues = {
        password: '',
        confirmPassword: ''
    };

    const handleChangePassword = (password) => {
        dispatch(changePassword({ password, navigate }))  
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string()
            // .oneOf([Yup.ref('password'), null], 'Passwords must match') // Ensure confirmPassword matches password
            .required('Confirm password is required')
    });

    const handleSubmit = async (values) => {
        try {

            if (values.password !== values.confirmPassword) {
                console.log("passwords do not match")
                toast.error("Passwords do not match")
                return;
            }
            await handleChangePassword(values.password);

        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <>
            <div className="w-full md:w-1/3 py-4">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className='w-full flex flex-col gap-4'>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>New Password</label>
                            <Field
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Enter new password"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Confirm Password</label>
                            <Field
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirm new password"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <button
                            type="submit"
                            className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                        >
                            {isLoading ?
                                <ClipLoader
                                    color="custom-primary"
                                    loading="loading"
                                    css="override"
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                                :
                                'Continue'
                            }
                        </button>
                    </Form>
                </Formik>
            </div>
        </>
    );
};

export default ChangePassForm;
