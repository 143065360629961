import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Management from "./views/Management";
import CreateAdmin from "./views/CreateAdmin";
import UserManagement from "./views/UserManagement";
import UserProfile from "./views/UserProfile";
import ForgetPassword from "./views/Auth/ForgetPassword";
import ResetPassword from "./views/Auth/ResetPassword";
import RentOrder from "./views/RentOrder";
import Login from "./views/Auth/Login";
import Wrapper from "./views/Wrapper";
import SendBroadcast from "./views/SendBroadcast";
import ManageCars from "./views/ManageCars";
import Payments from "./views/Payments";
import CarDetails from "./views/CarDetails";
import Referrals from "./views/Referrals";
import Profile from "./views/Profile";
import Otp from "./views/Auth/Otp";
import ChangePassword from "./views/Auth/ChangePassword";
import VerifyOtp from "./containers/Auth/VerifyOtp";
import ProtectedRoute from "./containers/ProtectedRoute";
import NotFound from "./views/NotFound";
import MasterData from "./views/MasterData/MasterData";
import Brands from "./views/MasterData/Brands";
import Models from "./views/MasterData/Models";
import Years from "./views/MasterData/Years";
import TripsData from "./views/MasterData/TripsData";
import States from "./views/MasterData/States";
import Cities from "./views/MasterData/Cities";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/admin/" />} />
      <Route path="/admin/" element={<Login/>} />
      <Route path="/admin/auth/forget-password" element={<ForgetPassword/>} />
      <Route path="/admin/auth/reset-password" element={<ResetPassword/>} />
      <Route path="/admin/auth/verify-otp" element={<VerifyOtp/>} />

      <Route path="/admin/dashboard" element={
        <ProtectedRoute>
          <Wrapper Component={Dashboard} />
        </ProtectedRoute>
      } />
      <Route path="/admin/management" element={<ProtectedRoute><Wrapper Component={Management} /></ProtectedRoute>} />
      <Route path="/admin/management/create" element={<ProtectedRoute><Wrapper Component={CreateAdmin} /></ProtectedRoute>} />
      <Route path="/admin/user-management" element={<ProtectedRoute><Wrapper Component={UserManagement} /></ProtectedRoute>} />
      <Route path="/admin/user-management/:userID" element={<ProtectedRoute><Wrapper Component={UserProfile} /></ProtectedRoute>} />
      <Route path="/admin/rent-order" element={<ProtectedRoute><Wrapper Component={RentOrder} /></ProtectedRoute>} />
      <Route path="/admin/send-broadcast" element={<ProtectedRoute><Wrapper Component={SendBroadcast} /></ProtectedRoute>} />
      <Route path="/admin/manage-cars" element={<ProtectedRoute><Wrapper Component={ManageCars} /></ProtectedRoute>} />
      <Route path="/admin/manage-cars/:carID" element={<ProtectedRoute><Wrapper Component={CarDetails} /></ProtectedRoute>} />
      <Route path="/admin/partners-payment" element={<ProtectedRoute><Wrapper Component={Payments} /></ProtectedRoute>} />
      <Route path="/admin/profile" element={<ProtectedRoute><Wrapper Component={Profile} /></ProtectedRoute>} />
      <Route path="/admin/referrals" element={<ProtectedRoute><Wrapper Component={Referrals} /></ProtectedRoute>} />
      <Route path="/admin/profile/get-otp" element={<ProtectedRoute><Wrapper Component={Otp} /></ProtectedRoute>} />
      <Route path="/admin/profile/change-password" element={<ProtectedRoute><Wrapper Component={ChangePassword} /></ProtectedRoute>} />
      <Route path="/admin/master-data" element={<ProtectedRoute><Wrapper Component={MasterData} /></ProtectedRoute>}>
      </Route>
      <Route path="/admin/master-data/brands" element={<ProtectedRoute><Wrapper Component={Brands} /></ProtectedRoute>}/>
      <Route path="/admin/master-data/models/:brandCode" element={<ProtectedRoute><Wrapper Component={Models} /></ProtectedRoute>}/>
      <Route path="/admin/master-data/models/years/:brandCode/:modelCode" element={<ProtectedRoute><Wrapper Component={Years} /></ProtectedRoute>}/>
      <Route path="/admin/master-data/trips" element={<ProtectedRoute><Wrapper Component={TripsData} /></ProtectedRoute>}/>
      <Route path="/admin/master-data/states" element={<ProtectedRoute><Wrapper Component={States} /></ProtectedRoute>}/>
      <Route path="/admin/master-data/cities/:stateCode" element={<ProtectedRoute><Wrapper Component={Cities} /></ProtectedRoute>}/>

      <Route path="/admin/*" element={<NotFound />} />



    </Routes>
  );
};

export default AdminRoutes;
