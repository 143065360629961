import React, { useState } from "react";
import { AiOutlineSearch, AiOutlineBell, AiOutlineMessage, AiOutlineUser } from "react-icons/ai";
import { Link,useNavigate } from "react-router-dom";
import { logout } from "../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const [scrolling] = useState(false);
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false); 
  const adminDetails = useSelector((state) => state.admin.adminProfile)
  const navigate = useNavigate();


    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    let greeting = "";

    if (currentHour >= 5 && currentHour < 12) {
      greeting = "Good morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = "Good afternoon";
    } else {
      greeting = "Good evening";
    }

  const handleImgClick = () => {
    setShowDropdown(!showDropdown); 
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <div
      className={`w-full top-0 left-0 z-30 py-6 px-6 flex items-center justify-between ${
        scrolling ? "bg-transparent text-white" : "bg-transparent text-grey-6"
      }`}
    >
      <div className="w-full flex items-center">
        <div className="flex w-full items-center justify-between">
          <p className="text-lg font-[500]">
            {greeting}! <span className="font-semibold capitalize">{adminDetails?.fullName}</span>
          </p>
          <div className="flex gap-4 items-center">
            <div className="hidden lg:block lg:w-[250px] relative">
              <input
                className="w-full py-2.5 pe-2 ps-10 outline-none text-sm bg-transparent border border-grey-3 rounded-lg"
                type="text"
                placeholder="Type here..."
              />
              <AiOutlineSearch className=" absolute top-3.5 left-3" />
            </div>
            <AiOutlineBell className="text-xl font-ligt text-gray-600" />
            <AiOutlineMessage className="text-xl font-light text-gray-600"/>
            <div
              className="w-9 h-9 rounded-full cursor-pointer flex justify-center items-center border border-gray-600"
              onClick={handleImgClick}
              style={{ position: "relative" }}
            >
              <AiOutlineUser/>
              {/* Dropdown */}
              {showDropdown && (
                <div className="absolute top-10 right-0 bg-white border border-gray-300 shadow-lg rounded-md z-50">
                  <button className="block w-full text-left py-2.5 px-6 hover:text-brand text-sm" onClick={handleLogout}>Logout</button>
                  <Link to="/profile">
                    <button className="block w-full text-left py-2.5 px-6 hover:text-brand text-sm">Profile</button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
