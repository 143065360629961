import React from 'react';
import AdminRoutes from './modules/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function App() {
  return (
        <>
          <ToastContainer />
          <AdminRoutes/>
        </>
  );
}
