import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCity } from '../../store/slices/vehicleSlice';
import CityTable from '../../components/MasterData/CityTable';
import CityForm from '../../components/MasterData/CityForm';

const CityLists = () => {
    const [selectedTab, setSelectedTab] = useState('listed');
    const { stateCode } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchCity = async () => {
            try {
                await dispatch(getCity({ stateCode }));
            } catch (error) {
                console.error(`Error fetching models for city`, error);
            }
        };

        fetchCity();
    }, [stateCode, dispatch]);

    const vehicles = useSelector(state => state.vehicle);
    const cities = vehicles.cities;
    const loading = vehicles.isLoading;
    

    return (
        <div className="w-full">
            <div className='overflow-x-scroll hide-scrollbar'>
                <div className='flex gap-4 mb-4'>
                    <button
                        className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'listed' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
                        type='button'
                        onClick={() => {
                            setSelectedTab('listed');
                        }}
                    >
                        Listed Cities <span className='bg-success text-white rounded-md px-3 py-1 text-[12px]'>{cities ? cities.length : 0}</span>
                    </button>
                    <button
                        type='button'
                        className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'new' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
                        onClick={() => {
                            setSelectedTab('new');
                        }}
                    >
                        Add New City
                    </button>
                </div>
            </div>
            {selectedTab === 'listed' && cities ? (
                <div className='overflow-x-scroll hide-scrollbar'>
                    <CityTable dataList={cities} loading={loading}  />
                </div>
            ) : (
                <CityForm setSelectedTab={setSelectedTab} stateCode={stateCode}/>
            )}
        </div>
    );
};

export default CityLists;
