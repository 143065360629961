import React, { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

const Dropdown = ({ xAxisUnit, handleXAxisChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    handleXAxisChange(option);
    setIsOpen(false);
  };

  return (
    <div className="w-full relative z-20 h-full">
      <div
        className="w-full flex justify-between items-center py-2 px-3 bg-transparent border border-primary text-primary rounded-[4px] outline-none cursor-pointer whitespace-nowrap"
        onClick={toggleDropdown}
      >
        <div>{xAxisUnit}</div>
        <div className="ml-2">
          <MdArrowDropDown className="text-primary" />
        </div>
      </div>

      {isOpen && (
        <div className="w-full absolute top-full left-0 bg-white text-primary shadow-md primary border-primary mt-1">
          {options.map((option) => (
            <div
              key={option}
              className="px-7 py-2 cursor-pointer hover:bg-primary hover:text-white whitespace-nowrap"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
