import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { addState, updateState } from '../../store/slices/vehicleSlice'; // Import updateState action
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const StateForm = ({ selectedState, onClose, setSelectedTab }) => {
    const dispatch = useDispatch();
    const { status } = useSelector((state) => state.vehicle);

    const initialValues = {
        name: selectedState ? selectedState?.stateName : '', 
    };
    

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('State name is required')
            .min(2, 'State name must be at least 2 characters')
            .max(50, 'State name must be at most 50 characters'),
    });

    const handleSubmit = async (values) => {
        if (selectedState) {
            dispatch(updateState({ stateCode: selectedState.stateCode, name: values.name }));
            onClose();
        } else {
            dispatch(addState({ name: values.name }));
            setSelectedTab("list");
            // if (status === 'succeeded') {
            //     setSelectedTab('listed');
            //   }
        }
    };

    const vehicleState = useSelector(state => state.vehicle);
    const loading = vehicleState.isLoading;

    return (
        <>
            <div className="w-full">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form className='w-full flex flex-col gap-4 mt-6'>
                            <div className={`flex flex-col w-full gap-2 `}>
                                <label className='text-[14px] font-semibold text-custom-secondary'>Enter State Name</label>
                                <Field
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Input State Name"
                                    className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                                />
                                <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
                            </div>
                            
                            <button
                                type="submit"
                                className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                                disabled={loading}
                            >
                                {loading ? (
                                    <ClipLoader
                                        color="custom-primary"
                                        loading="loading"
                                        css="override"
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                ) : (
                                    `${selectedState ? 'Update' : 'Save'} State` // Change button text based on selectedState presence
                                )}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default StateForm;
