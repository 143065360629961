import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import { addCity, updateCity } from '../../store/slices/vehicleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const CityForm = ({ selectedCity, onClose, setSelectedTab, stateCode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);
  const loading = vehicle.isLoading;
  const states = vehicle.states || [];

  const goBack = () => {
    navigate(-1);
  };

  const initialValues = {
    name: selectedCity ? selectedCity.cityName : '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('City name is required')
      .min(2, 'City name must be at least 2 characters')
      .max(50, 'City name must be at most 50 characters'),
  });

  const handleSubmit = async (values) => {
    if (selectedCity) {
      dispatch(updateCity({ cityCode: selectedCity.cityCode, name: values.name, stateCode: selectedCity?.stateCode }));
      onClose();
    } else {
      dispatch(addCity({ name: values.name, stateCode }));
      setSelectedTab('list');
    }
  };

  return (
    <div className="w-full md:w-1/3">
      <div className="flex items-center gap-3 h-12 hover:cursor-pointer" onClick={goBack}>
        <button className="rounded-[100%] bg-primary flex p-2 justify-center items-center">
          <BsArrowLeft className="text-white font-bold h-4 w-4" />
        </button>
        <h4 className="text-md text-tertiary font-semibold">Back</h4>
      </div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting }) => (
          <Form className="w-full flex flex-col gap-4 mt-6">
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">State</label>
              <Field
                type="text"
                id="stateCode"
                name="stateCode"
                value={states.find((state) => state.stateCode === (stateCode || selectedCity?.stateCode))?.stateName || "State not found"}
                disabled
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light cursor-not-allowed"
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">Enter City</label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder="Input City Name"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage name="name" component="div" className="text-red-500" />
            </div>
            <button
              type="submit"
              className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader
                  color="custom-primary"
                  loading="loading"
                  css="override"
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                `${selectedCity ? 'Update' : 'Save'} City`
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CityForm;
