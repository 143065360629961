import React from 'react';
import TripStatusPart from '../partials/TripStatusPart';
import ProgressLine from './ProgressLine';

const TripStatus = ({data}) => {
  return (
    <div className='w-full flex gap-6'>
      {data.map((status, index) => (
        <div className='flex flex-col' key={index}>
            <TripStatusPart
            number={status.number}
            statusType={status.statusType}
            svgPath={status.svgPath}
            />
            <ProgressLine progress={status.progress} unshadedColor="bg-grey-1" shadedColor="bg-brand"/>
        </div>
      ))}
    </div>
  );
};

export default TripStatus;
