import React from 'react';
import useImageLoader from '../components/useImageLoader';

const ContactInfo = ({ userProfile, userPaymentBank }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();

    console.log(userPaymentBank);

    const [userProfileObj = {}] = userProfile;
    const { emailAddress, fullName, profilePic } = userProfileObj;

    return (
        <div className='border-[1px] rounded-[4px] px-4 py-4 flex flex-col lg:flex-row md:justify-between'>
            <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
                <img
                    src={profilePic}
                    className='w-24 h-24 rounded-2xl'
                    alt="Customer Pic"
                    onLoad={handleImageLoad}
                    style={{ opacity: imageLoaded? 1 : 0, transition: 'opacity 0.5s' }}
                />
                <div className='flex flex-col mb-2'>
                    <p className='text-[14px] font-semibold text-grey-6 capitalize'>{fullName}</p>
                    <p className='text-sm text-grey-5'>{emailAddress}</p>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row lg:items-center lg:gap-8'>
                {userPaymentBank.length > 0 && (
                    <div className='flex flex-col gap-2'>
                        <div className='flex justify-between'>
                            <span className='text-brand whitespace-nowrap'>Account numbers:</span>
                            <ul>
                                {userPaymentBank?.map((bank, index) => (
                                    <li key={index}>
                                        <span className='text-grey-7 mb-2'>{bank?.accountNumber}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        
                        <div className=''>
                            <span className='text-grey-5 whitespace-nowrap'>Bank Names:</span>
                            <ul>
                                {userPaymentBank?.map((bank, index) => (
                                    <li key={index}>
                                        <span className='text-grey-5 whitespace-nowrap'>{bank?.bankName}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                <div className='w-full flex gap-3 items-center'>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer"/>
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-comp-6 dark:peer-focus:ring-comp-6 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                    </label>
                    <span className='text-[14px] text-grey-6'>Suspend User</span>               
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
