import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import UpdateModal from './UpdateModal';
import StateForm from './StateForm';
import { deleteState, getCity } from '../../store/slices/vehicleSlice';
import { showCustomConfirmAlert } from '../CustomConfirmAlert';
import { toast } from 'react-toastify';

const StateTable = ({ dataList }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedState, setSelectedState] = useState(null);

    const openModal = (state) => {
        setSelectedState(state);
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setSelectedState(null);
        setIsModalOpen(false);
    };

    const dispatch = useDispatch();
    const [cityCounts, setCityCounts] = useState({});
    const navigate = useNavigate();
    const vehicle = useSelector((state) => state.vehicle); 
    const loading = vehicle.isLoading;

    useEffect(() => {
        const fetchCityCounts = async () => {
            const counts = {};
            for (const data of dataList) {
                try {
                    const response = await dispatch(getCity({ stateCode: data.stateCode }));
                    const cityCount = response.payload.length; 
                    counts[data.stateCode] = cityCount;
                } catch (error) {
                    console.error(`Error fetching models for state ${data?.stateCode}:`, error);
                }
            }
            setCityCounts(counts);
        };

        fetchCityCounts();

        return () => {
        };
    }, [dataList, dispatch]);

    const handleViewCities = (stateCode, stateName) => {
        navigate(`/master-data/cities/${stateCode}`, { state: { stateName } });
    };

    const handleDeleteState = (stateCode) => {
        showCustomConfirmAlert(() => {
            dispatch(deleteState(stateCode))
              .unwrap()
              .catch(error => {
                   toast.error(error.message);
               });
        });
    };

    return (
        <div className='w-full'>
            {loading? (
                <Loader loading={loading}/>
            ) : (
                <table className='w-full table-auto text-grey-6'>
                    <thead>
                        <tr>
                            <th className='px-6 py-3 text-left whitespace-nowrap'>S/N</th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'>State</th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'>Cities</th>
                            <th className='px-6 py-3 text-left whitespace-nowrap'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((data, index) => (
                            <tr key={index} className={`px-6 ${index % 2 === 0? 'bg-comp-6' : 'bg-transparent'}`}>
                                <td className='px-6 py-3 whitespace-nowrap'>{index + 1}</td>
                                <td className='px-6 py-3 whitespace-nowrap'>{data?.stateName}</td>
                                <td className='px-6 py-3 whitespace-nowrap'>{cityCounts[data?.stateCode] || 0}</td>
                                <td className='px-6 py-3 whitespace-nowrap'>
                                        <button className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit' onClick={() => handleViewCities(data.stateCode, data.stateName)}>
                                            See Cities
                                        </button>
                                </td>
                                <td className='px-6 py-3 whitespace-nowrap'>
                                <button
                                    className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                                    onClick={() => handleDeleteState(data?.stateCode)} // Modified line
                                >
                                    Delete State
                                </button>
                                </td>
                                <td className='px-6 py-3 whitespace-nowrap'>
                                <button
                                    className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                                    onClick={() => openModal(data)}
                                >
                                    Edit State
                                </button>

                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <UpdateModal isOpen={isModalOpen} onClose={closeModal}>
                <StateForm selectedState={selectedState} onClose={closeModal}/>
            </UpdateModal>

        </div>
    );
};

export default StateTable;
