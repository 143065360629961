import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

const OtpForm = () => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [timer, setTimer] = useState(60);
    const [buttonSubmitting] = useState(false);

    // Initialize refs at the top level
    const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleInput = (index, value) => {
        setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value;

            // Move focus to the next field if not the last one
            if (index < newOtp.length - 1 && value !== '') {
                const nextRef = refs[index + 1];

                // Check if the next ref exists before focusing
                if (nextRef && nextRef.current) {
                    nextRef.current.focus();
                }
            }

            return newOtp;
        });
    };

    const handleResendCode = () => {
        // Add logic to resend code
        setTimer(60); // Reset the timer
    };

    useEffect(() => {
        // Countdown timer logic
        const interval = setInterval(() => {
            setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="w-1/3 py-4">
            <p className='text-[18px] text-grey-5 mb-8'>Input PIN sent to your email</p>
            <div className="grid grid-cols-4 gap-2 w-2/3">
                {otp.map((code, index) => (
                    <input
                        key={index}
                        type="text"
                        value={code}
                        onChange={(e) => handleInput(index, e.target.value)}
                        ref={refs[index]}
                        maxLength="1"
                        className={`py-[10px] rounded-[2.5px] w-full text-center placeholder:text-[13px] bg-transparent border-2 border-brand text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                    />
                ))}
            </div>

            <div className="flex items-center w-full gap-2 my-8">
                <button
                    type="button"
                    className="text-brand font-semibold"
                    onClick={handleResendCode}
                    disabled={timer > 0}
                >
                    Resend Code
                </button>
                <p className='text-brand font-semibold'>{timer > 0 ? `00:${timer < 10 ? `0${timer}` : timer}` : ''}</p>
            </div>

            <Link to="/profile/reset-password">
                <button
                    type="submit"
                    className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                >
                    {buttonSubmitting ?
                        <ClipLoader
                            color="custom-primary"
                            loading="loading"
                            css="override"
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        :
                        'Continue'
                    }
                </button>
            </Link>
        </div>
    );
};

export default OtpForm;
