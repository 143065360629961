import React from 'react'
import PendingOrderTable from '../components/PendingOrderTable';

const PendingOrder = () => {
    const tableHeaders = ['Car Brand', 'Duration', 'Total Cost', 'Status', 'Requested Info', ''];

    const tableData = [
        {
          'Car Brand': { icon: 'toyota-icon.png', name: 'Toyota' },
          'Duration': {
            dateTimeFrom: '2023-11-01T08:00:00Z',
            dateTimeTo: '2023-11-04T17:30:00Z',
          },
          'Total Cost': '₦30,000,000',
          'Status': 'Pending',
          'Requested Info': {
            customerName: 'John Doe',
            profilePic: 'john-doe-pic.png',
            text: <button className='text-success text-[14px]' onClick={() => console.log('Details clicked')}>See Profile</button>,
          },
          '': <button className='text-primary text-[14px]' onClick={() => console.log('Details clicked')}>See full details</button>,
        },
        {
          'Car Brand': { icon: 'honda-icon.png', name: 'Honda' },
          'Duration': {
            dateTimeFrom: '2023-11-05T10:00:00Z',
            dateTimeTo: '2023-11-07T15:45:00Z',
          },
          'Total Cost': '₦25,000,000',
          'Status': 'Completed',
          'Requested Info': {
            customerName: 'Jane Smith',
            profilePic: 'jane-smith-pic.png',
            text: <button className='text-success text-[14px]' onClick={() => console.log('Details clicked')}>See Profile</button>,
          },
          '': <button className='text-primary text-[14px]' onClick={() => console.log('Details clicked')}>See full details</button>,
        },
        {
            'Car Brand': { icon: 'toyota-icon.png', name: 'Toyota' },
            'Duration': {
              dateTimeFrom: '2023-11-01T08:00:00Z',
              dateTimeTo: '2023-11-04T17:30:00Z',
            },
            'Total Cost': '₦30,000,000',
            'Status': 'Pending',
            'Requested Info': {
              customerName: 'John Doe',
              profilePic: 'john-doe-pic.png',
              text: <button className='text-success text-[14px]' onClick={() => console.log('Details clicked')}>See Profile</button>,
            },
            '': <button className='text-primary text-[14px]' onClick={() => console.log('Details clicked')}>See full details</button>,
          },
          {
            'Car Brand': { icon: 'honda-icon.png', name: 'Honda' },
            'Duration': {
              dateTimeFrom: '2023-11-05T10:00:00Z',
              dateTimeTo: '2023-11-07T15:45:00Z',
            },
            'Total Cost': '₦25,000,000',
            'Status': 'Completed',
            'Requested Info': {
              customerName: 'Jane Smith',
              profilePic: 'jane-smith-pic.png',
              text: <button className='text-success text-[14px]' onClick={() => console.log('Details clicked')}>See Profile</button>,
            },
            '': <button className='text-primary text-[14px]' onClick={() => console.log('Details clicked')}>See full details</button>,
          },
      ];

      

  return (
    <div className="w-full border border-grey-2 py-4">
        <div className='flex justify-between mb-8 px-4'>
            <h4 className='font-semibold text-grey-4'>Pending Self drive booking</h4>
            <button className='text-primary text-[14px]' onClick={() => console.log('Details clicked')}>See all</button>
        </div>
        <div className='overflow-x-scroll scrollbar'>
          <PendingOrderTable data={tableData} headers={tableHeaders} />
        </div>
    </div>
  )
}

export default PendingOrder