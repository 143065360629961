import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDispatch, useSelector } from 'react-redux';
import { addVehicleYear, updateVehicleYear } from '../../store/slices/vehicleSlice';
import { useParams } from 'react-router-dom';

const YearForm = ({ selectedYear, onClose, setSelectedTab }) => {
  const { brandCode, modelCode } = useParams();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);

  // Memoize brands and models to avoid unnecessary recalculations
  const brands = useMemo(() => vehicle.brands || [], [vehicle.brands]);
  const models = useMemo(() => vehicle.brandModels || [], [vehicle.brandModels]);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  useEffect(() => {
    const model = models.find((model) => model.modelCode === modelCode);
    if (model) {
      setSelectedModel(model);
    }
  }, [modelCode, models]);

  useEffect(() => {
    const brand = brands.find((brand) => brand.brandCode === brandCode);
    if (brand) {
      setSelectedBrand(brand);
    }
  }, [brandCode, brands]);

  const vehicleState = useSelector((state) => state.vehicle);
  const loading = vehicleState.isLoading;

  const priceRanges = [
    { label: 'Select Advice Price', value: '' },
    { label: '₦50,000 - ₦100,000', value: '50000-100000' },
    { label: '₦100,001 - ₦500,000', value: '100001-500000' },
    { label: '₦500,001 - ₦1,000,000', value: '500001-1000000' },
    { label: '₦1,000,001 - ₦2,000,000', value: '1000001-2000000' },
    { label: '₦2,000,001 - ₦3,000,000', value: '2000001-3000000' },
    { label: '₦3,000,001 - ₦4,000,000', value: '3000001-4000000' },
    { label: '₦4,000,001 - ₦5,000,000', value: '4000001-5000000' },
    { label: '₦5,000,001 - ₦6,000,000', value: '5000001-6000000' },
    { label: '₦6,000,001 - ₦7,000,000', value: '6000001-7000000' },
    { label: '₦7,000,001 - ₦8,000,000', value: '7000001-8000000' },
    { label: '₦8,000,001 - ₦9,000,000', value: '8000001-9000000' },
    { label: '₦9,000,001 - ₦10,000,000', value: '9000001-10000000' },
  ];

  const initialAdvicePrice = priceRanges.find(
    (price) => price.value === (selectedYear ? selectedYear?.advicePrice : '')
  );

  const initialValues = {
    year: selectedYear ? selectedYear.yearName : '',
    advicePrice: initialAdvicePrice ? initialAdvicePrice.value : '',
    cautionFee: selectedYear ? selectedYear.cautionFee : '',
  };

  const validationSchema = Yup.object().shape({
    year: Yup.string()
      .required('Year is required')
      .matches(/^[0-9]{4}$/, 'Year must be a valid 4-digit number'),
    advicePrice: Yup.string().required('Advice Price is required'),
    cautionFee: Yup.number()
      .required('Caution Fee is required')
      .min(0, 'Caution Fee must be a positive number'),
  });

  const handleSubmit = async (values) => {
    if (selectedYear) {
      dispatch(
        updateVehicleYear({
          brandModelCode: modelCode,
          year: values.year,
          advicePrice: values.advicePrice,
          cautionFee: values.cautionFee,
          yearCode: selectedYear.yearCode,
          brandCode: brandCode,
        })
      );
      onClose();
    } else {
      dispatch(
        addVehicleYear({
          year: values.year,
          advicePrice: values.advicePrice,
          cautionFee: values.cautionFee, // Include caution fee in payload
          brandCode: brandCode,
          brandModelCode: modelCode,
        })
      );
      setSelectedTab('list');
    }
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  return (
    <div className={`w-full ${selectedYear ? 'md:w-full' : 'md:w-1/2'}`}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="w-full flex flex-col gap-4">
            <h2 className="text-2xl text-center font-semibold text-black">
              {selectedYear ? 'Update' : 'Add'} Year
            </h2>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                Brand
              </label>
              <Field
                type="text"
                id="brandName"
                name="brandName"
                value={selectedBrand ? selectedBrand.brandName : ''}
                disabled
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage
                name="brandName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                Select Brand Model
              </label>
              <Field
                type="text"
                id="modelName"
                name="modelName"
                value={selectedModel ? selectedModel.modelName : ''}
                disabled
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage
                name="brandModelCode"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                Enter Year
              </label>
              <Field
                as="select"
                id="year"
                name="year"
                placeholder="Select Year"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              >
                <option value="">Select Year</option>
                {generateYearOptions()}
              </Field>
              <ErrorMessage
                name="year"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                Enter Advice Price
              </label>
              <Field
                as="select"
                id="advicePrice"
                name="advicePrice"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7"
              >
                {priceRanges.map((price) => (
                  <option key={price.value} value={price.value}>
                    {price.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="advicePrice"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                Enter Caution Fee
              </label>
              <Field
                type="number"
                id="cautionFee"
                name="cautionFee"
                placeholder="Input Caution Fee"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
              <ErrorMessage
                name="cautionFee"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <button
              type="submit"
              className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader
                  color="custom-primary"
                  loading="loading"
                  css="override"
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                `${selectedYear ? 'Update' : 'Save'} Year`
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default YearForm;
