import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { approveOrDeclineTripApi, countAllTripsApi, getAllTripsApi } from '../../helpers/api';
import { toast } from 'react-toastify';

export const fetchAllTrips = createAsyncThunk(
  'trip/fetchAllTrips',
  async ({ queryParams }) => {
    try {
    const response = await getAllTripsApi({queryParams});
    const responseData = {
      data: response.data,
      count: response.count
    };
      return responseData;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const approveOrDeclineTrip = createAsyncThunk(
  'trip/approveOrDeclineTrip',
  async ({ tripID, status, tripOrderID }, { dispatch }) => {
    try {
      const response = await approveOrDeclineTripApi({ tripID, status, tripOrderID });
      if (response.status_code === 200) {
        toast.success(response.message);
        console.log("at this point")

        dispatch(fetchAllTrips({
          queryParams: {
            tripType: "selfDrive",
            status: "pending",
            limit: 100,
            skip: 0
          }
        }));
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const countAllTrips = createAsyncThunk(
  'payment/countAllCars',
  async (_, { dispatch }) => {
    try {
      const response = await countAllTripsApi();
      return response.data;
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

const tripSlice = createSlice({
  name: 'trip',
  initialState: {
    allTrips: [],
    approvalStatus: 'idle',
    tripCount: null,
    allTripsCount: null,
    status: 'idle',
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTrips.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchAllTrips.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allTrips = action.payload.data;
        state.tripCount = action.payload.count;
        state.isLoading = false;
      })
      .addCase(fetchAllTrips.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(approveOrDeclineTrip.pending, (state) => {
        state.approvalStatus = 'loading';
        state.isLoading = true;
      })
      .addCase(approveOrDeclineTrip.fulfilled, (state) => {
        state.approvalStatus = 'succeeded';
        state.isLoading = false;
      })
      .addCase(approveOrDeclineTrip.rejected, (state, action) => {
        state.approvalStatus = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(countAllTrips.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(countAllTrips.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allTripsCount = action.payload;
        state.isLoading = false;
      })
      .addCase(countAllTrips.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});


export default tripSlice.reducer;
