import React from 'react'
import MasterDataLists from '../../containers/MasterData/MasterDataLists'

const MasterData = () => {
  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='w-full flex flex-col lg:flex-row gap-6 overflow-x-scroll lg:overflow-hidden scrollbar'>\
      <MasterDataLists/>
      </div>
    </div>
  )
}

export default MasterData


    