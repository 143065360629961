import React from 'react'
import TripLists from '../../containers/MasterData/TripLists'

const TripsData = () => {
  return (
    <TripLists/>
    
  )
}

export default TripsData