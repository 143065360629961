import React from 'react';
import DateFormatter from './DateFormatter';
import { BsArrowRight } from 'react-icons/bs';

const DateTime = ({ dateTimeFrom, dateTimeTo }) => {
    const timeFrom = extractTime(dateTimeFrom);
    const timeTo = extractTime(dateTimeTo);

    return (
        <div className='flex justify-between gap-2 items-center'>
            <DateFormatter date={dateTimeFrom} time={timeFrom} />
            <span className='rounded-[100%] border-2 border-primary flex h-6 w-6 justify-center items-center'>
                <BsArrowRight className='text-brand h-4 w-4' />
            </span>
            <DateFormatter date={dateTimeTo} time={timeTo} />
        </div>
    );
};

const extractTime = (dateTime) => {
    const time = dateTime.split('T')[1].split('.')[0];
    const [hour, minute] = time.split(':');
    const hour12 = parseInt(hour) % 12 || 12; 
    return `${hour12}:${minute} ${parseInt(hour) >= 12 ? 'PM' : 'AM'}`;
};

export default DateTime;
