import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const CustomConfirmAlert = ({ onConfirm, onClose }) => (
  <div className="custom-ui p-6 bg-user-bg rounded shadow-lg text-black">
    <h1 className="text-xl font-bold mb-4 text-black">Confirm Deletion</h1>
    <p className="mb-4">Are you sure you want to delete this data</p>
    <div className="flex justify-end space-x-4">
      <button
        onClick={onClose}
        className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
      >
        Cancel
      </button>
      <button
        onClick={onConfirm}
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
      >
        Confirm
      </button>
    </div>
  </div>
);

export const showCustomConfirmAlert = (onConfirm) => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <CustomConfirmAlert
        onConfirm={() => {
          onConfirm();
          onClose();
        }}
        onClose={onClose}
      />
    )
  });
};
