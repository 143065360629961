import React, { useState } from 'react';
import TransmissionForm from './TransmissionForm';
import FeatureForm from './FeatureForm';
import SeatsForm from './SeatsForm';
import InsuranceTypeForm from './InsuranceTypeForm';
import VehicleTypeForm from './VehicleTypeForm';
import { deleteFeature, deleteInsuranceType, deleteSeat,  deleteTransmission, deleteVehicleType } from '../../store/slices/vehicleSlice';
import { useDispatch } from 'react-redux';
import { showCustomConfirmAlert } from '../CustomConfirmAlert';
import { toast } from 'react-toastify';

const Modal = ({ isOpen, onClose, data, showContent, setShowContent, setDataDisplay, dataDisplay }) => {
  const dispatch = useDispatch();
  const [editItem, setEditItem] = useState(null);

  const handleDelete = (item) => {
    showCustomConfirmAlert(() => {
      switch (showContent) {
        case 'featuresName':
          dispatch(deleteFeature(item.featuresCode))
           .unwrap()
           .catch(error => {
              toast.error(error.message);
            });
          break;
        case 'transmissionName':
          dispatch(deleteTransmission(item.transmissionCode))
           .unwrap()
           .catch(error => {
              toast.error(error.message);
            });
          break;
        case 'seatName':
          dispatch(deleteSeat(item.seatCode))
           .unwrap()
           .catch(error => {
              toast.error(error.message);
            });
          break;
        case 'typeName':
          dispatch(deleteVehicleType(item.typeCode))
           .unwrap()
           .catch(error => {
              toast.error(error.message);
            });
          break;
        case 'insuranceName':
          dispatch(deleteInsuranceType(item.insuranceCode))
           .unwrap()
           .catch(error => {
              toast.error(error.message);
            });
          break;
        default:
          console.error("Unhandled showContent:", showContent);
      }
    });
  };
  
  const handleUpdate = (item) => {
    switch (showContent) {
      case 'featuresName':
        setEditItem(item);
        setDataDisplay("FeaturesForm");
        break;
      case 'transmissionName':
        setEditItem(item);
        setDataDisplay("TransmissionsForm");
        break;
      case 'seatName':
        setEditItem(item);
        setDataDisplay("SeatsForm");
        break;
      case 'typeName':
        setEditItem(item);
        setDataDisplay("Vehicle TypesForm");
        break;
      case 'insuranceName':
        setEditItem(item);
        setDataDisplay("Insurance TypesForm");
        break;
      default:
        console.error("Unhandled showContent:", showContent);
    }
  };

  return (
    <div className={`fixed z-50 inset-0 ${isOpen ? 'block' : 'hidden'} overflow-y-auto`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" onClick={onClose}></div>

        <div className="relative bg-main-bg text-black  p-8 mx-auto rounded-md shadow-xl transform transition-all  flex flex-col items-center  gap-6 w-4/5 sm:w-3/5 lg:w-2/5 overflow-y-scroll scrollbar max-h-[60vh]">
          
            {dataDisplay === "default" && (
              <div className='w-full'>   
                <div className="flex flex-col gap-3 items-center">
                    <h2 className=" text-lg font-semibold">{data && data.title} [{data && data.list.length}] </h2>
                    <button className='text-brand bg-transparent border border-brand hover:border-none hover:bg-brand hover:text-white font-medium py-2 px-4 rounded w-fit' 
                      onClick={() => {
                            setDataDisplay(`${data && data.title}Form`);
                        }}>
                        Add New {data && data.title}
                    </button>
                </div>
                <div className="modal-content p-4 w-full">
                  {data && data.list.map((item, index) => (
                      <div key={index} className="py-2 w-full flex justify-between items-center">
                          <div className="font-semibold">
                              {index + 1}. <span>{item[showContent]}</span>
                          </div> 
                          <div className='flex gap-3'>
                          <button
                            className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                            onClick={() => handleDelete(item)}
                          >
                            Delete
                          </button>
                          <button
                            className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                            onClick={() => handleUpdate(item)}
                          >
                            Edit Details
                          </button>

                          </div>
                      </div>
                  ))}

                </div>
              </div>
            )} 
            {dataDisplay === "FeaturesForm" && (
              <FeatureForm onClose={onClose} setShowContent={setShowContent} setDataDisplay={setDataDisplay} editItem={editItem} setEditItem={setEditItem}/>
            )}  
            {dataDisplay === "TransmissionsForm" && (
              <TransmissionForm onClose={onClose} setShowContent={setShowContent} setDataDisplay={setDataDisplay} editItem={editItem} setEditItem={setEditItem}/>
            )}   
            {dataDisplay === "SeatsForm" && (
              <SeatsForm onClose={onClose} setShowContent={setShowContent} setDataDisplay={setDataDisplay} editItem={editItem} setEditItem={setEditItem}/>
            )}   
            {dataDisplay === "Vehicle TypesForm" && (
              <VehicleTypeForm onClose={onClose} setShowContent={setShowContent} setDataDisplay={setDataDisplay} editItem={editItem} setEditItem={setEditItem}/>
            )}   
            {dataDisplay === "Insurance TypesForm" && (
              <InsuranceTypeForm onClose={onClose} setShowContent={setShowContent} setDataDisplay={setDataDisplay} editItem={editItem} setEditItem={setEditItem}/>
            )}   
 
        </div>
      </div>
    </div>
  );
};

export default Modal;
