import React from 'react';
import { customerPic } from '../../assets';
import { Link } from 'react-router-dom';
import useImageLoader from './useImageLoader';

const ReferralTable = ({ headers, data, selectedTab }) => {
  const { imageLoaded, handleImageLoad } = useImageLoader();

  return (
    <table className="w-full table-auto text-grey-7">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header} className="text-left px-8 py-4 uppercase text-[12px] whitespace-nowrap text-gray-500">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className={index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}>
            {headers.map((header) => (
              <td key={header} className="py-2 px-8 whitespace-nowrap">
                {header === 'User Profile' ? (
                    <div className="flex items-center gap-2">
                        <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                            <img
                                src={customerPic}
                                className='w-4 h-4 rounded-full'
                                alt="Customer Pic"
                                onLoad={handleImageLoad}
                                style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                            />
                        </div>
                        <div className='flex flex-col gap-1'>
                            {row['User Profile'].name}
                            <Link to="/user-profile"><button className='text-success underline text-[14px]' onClick={() => console.log('Details clicked')}>View Profile</button></Link>
                        </div>
                    </div>
                ) : header === 'Unpaid Referral' ? (
                  <div className="flex flex-col">
                    <p className='text-custom-secondary text-[16px] font-semibold'>{row['Unpaid Referral']}</p>
                    <p className='text-failed text-[10px] font-semibold'>Payment Pending</p>
                  </div>
                ) : header === 'More Actions' ? (
                  <div className="flex flex-col items-end gap-3">
                    <p className='text-custom-secondary text-[16px]'>I have made Payment to <span className='font-semibold'>{row['User Profile'].name}</span></p>
                      <button className={`py-1.5 px-4 bg-brand text-white rounded-[4px]`}>
                        Yes, Payment Paid
                      </button>

                  </div>
                ) : (
                  row[header]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ReferralTable;
