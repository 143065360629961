import React, { useState } from 'react';
import { FaThumbsUp } from 'react-icons/fa';
import { BiMoney } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';
import Dropdown2 from './Dropdown2';
import { useDispatch } from 'react-redux';
import { updateCarStatus } from '../store/slices/carSlice';
import { useNavigate } from 'react-router-dom';
import useImageLoader from './useImageLoader';

const CarCard2 = ({ selectedCarDetails }) => {
    const [dropDown, setDropDown] = useState(selectedCarDetails[0]?.status);
    const options = ['approved', 'pending', 'suspended', 'declined'];
    const navigate = useNavigate();

    const { imageLoaded, handleImageLoad } = useImageLoader();
    const dispatch = useDispatch();

    if (!selectedCarDetails) {
        return null
    }

    const dateTimeFrom = selectedCarDetails[0]?.startDate;
    const dateTimeTo = selectedCarDetails[0]?.endDate;

    const timeFrom = dateTimeFrom?.split('T')[1].split('.')[0];
    const timeTo = dateTimeTo?.split('T')[1].split('.')[0];

    const formattedDateFrom = new Date(dateTimeFrom).toLocaleDateString('en-UK', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
    });

    const formattedDateTo = new Date(dateTimeTo).toLocaleDateString('en-UK', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
    });


    const handleDropDownChange = (status) => {
        let newStatus;
        
        switch (status) {
            case 'approve':
                newStatus = 'approved';
                break;
            case 'suspend':
                newStatus = 'suspended';
                break;
            case 'decline':
                newStatus = 'declined';
                break;
            default:
                newStatus = status;         }
        
        dispatch(updateCarStatus({ carID: selectedCarDetails[0]?.carID, status: newStatus, navigate }));
        setDropDown(newStatus);
    };
    

    return (
        <div className='flex flex-col lg:flex-row justify-between w-full'>
            <div className='flex flex-col md:flex-row gap-4'>
                <img
                    src={selectedCarDetails[0]?.photoUrl}
                    className='w-72 h-48 rounded-md'
                    alt="vehicle"
                    onLoad={handleImageLoad}
                    style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                />
                <div className='flex flex-col gap-2'>
                    <h4 className='text-[22px] font-semibold mt-4 text-grey-7'>{selectedCarDetails[0]?.brand[0]?.brandName} {selectedCarDetails[0]?.brandModel[0]?.modelName} {selectedCarDetails[0]?.modelYear[0]?.yearName}</h4>
                    <p className='text-grey-6 flex items-center'>
                        <BiMoney className='mr-1' />
                        <span className='text-grey-6 font-semibold'>#{selectedCarDetails[0]?.pricePerDay}/day</span>
                    </p>

                    <p className='text-grey-5 flex gap-4'>
                        <span className='text-grey-5'>Discounts: </span>
                        <span className='text-grey-5'>{selectedCarDetails[0]?.discountPrice}% Off, {selectedCarDetails[0]?.discountDays} days above</span>
                    </p>

                    <p className='text-grey-5 flex gap-4'>
                        <span className='text-grey-5'>Car Availability: </span>
                        <span className='text-grey-5 capitalize'>{selectedCarDetails[0]?.availability}</span>
                    </p>


                    <p className='text-grey-5 flex gap-4'>
                        <span className='text-grey-5'>Advance Notice: </span>
                        <span className='text-grey-5 capitalize'>{selectedCarDetails[0]?.advanceDays}</span>
                    </p>

                    <div className='flex gap-3 items-center'>
                        <p className='text-[12px] text-grey-5'>
                            {formattedDateFrom}, {timeFrom}
                        </p>
                        <span className='rounded-[100%] border border-grey-5 flex h-6 w-6 justify-center items-center'>
                            <BsArrowRight className='text-grey-5' />
                        </span>
                        <p className='text-[12px] text-grey-5'>
                            {formattedDateTo}, {timeTo}
                        </p>
                    </div>
                    <p className='text-grey-5 flex gap-2 items-center'>
                        <FaThumbsUp/>
                        <span className=''>{selectedCarDetails[0]?.rating}%</span>
                        <span className='text-grey-5'>{selectedCarDetails[0]?.tripsCompleted} Trips completed</span>
                    </p>
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex gap-2 items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <path d="M10.1049 10.2548L9.31353 11.0374C8.73026 11.6098 7.97352 12.3458 7.04292 13.2454C6.46146 13.8077 5.53892 13.8076 4.95752 13.2453L2.6301 10.9814C2.33759 10.6942 2.09274 10.452 1.89552 10.2548C-0.371422 7.98783 -0.371422 4.31236 1.89552 2.04542C4.16246 -0.22152 7.83792 -0.22152 10.1049 2.04542C12.3718 4.31236 12.3718 7.98783 10.1049 10.2548ZM7.66699 6.33323C7.66699 5.41266 6.92072 4.66641 6.00019 4.66641C5.07966 4.66641 4.33338 5.41266 4.33338 6.33323C4.33338 7.25376 5.07966 8.00003 6.00019 8.00003C6.92072 8.00003 7.66699 7.25376 7.66699 6.33323Z" fill="#5C5C5C"/>
                    </svg>
                    <h4 className='text-grey-7'>
                        {selectedCarDetails[0]?.city[0]?.cityName}, {selectedCarDetails[0]?.state[0]?.stateName} State
                    </h4>
                </div>
                <h4 className='text-grey-7'>
                    Vehicle Status
                </h4>
                <div className='w-full'>
                    <Dropdown2 dropDown={dropDown} handleDropDownChange={handleDropDownChange} options={options} />
                </div>
            </div>
        </div>
    );
};

export default CarCard2;
