import React, { useState } from 'react';
import BarChart from '../components/BarChart';
import Dropdown from '../components/Dropdown';

const Chart = () => {
  const [xAxisUnit, setXAxisUnit] = useState('Daily');
  const options = ['Daily', 'Weekly', 'Monthly'];

  const allData = {
    Daily: [30, 28, 34, 38, 49, 50, 43],
    Weekly: [50, 65, 48, 60],
    Monthly: [550, 300, 120, 230, 380, 80, 164, 140, 68, 82, 560, 600],
  };

  const handleXAxisChange = (unit) => {
    setXAxisUnit(unit);
  };

  return (
    <div className="w-full h-full">
      <div className="mb-4 flex justify-between">
        <div className='py-3'>
          <h4 className='text-gray-500 font-semibold whitespace-nowrap'>Car Ordered    <span className='text-green-500 font-bold text-[14px]'>(+23%) <span className='text-gray-400'>than yesterday</span></span></h4>

        </div>
        <div className='w-[150px] h-full'>
          <Dropdown xAxisUnit={xAxisUnit} handleXAxisChange={handleXAxisChange} options={options} />
        </div>
      </div>

      <div className='chart-bg px-3 pt-3 pb-10 rounded-md'>
        <BarChart
          data={allData[xAxisUnit]}
          yAxisInterval={100}
          xAxisUnit={xAxisUnit}
        />
      </div>
    </div>
  );
};

export default Chart;
