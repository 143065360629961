import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { fetchAdminProfile, updateAdminProfile } from '../store/slices/adminSlice';
import useImageLoader from '../../../components/useImageLoader';
import { ProfileIcon, pen, profilePic } from '../../../assets';

const ProfileDetails = () => {
  const { imageLoaded, handleImageLoad } = useImageLoader();
  const [editable, setEditable] = useState(false);
  const [adminProfile, setAdminProfile] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.user);



  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAdminProfile({ userID: userDetails?.data.userID }));
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [dispatch, userDetails?.data.userID]);

  const adminDetails = useSelector((state) => state.admin.adminProfile);

  if (!userDetails) {
    return null;
  }

  if (!adminDetails) {
    return null;
  }

  const initialValues = {
    fullName: adminDetails?.fullName || '',
    emailAddress: adminDetails?.emailAddress || '',
    phoneNumber: adminDetails?.phoneNumber || '',
    profilePic: adminDetails?.profilePic || null,
  };


  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    emailAddress: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
  });

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const temporaryImageUrl = URL.createObjectURL(file);
      setAdminProfile((prevState) => ({ ...prevState, profilePic: temporaryImageUrl }));
      setSelectedFile(file);
      setFieldValue("profilePic", file);
    }
  };

  const handleUpdateProfile = async (values) => {
    try {
      await dispatch(updateAdminProfile({
        userID: userDetails?.data.userID,
        fullName: values.fullName,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber,
        profilePic: selectedFile // Pass the selected file here
      }));
      setEditable(false);
    } catch (error) {
      console.error('Error updating profile:', error.message);
    }
  };
  

  return (
    <div className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateProfile}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div
              className='w-full md:w-fit flex justify-center items-center bg-black text-white rounded-full relative'
              onClick={handleDivClick}
            >
              <img
                src={adminProfile.profilePic || adminDetails?.profilePic}
                className='w-full sm:w-1/2 md:w-48 rounded-full'
                alt="Customer Pic"
                onLoad={handleImageLoad}
                style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
              />
              <div className='w-8 h-8 absolute bg-brand flex justify-center items-center rounded-full bottom-4 right-0'>
                <img
                  src={pen}
                  className='w-4 h-4 rounded-full relative'
                  alt="User Pen"
                  onLoad={handleImageLoad}
                  style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                />
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, setFieldValue)}
            />

            <div className='w-full flex flex-col gap-4 text-grey-7'>
              <div className=''>
                <h4 className='text-[14px] text-primary'>Name</h4>
                <Field
                  type="text"
                  name="fullName"
                  className="capitalize"
                  disabled={!editable}
                />
                <ErrorMessage name="fullName" component="div" className="error" />
              </div>
              <div className=''>
                <h4 className='text-[14px] text-primary'>Email</h4>
                <Field
                  type="email"
                  name="emailAddress"
                  disabled={!editable}
                />
                <ErrorMessage name="emailAddress" component="div" className="error" />
              </div>
              <div className=''>
                <h4 className='text-[14px] text-primary'>Phone Number</h4>
                <Field
                  type="text"
                  name="phoneNumber"
                  disabled={!editable}
                />
                <ErrorMessage name="phoneNumber" component="div" className="error" />
              </div>
              <div className=''>
                <h4 className='text-[14px] text-primary'>Password</h4>
                <div className='flex flex-col md:flex-row gap-4'>
                  <p className='text-[24px] text-grey-5'>******************</p>
                  <Link to="change-password" className='mb-4'>
                    <button className='bg-comp-7 text-primary px-8 py-3 rounded-[4px] whitespace-nowrap hover:bg-black hover:text-white w-full md:w-fit'>
                      Change Password
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {!editable && (
              <button type="button" className='bg-comp-7 text-primary px-8 py-3 rounded-[4px] whitespace-nowrap hover:bg-black hover:text-white w-full md:w-fit' onClick={() => setEditable(true)}>Edit Profile</button>
            )}

            {editable && (
              <button type="submit" className='bg-comp-7 text-primary px-8 py-3 rounded-[4px] whitespace-nowrap hover:bg-black hover:text-white w-full md:w-fit'>Save Profile</button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileDetails;
