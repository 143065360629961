import React from 'react';

const SummaryCardTwoPart = ({ number, text, bgColor, icon }) => {
  const containerStyle = bgColor ? `p-2 ${bgColor}` : 'p-2 bg-primary';

  return (
    <div className="rounded-lg bg-transaprent p-4 relative border border-grey-2 w-full">
      <div className="flex items-center h-full relative z-10 gap-2 md:gap-6">
        <div className="flex-2 flex items-center gap-2">
          <div className='bg-primary rounded-[100%] h-3 w-3'></div>
          <span className='text-[14px] text-grey-5 whitespace-nowrap'>{text}</span>
        </div>
        <span className="lg:text-3xl font-semibold flex-1 text-grey-7">{number}</span>
        <div className='flex-1 flex justify-end'>
          <div className={containerStyle}>
            {icon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryCardTwoPart;
