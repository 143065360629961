import React from 'react';
import { FaWheelchair } from 'react-icons/fa'; // Import the wheel icon from react-icons library
// ... other imports
import DateTime from './DateTime'; // Assuming you've already created DateTime component
import { MdOutlinePending, MdPending } from 'react-icons/md';
import { BsInfo } from 'react-icons/bs';
import useImageLoader from '../../../components/useImageLoader';
import { customerPic, phone, wheel } from '../../../assets';

const PendingOrderTable = ({ headers, data }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();
  return (
    <table className="w-full table-auto text-grey-6">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header} className="py-2 px-6 whitespace-nowrap">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className={index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}>
            {headers.map((header) => (
              <td key={header} className="py-2 px-6 whitespace-nowrap">
                {header === 'Duration' ? (
                    <DateTime
                        dateTimeFrom={row.Duration.dateTimeFrom}
                        dateTimeTo={row.Duration.dateTimeTo}
                    />
                ) : header === 'Car Brand' ? (
                  <div className="flex items-center gap-2">
                    <div className='w-6 h-6 flex justify-center items-center bg-black text-white rounded-sm'>
                    <img
                        src={wheel}
                        alt="Wheel"
                        onLoad={handleImageLoad}
                        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                    </div>
                    {row['Car Brand'].name}
                  </div>
                ) : header === 'Requested Info' ? (
                    <div className="flex items-center gap-2">
                      <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                        <img
                            src={customerPic}
                            className='w-4 h-4 rounded-full'
                            alt="Customer Pic"
                            onLoad={handleImageLoad}
                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                            />
                      </div>
                      <div className='flex flex-col gap-1'>
                        {row['Requested Info'].customerName}
                        {row['Requested Info'].text}
                      </div>
                    </div>
                ) : header === 'Status' ? (
                    <div className="flex items-center gap-2">
                      <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                        <img
                            src={phone}
                            alt="Phone"
                            onLoad={handleImageLoad}
                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                            />
                      </div>
                      {row['Status']}
                    </div>
                ) : (
                  row[header]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PendingOrderTable;
