import React from 'react';
import useImageLoader from '../components/useImageLoader';

const KycInfo = ({ userKYC }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();



    const openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    if (!userKYC) {
        return null;
    }

    const [userKYCObj = {}] = userKYC;
    const { homeAddress, homeAddressProof, officeAddress, emergencyName, emergencyNumber, emergencyRelationship, licenceFront, licenceBack, licenceNumber, licenceExpireDate } = userKYCObj;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-NG', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    return (
        <div className='border-[1px] rounded-[4px] px-4 py-4 flex flex-col gap-3 h-full'>
            <h4 className='text-[18px] text-grey-7 font-semibold'>OTHER INFO(KYC)</h4>

            <div className='flex flex-col lg:flex-row gap-4 pt-2 pb-6'>
                <div className='flex flex-col w-full lg:w-1/2 '>
                    {homeAddress && (
                        <div className='flex justify-between border-b py-4'>
                            <div className='flex flex-col justify-center'>
                                <h4 className='text-[12px] font-semibold text-grey-7'>Home Address</h4>
                                <p className='text-grey-7 text-[12px] w-1/2'>{homeAddress}</p>
                            </div>
                            {homeAddressProof && (
                                <div className='flex gap-1'>
                                    <div>
                                        <p className='text-grey-7 text-[12px]'>Proof of address document</p>
                                        <button
                                            className='text-primary text-[12px] font-semibold'
                                            onClick={() => openImageInNewTab(homeAddressProof)}
                                        >
                                            View
                                        </button>
                                    </div>
                                    <img
                                        src={homeAddressProof}
                                        className='w-16 h-16 rounded-md'
                                        alt="proof"
                                        onLoad={handleImageLoad}
                                        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {officeAddress && (
                        <div className='flex justify-between border-b py-4'>
                            <div className='flex flex-col justify-center'>
                                <h4 className='text-[12px] font-semibold text-grey-7'>Office Addresss</h4>
                                <p className='text-grey-7 text-[12px] w-1/2'>{officeAddress}</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-col text-[12px] py-4'>
                        <h4 className='text-[12px] font-semibold text-grey-7'>Emergency Contact</h4>
                        {emergencyName && (
                            <p className='text-grey-7 font-medium'>
                                Full Name:
                                <span className='font-normal ml-2'>{emergencyName}</span>
                            </p>
                        )}
                        <p className='text-grey-7 font-medium'>
                            Mobile:
                            <span className='font-normal ml-2'>{emergencyNumber}</span>
                        </p>
                        <p className='text-grey-7 font-medium'>
                            Relationship:
                            <span className='font-normal ml-2'>{emergencyRelationship}</span>
                        </p>
                    </div>
                </div>

                <div className='flex flex-col w-full lg:w-1/2 text-xs'>
                    <div className='px-8'>
                        <h4 className='font-semibold text-grey-7'>License Information</h4>
                        <p className='text-grey-7 font-medium'>
                            Licence Number:
                            <span className='font-normal ml-2'>{licenceNumber}</span>
                        </p>
                        <p className='text-grey-7 font-medium'>
                            Licence Expiry Date:
                            <span className='font-normal ml-2'>{formatDate(licenceExpireDate)}</span>
                        </p>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-2">
                        {licenceFront && (
                            <div className='px-8 border-b py-4'>
                                <div className='flex gap-1'>
                                    <div>
                                        <p className='text-grey-7 text-[12px] w-2/3'>License Front</p>
                                        <button
                                                className='text-primary text-[12px] font-semibold'
                                                onClick={() => openImageInNewTab(licenceFront)}
                                            >
                                                View
                                            </button>
                                    </div>
                                    
                                        <img
                                            src={licenceFront}
                                            className='w-16 h-16 rounded-md'
                                            alt="proof"
                                            onLoad={handleImageLoad}
                                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                                        />
                                </div>
                            </div>
                        )}


                        {licenceBack && (
                            <div className='px-8 border-b py-4'>
                                <div className='flex gap-1'>
                                    <div>
                                        <p className='text-grey-7 text-[12px] w-2/3'>License Back</p>
                                        <button
                                                className='text-primary text-[12px] font-semibold'
                                                onClick={() => openImageInNewTab(licenceBack)}
                                            >
                                                View
                                            </button>
                                    </div>
                                    
                                        <img
                                            src={licenceBack}
                                            className='w-16 h-16 rounded-md'
                                            alt="proof"
                                            onLoad={handleImageLoad}
                                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                                        />
                                </div>
                            </div>
                        )}
                    </div>

                    

                </div>
            </div>
        </div>
    );
};

export default KycInfo;
