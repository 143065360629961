import React from 'react';

const BarChart = ({ data, yAxisInterval, xAxisUnit }) => {
  const generateXAxisLabels = () => {
    switch (xAxisUnit) {
      case 'Daily':
        return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      case 'Weekly':
        return ['Week1', 'Week2', 'Week3', 'Week4'];
      case 'Monthly':
        return [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];
      default:
        return [];
    }
  };

  const generateYAxisLabels = () => {
    const labels = [];
    for (let i = 600; i >= 0; i -= yAxisInterval) {
      labels.push(i);
    }
    return labels;
  };

  const xAxisLabels = generateXAxisLabels();
  const yAxisLabels = generateYAxisLabels();

  return (
    <div className="flex">
      {/* Y-axis labels */}
      <div className="flex flex-col items-end justify-between h-[35vh] pr-2">
        {yAxisLabels.map((label, index) => (
          <div key={index} className="text-xs mb-1">
            {label}
          </div>
        ))}
      </div>

      {/* Bar chart */}
      <div className="flex items-end justify-between w-full px-6">
        {data.map((value, index) => (
          <div
            key={index}
            style={{ height: `${(value / 600) * 100}%` }} // Adjusted to a percentage based on the maximum value of 1000
            className="bg-white m-1 w-4 relative rounded-[15px]"
          >
            {/* X-axis labels below each bar */}
            <div className="text-sm text-center absolute -bottom-8 left-0 right-0">
              {xAxisLabels[index]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
