import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { auth1 } from '../../../assets';
import { Link, useNavigate } from 'react-router-dom';
import { authenticateUser } from '../../store/slices/authSlice';
import { BsEyeFill, BsEyeSlash } from 'react-icons/bs';

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.auth.isLoading);

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleLogin = (email, password) => {
    dispatch(authenticateUser({ email, password, navigate }));
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleSubmit = async (values) => {
    try {
      await handleLogin(values.email, values.password);
    } catch (error) {
      console.error('An error occurred. Please try again later.');
    }
  };

  const divStyle = {
    backgroundImage: `url(${auth1})`,
  };

  return (
    <div className="w-full flex h-screen">
      <div className="w-full h-full py-12 lg:w-1/2 bg-main-bg">
        <div className="flex w-4/5 mx-auto mb-32">
          {/* Logo */}
        </div>
        <div className="flex w-4/5 md:w-3/5 mx-auto flex-col items-center justify-center">
          <h3 className="text-[24px] lg:text-[40px] text-tertiary font-semibold">Welcome Back</h3>
          <p className="text-[16px] text-tertiary font-medium">Log in to your GTI Ride</p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="w-full flex flex-col gap-4 mt-6">
              <div className="flex flex-col w-full gap-2">
                <label className="text-[14px] font-semibold text-custom-secondary">Email</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
                />
              </div>
              <div className="flex flex-col w-full gap-2 relative">
                <label className="text-[14px] font-semibold text-custom-secondary">Password</label>
                <Field
                  type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
                />
                {/* Password eye toggle */}
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-[40px] text-grey-7"
                >
                  {showPassword ? <BsEyeSlash/> : <BsEyeFill/>}
                </button>
              </div>
              <Link to="auth/forget-password">
                <button type="button" className="bg-transparent text-primary text-[14px] text-left mb-3">
                  Forgot password?
                </button>
              </Link>
              <button
                type="submit"
                className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
              >
                {isLoading ? (
                  <ClipLoader
                    color="custom-primary"
                    loading="loading"
                    css="override"
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  'Login'
                )}
              </button>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="hidden lg:block lg:w-1/2 bg-black bg-cover bg-center bg-no-repeat h-screen" style={divStyle} />
    </div>
  );
};

export default LoginForm;
