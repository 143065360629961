import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  addBrandApi, getBrandApi, 
  addBrandModelApi, getBrandModelApi, 
  addVehicleYearApi, getVehicleYearApi, 
  addStateApi, getStateApi, 
  addCityApi, getCityApi, 
  addFeaturesApi, getFeaturesApi, 
  addTransmissionApi, getTransmissionApi, 
  addSeatApi, getSeatApi, 
  addVehicleTypeApi, getVehicleTypeApi, 
  addInsuranceTypeApi, getInsuranceTypeApi, 
  updateBrandApi, deleteBrandApi,
  updateBrandModelApi, deleteBrandModelApi,
  updateVehicleYearApi, deleteVehicleYearApi,
  updateStateApi, deleteStateApi,
  updateCityApi, deleteCityApi,
  updateFeatureApi, deleteFeatureApi,
  updateTransmissionApi, deleteTransmissionApi,
  updateSeatApi, deleteSeatApi,
  updateVehicleTypeApi, deleteVehicleTypeApi,
  updateInsuranceTypeApi, deleteInsuranceTypeApi,
  getTripsDataApi, addTripsDataApi
} from '../../helpers/api';

import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { showCustomConfirmAlert } from '../../components/CustomConfirmAlert';

// Add Brand
export const addBrand = createAsyncThunk('vehicle/addBrand', async ({name, icon}, {dispatch}) => {
  try {
    const response = await addBrandApi(name, icon);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getBrand());
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Brand
export const getBrand = createAsyncThunk('vehicle/getBrand', async () => {
  try {
    const response = await getBrandApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Update Brand
export const updateBrand = createAsyncThunk(
  'vehicle/updateBrand',
  async ({ brandCode, name, icon }, { dispatch }) => { 
    try {
      const response = await updateBrandApi(brandCode, name, icon); 
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getBrand());
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Delete Brand
export const deleteBrand = createAsyncThunk(
  'vehicle/deleteBrand',
  async (brandCode, { dispatch }) => {
    return new Promise((resolve, reject) => {
      showCustomConfirmAlert(async () => {
        try {
          const response = await deleteBrandApi(brandCode);
          if (response.status_code === 200) {
            toast.success(response.message);
            dispatch(getBrand());
          }
          resolve(brandCode);
        } catch (error) {
          toast.error(error.response.data.message);
          reject(error);
        }
      });
    });
  }
);


// Add Brand Model
export const addBrandModel = createAsyncThunk('vehicle/addBrandModel', async ({ name, brandCode }, { dispatch }) => {
  try {
    const response = await addBrandModelApi(name, brandCode);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getBrandModel({ name, brandCode }));
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Brand Model
export const getBrandModel = createAsyncThunk('vehicle/getBrandModel', async ({ brandCode }) => {
  try {
    const response = await getBrandModelApi(brandCode);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Update Brand Model
export const updateBrandModel = createAsyncThunk(
  'vehicle/updateBrandModel',
  async ({ modelCode, name, brandCode }, { dispatch }) => { 
    try {
      const response = await updateBrandModelApi(modelCode, name, brandCode); 
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getBrandModel({brandCode}));
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Delete Brand Model
export const deleteBrandModel = createAsyncThunk(
  'vehicle/deleteBrandModel',
  async ({ modelCode, brandCode }, { dispatch }) => {
    try {
      const response = await deleteBrandModelApi(modelCode);
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getBrandModel({ brandCode }));
      }
      return modelCode;
    } catch (error) {
      toast.error(error.response.data.message);
      throw error;
    }
  }
);



// Add Vehicle Year
export const addVehicleYear = createAsyncThunk(
  'vehicle/addVehicleYear',
  async ({ year, advicePrice, brandCode, brandModelCode, cautionFee }, { dispatch }) => {
    try {
      const response = await addVehicleYearApi(year, advicePrice, brandCode, brandModelCode, cautionFee);
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getVehicleYear({ brandCode, modelCode: brandModelCode }));
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Get Vehicle Year
export const getVehicleYear = createAsyncThunk('vehicle/getVehicleYear', async ({ brandCode, modelCode }) => {
  try {
    const response = await getVehicleYearApi(brandCode, modelCode);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


// Update Vehicle Year
export const updateVehicleYear = createAsyncThunk(
  'vehicle/updateVehicleYear',
  async ({ year, advicePrice, yearCode, brandModelCode, brandCode, cautionFee }, { dispatch }) => {
    try {
      const response = await updateVehicleYearApi(year, advicePrice, yearCode, brandModelCode, cautionFee);
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getVehicleYear({ brandCode, modelCode: brandModelCode }));
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Delete Vehicle Year
export const deleteVehicleYear = createAsyncThunk(
  'vehicle/deleteVehicleYear',
  async ({ modelCode, brandCode, yearCode }, { dispatch }) => {
    try {
      const response = await deleteVehicleYearApi(yearCode);
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getVehicleYear({brandCode: brandCode, modelCode: modelCode}));
      }
      return modelCode;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Add State
export const addState = createAsyncThunk('vehicle/addState', async (stateData, { dispatch }) => {
  try {
    const response = await addStateApi(stateData);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getState());

    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get State
export const getState = createAsyncThunk('vehicle/getState', async () => {
  try {
    const response = await getStateApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Update State
export const updateState = createAsyncThunk(
  'vehicle/updateState',
  async ({ stateCode, name}, { dispatch }) => {
    try {
      const response = await updateStateApi(stateCode, name);
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getState());
  
      }
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

// Delete State
export const deleteState = createAsyncThunk('vehicle/deleteState', async (stateCode, { dispatch }) => {
  try {
    const response = await deleteStateApi(stateCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getState());
    }
    return stateCode; 
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


// Add City
export const addCity = createAsyncThunk('vehicle/addCity', async ({name, stateCode}, { dispatch }) => {
  try {
    const response = await addCityApi(name, stateCode);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getCity({stateCode: stateCode}));
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get City
export const getCity = createAsyncThunk('vehicle/getCity', async ({ stateCode }) => {
  try {
    const response = await getCityApi(stateCode);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


// Update City
export const updateCity = createAsyncThunk('vehicle/updateCity', async ({ cityCode, name, stateCode }, { dispatch }) => {
  try {
    const response = await updateCityApi(cityCode, name, stateCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getCity({stateCode}));

    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Delete City
export const deleteCity = createAsyncThunk('vehicle/deleteCity', async ({cityCode, stateCode}, { dispatch }) => {
  try {
    console.log("see state code", stateCode)
    const response = await deleteCityApi(cityCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getCity({stateCode}));
    }
    return response; 
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Add Features
export const addFeatures = createAsyncThunk('vehicle/addFeatures', async (featuresData, { dispatch }) => {
  try {
    const response = await addFeaturesApi(featuresData);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getFeatures());
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Features
export const getFeatures = createAsyncThunk('vehicle/getFeatures', async () => {
  try {
    const response = await getFeaturesApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Update Feature
export const updateFeature = createAsyncThunk(
  'vehicle/updateFeature',
  async ({ featuresCode, name }, { dispatch }) => {
    try {
      const response = await updateFeatureApi({ featuresCode, name });
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getFeatures());

      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


// Delete Feature
export const deleteFeature = createAsyncThunk('vehicle/deleteFeature', async (featuresCode, { dispatch }) => {
  try {
    const response = await deleteFeatureApi(featuresCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getFeatures());
    }
    return response; 
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Add Transmission
export const addTransmission = createAsyncThunk('vehicle/addTransmission', async (transmissionData, { dispatch }) => {
  try {
    const response = await addTransmissionApi(transmissionData);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getTransmission());


    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Transmission
export const getTransmission = createAsyncThunk('vehicle/getTransmission', async () => {
  try {
    const response = await getTransmissionApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


// Update Transmission
export const updateTransmission = createAsyncThunk(
  'vehicle/updateTransmission',
  async ({ transmissionCode, name }, { dispatch }) => {
    try {
      const response = await updateTransmissionApi({ transmissionCode, name });
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getTransmission());

      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Delete Transmission
export const deleteTransmission = createAsyncThunk('vehicle/deleteTransmission', async (transmissionCode, { dispatch }) => {
  try {
    const response = await deleteTransmissionApi(transmissionCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getTransmission());
    }
    return transmissionCode; 
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


// Add Seat
export const addSeat = createAsyncThunk('vehicle/addSeat', async (seatData, { dispatch}) => {
  try {
    const response = await addSeatApi(seatData);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getSeat());
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Seat
export const getSeat = createAsyncThunk('vehicle/getSeat', async () => {
  try {
    const response = await getSeatApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


// Update Seat
export const updateSeat = createAsyncThunk(
  'vehicle/updateSeat',
  async ({ seatCode, name }, { dispatch }) => {
    try {
      const response = await updateSeatApi({ seatCode, name });
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getSeat());

      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Delete Seat
export const deleteSeat = createAsyncThunk('vehicle/deleteSeat', async (seatCode, { dispatch }) => {
  try {
    const response = await deleteSeatApi(seatCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getSeat());
    }
    return seatCode; 
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Add Vehicle Type
export const addVehicleType = createAsyncThunk('vehicle/addVehicleType', async (vehicleTypeData, { dispatch }) => {
  try {
    const response = await addVehicleTypeApi(vehicleTypeData);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getVehicleType());
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Vehicle Type
export const getVehicleType = createAsyncThunk('vehicle/getVehicleType', async () => {
  try {
    const response = await getVehicleTypeApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Update Vehicle Type
export const updateVehicleType = createAsyncThunk(
  'vehicle/updateVehicleType',
  async ({ typeCode, name }, { dispatch }) => {
    try {
      const response = await updateVehicleTypeApi({ typeCode, name });
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getVehicleType());

      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Delete Vehicle Type
export const deleteVehicleType = createAsyncThunk('vehicle/deleteVehicleType', async (typeCode, { dispatch }) => {
  try {
    const response = await deleteVehicleTypeApi(typeCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getVehicleType());
    }
    return typeCode; 
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Add Insurance Type
export const addInsuranceType = createAsyncThunk('vehicle/addInsuranceType', async (insuranceTypeData, { dispatch }) => {
  try {
    const response = await addInsuranceTypeApi(insuranceTypeData);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getInsuranceType());
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Insurance Type
export const getInsuranceType = createAsyncThunk('vehicle/getInsuranceType', async () => {
  try {
    const response = await getInsuranceTypeApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});


// Update Insurance Type
export const updateInsuranceType = createAsyncThunk(
  'vehicle/updateInsuranceType',
  async ({ insuranceCode, name }, { dispatch }) => {
    try {
      const response = await updateInsuranceTypeApi({ insuranceCode, name });
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(getInsuranceType());
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
// Delete Insurance Type
export const deleteInsuranceType = createAsyncThunk('vehicle/deleteInsuranceType', async (insuranceCode, { dispatch }) => {
  try {
    const response = await deleteInsuranceTypeApi(insuranceCode); 
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getInsuranceType());
    }
    return insuranceCode; 
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Trips Data
export const addTripsData = createAsyncThunk('vehicle/addTripsData', async (tripsData, { dispatch }) => {
  try {
    const response = await addTripsDataApi(tripsData);
    if (response.status_code === 200) {
      toast.success(response.message);
      dispatch(getTripsData());
    }
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Get Insurance Type
export const getTripsData = createAsyncThunk('vehicle/getTripsData', async () => {
  try {
    const response = await getTripsDataApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});



const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState: {
      brands: [],
      brandModels: [],
      brandName: [],
      vehicleYears: [],
      states: [],
      cities: [],
      features: [],
      transmissions: [],
      seats: [],
      vehicleTypes: [],
      insuranceTypes: [],
      tripsData: [],
      status: 'idle',
      error: null,
      isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
          // Add Brand
          .addCase(addBrand.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addBrand.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.brands.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addBrand.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Brand
          .addCase(getBrand.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getBrand.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.brands = action.payload;
            state.isLoading = false;
          })
          .addCase(getBrand.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add Brand Model
          .addCase(addBrandModel.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addBrandModel.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.brandModels.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addBrandModel.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Brand Model
          .addCase(getBrandModel.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getBrandModel.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.brandModels = action.payload;
            state.isLoading = false;
          })
          .addCase(getBrandModel.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          .addCase(addVehicleYear.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addVehicleYear.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.vehicleYears.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addVehicleYear.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Vehicle Year
          .addCase(getVehicleYear.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getVehicleYear.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.vehicleYears = action.payload;
            state.isLoading = false;
          })
          .addCase(getVehicleYear.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          .addCase(addState.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addState.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.states.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addState.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get State
          .addCase(getState.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getState.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.states = action.payload;
            state.isLoading = false;
          })
          .addCase(getState.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add City
          .addCase(addCity.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addCity.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.cities.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addCity.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get City
          .addCase(getCity.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getCity.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.cities = action.payload;
            state.isLoading = false;
          })
          .addCase(getCity.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add Features
          .addCase(addFeatures.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addFeatures.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.features.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addFeatures.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Features
          .addCase(getFeatures.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getFeatures.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.features = action.payload;
            state.isLoading = false;
          })
          .addCase(getFeatures.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add Transmission
          .addCase(addTransmission.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addTransmission.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.transmissions.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addTransmission.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Transmission
          .addCase(getTransmission.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getTransmission.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.transmissions = action.payload;
            state.isLoading = false;
          })
          .addCase(getTransmission.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add Seat
          .addCase(addSeat.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addSeat.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.seats.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addSeat.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Seat
          .addCase(getSeat.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getSeat.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.seats = action.payload;
            state.isLoading = false;
          })
          .addCase(getSeat.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add Vehicle Type
          .addCase(addVehicleType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addVehicleType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.vehicleTypes.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addVehicleType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Vehicle Type
          .addCase(getVehicleType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getVehicleType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.vehicleTypes = action.payload;
            state.isLoading = false;
          })
          .addCase(getVehicleType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add Insurance Type
          .addCase(addInsuranceType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addInsuranceType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.insuranceTypes.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addInsuranceType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Insurance Type
          .addCase(getInsuranceType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getInsuranceType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.insuranceTypes = action.payload;
            state.isLoading = false;
          })
          .addCase(getInsuranceType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          .addCase(updateBrandModel.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateBrandModel.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the brand model in the state
            const updatedBrandModelIndex = state.brandModels.findIndex((model) => model?.id === action.payload?.id);
            if (updatedBrandModelIndex !== -1) {
              state.brandModels[updatedBrandModelIndex] = action.payload;
            }
          })
          .addCase(updateBrandModel.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Delete Brand Model
          .addCase(deleteBrandModel.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteBrandModel.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted brand model from the state
            state.brandModels = state.brandModels.filter((model) => model?.id !== action.payload);
          })
          .addCase(deleteBrandModel.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Update Vehicle Year
          .addCase(updateVehicleYear.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateVehicleYear.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the vehicle year in the state
            const updatedVehicleYearIndex = state.vehicleYears.findIndex((year) => year?.id === action.payload?.id);
            if (updatedVehicleYearIndex !== -1) {
              state.vehicleYears[updatedVehicleYearIndex] = action.payload;
            }
          })
          .addCase(updateVehicleYear.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Delete Vehicle Year
          .addCase(deleteVehicleYear.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteVehicleYear.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted vehicle year from the state
            state.vehicleYears = state.vehicleYears.filter((year) => year?.id !== action.payload);
          })
          .addCase(deleteVehicleYear.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          .addCase(updateState.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateState.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the state in the state
            const updatedStateIndex = state.states.findIndex((state) => state?.id === action.payload?.id);
            if (updatedStateIndex !== -1) {
              state.states[updatedStateIndex] = action.payload;
            }
          })
          .addCase(updateState.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Delete State
          .addCase(deleteState.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteState.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted state from the state
            state.states = state.states.filter((state) => state?.id !== action.payload);
          })
          .addCase(deleteState.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          .addCase(updateCity.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateCity.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the city in the state
            const updatedCityIndex = state.cities.findIndex((city) => city?.id === action.payload?.id);
            if (updatedCityIndex !== -1) {
              state.cities[updatedCityIndex] = action.payload;
            }
          })
          .addCase(updateCity.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Delete City
          .addCase(deleteCity.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteCity.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted city from the state
            state.cities = state.cities.filter((city) => city?.id !== action.payload);
          })
          .addCase(deleteCity.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Update Feature
          .addCase(updateFeature.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateFeature.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the feature in the state
            const updatedFeatureIndex = state.features.findIndex((feature) => feature?.id === action.payload?.id);
            if (updatedFeatureIndex !== -1) {
              state.features[updatedFeatureIndex] = action.payload;
            }
          })
          .addCase(updateFeature.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Delete Feature
          .addCase(deleteFeature.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteFeature.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted feature from the state
            state.features = state.features.filter((feature) => feature?.id !== action.payload);
          })
          .addCase(deleteFeature.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Update Transmission
          .addCase(updateTransmission.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateTransmission.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the transmission in the state
            const updatedTransmissionIndex = state.transmissions.findIndex((transmission) => transmission?.id === action.payload?.id);
            if (updatedTransmissionIndex !== -1) {
              state.transmissions[updatedTransmissionIndex] = action.payload;
            }
          })
          .addCase(updateTransmission.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Delete Transmission
          .addCase(deleteTransmission.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteTransmission.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted transmission from the state
            state.transmissions = state.transmissions.filter((transmission) => transmission?.id !== action.payload);
          })
          .addCase(deleteTransmission.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Update Seat
          .addCase(updateSeat.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateSeat.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the seat in the state
            const updatedSeatIndex = state.seats.findIndex((seat) => seat?.id === action.payload?.id);
            if (updatedSeatIndex !== -1) {
              state.seats[updatedSeatIndex] = action.payload;
            }
          })
          .addCase(updateSeat.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Delete Seat
          .addCase(deleteSeat.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteSeat.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted seat from the state
            state.seats = state.seats.filter((seat) => seat?.id !== action.payload);
          })
          .addCase(deleteSeat.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Update Vehicle Type
          .addCase(updateVehicleType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateVehicleType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the vehicle type in the state
            const updatedVehicleTypeIndex = state.vehicleTypes.findIndex((vehicleType) => vehicleType?.id === action.payload?.id);
            if (updatedVehicleTypeIndex !== -1) {
              state.vehicleTypes[updatedVehicleTypeIndex] = action.payload;
            }
          })
          .addCase(updateVehicleType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Delete Vehicle Type
          .addCase(deleteVehicleType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteVehicleType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted vehicle type from the state
            state.vehicleTypes = state.vehicleTypes.filter((vehicleType) => vehicleType?.id !== action.payload);
          })
          .addCase(deleteVehicleType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Update Insurance Type
          .addCase(updateInsuranceType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(updateInsuranceType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Update the insurance type in the state
            const updatedInsuranceTypeIndex = state.insuranceTypes.findIndex((insuranceType) => insuranceType?.id === action.payload?.id);
            if (updatedInsuranceTypeIndex !== -1) {
              state.insuranceTypes[updatedInsuranceTypeIndex] = action.payload;
            }
          })
          .addCase(updateInsuranceType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })

          // Delete Insurance Type
          .addCase(deleteInsuranceType.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(deleteInsuranceType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            // Remove the deleted insurance type from the state
            state.insuranceTypes = state.insuranceTypes.filter((insuranceType) => insuranceType?.id !== action.payload);
          })
          .addCase(deleteInsuranceType.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Add Trips Data
          .addCase(addTripsData.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(addTripsData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.tripsData.push(action.payload);
            state.isLoading = false;
          })
          .addCase(addTripsData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          // Get Trips Data
          .addCase(getTripsData.pending, (state) => {
            state.status = 'loading';
            state.isLoading = true;
          })
          .addCase(getTripsData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.tripsData = action.payload;
            state.isLoading = false;
          })
          .addCase(getTripsData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.isLoading = false;
          })
          .addMatcher(
            (action) => {
              const rejectedActions = [deleteBrandModel.rejected, deleteState.rejected, deleteCity.rejected];
              return rejectedActions.some((rejectedAction) => action.type === rejectedAction.type) && action.meta.aborted;
            },
            (state) => {
              state.status = 'idle';
              state.isLoading = false;
            }
          );
          


    },      
});
  
  

export default vehicleSlice.reducer;
