import React from 'react'
import ResetForm from '../../containers/Auth/ResetForm'

const ResetPassword = () => {
  return (
    <div>
        <ResetForm/>
    </div>
  )
}

export default ResetPassword