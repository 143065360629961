import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllAdminApi, getAdminProfileApi, updateAdminStatusApi, updateAdminProfileApi, createAdminApi } from '../../helpers/api';
import { toast } from 'react-toastify';

export const createAdmin = createAsyncThunk(
  'admin/createAdmin',
  async ({ fullName, email, password, role, phoneNumber, navigate }, { dispatch }) => {
    try {
      const response = await createAdminApi(fullName, email, password, role, phoneNumber); 
      if (response.data.status_code === 200) {
        toast.success(response.data.message);

        
        dispatch(fetchAllAdmin());

        
        const newAdminID = response.data.adminID;

        
        await dispatch(updateAdminStatus({ userID: newAdminID, status: true }));

        navigate('/admin/management');
      }
      return response.data;
    } catch (error) {
      toast.error(error.message)
    }
  }
);


export const fetchAllAdmin = createAsyncThunk('admin/fetchAllAdmin', async () => {
  try {
    const response = await getAllAdminApi();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchAdminProfile = createAsyncThunk('admin/fetchAdminProfile', async ({ userID }) => {
  try {
    console.log("slice userID", userID)
    const response = await getAdminProfileApi(userID);
    const adminData = response.data[0]
    return adminData;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const updateAdminStatus = createAsyncThunk('admin/updateAdminStatus', async ({ userID, status }, { dispatch }) => {
  try {
    const response = await updateAdminStatusApi(userID, status);
    if (response.status_code === 200) {
      toast.success(response.message);

      dispatch(fetchAllAdmin());
    }
    return response.data;

  } catch (error) {
    throw new Error(error.response.data.message);
  }
});



export const updateAdminProfile = createAsyncThunk(
  'admin/updateAdminProfile',
  async ({ userID, fullName, emailAddress, phoneNumber, profilePic }, { dispatch }) => {
    try {
      const response = await updateAdminProfileApi(userID, fullName, emailAddress, phoneNumber, profilePic);
      if (response.status_code === 200) {
        toast.success(response.message);
        dispatch(fetchAdminProfile({ userID }));
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    allAdmin: [],
    adminData: {},
    adminProfile: {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createAdmin.pending, (state) => {
      state.status = 'loading';
      state.isLoading = true;
    })
    .addCase(createAdmin.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.isLoading = false;
      state.adminData = action.payload;
    })
    .addCase(createAdmin.rejected, (state, action) => {
      state.status = 'failed';
      state.isLoading = false;
      state.error = action.error.message;
    })
      .addCase(fetchAllAdmin.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchAllAdmin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allAdmin = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllAdmin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchAdminProfile.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchAdminProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.adminProfile = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAdminProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateAdminStatus.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(updateAdminStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(updateAdminStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateAdminProfile.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(updateAdminProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(updateAdminProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});

export default adminSlice.reducer;
