import React from 'react';

const DateFormatter = ({ date, time }) => {
  const formattedDate = new Date(date).toLocaleDateString('en-UK', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  });

  return (
    <p className='text-[14px] text-grey-7 font-semibold'>
      {formattedDate}, <br/><span className='font-medium'>{time}</span>
    </p>
  );
};

export default DateFormatter;
