import React, { useState } from 'react';
import { useDispatch } from 'react-redux'; 
import useImageLoader from '../../../components/useImageLoader';
import { wheel } from '../../../assets';
import { Link } from 'react-router-dom';
import DateTime from './DateTime';
import { addPaymentList } from '../store/slices/paymentSlice';
import TripsDataForm from './MasterData/TripsDataForm';
import UpdateModal from './MasterData/UpdateModal';
import UpdatePaymentForm from './MasterData/UpdatePaymentForm';

const PaymentTable = ({ headers, data, selectedTab, setSelectedTab }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPaymentData, setSelectedPaymentData] = useState("");


    const openModal = (paymentID) => {
      setSelectedPaymentData(paymentID);
      setIsModalOpen(true);
  };
  
  const closeModal = () => {
      setSelectedPaymentData(null);
      setIsModalOpen(false);
  };

  

    return (
        <div className=''>
            <table className="w-full table-auto text-grey-7">
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header} className="text-left px-8 py-4 uppercase text-[12px] whitespace-nowrap text-gray-500">
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index} className={`${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'} hover:cursor-pointer hover:bg-comp-7`}>
                            {headers.map((header) => (
                                <td key={header} className="py-2 px-8 whitespace-nowrap">
                                    {header === 'Car Brand' ? (
                                        <div className="flex items-center gap-2">
                                            <div className='w-6 h-6 flex justify-center items-center bg-black text-white rounded-sm'>
                                                <img
                                                    src={wheel}
                                                    alt="Wheel"
                                                    onLoad={handleImageLoad}
                                                    style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                                                />
                                            </div>
                                            {row['Car Brand']}
                                        </div>
                                    ) : header === 'Duration' ? (
                                        <DateTime
                                            dateTimeFrom={row['Duration'].startDate}
                                            dateTimeTo={row['Duration'].endDate}
                                        />
                                    ) : header === 'Car Owner' ? (
                                        <div className="flex items-center gap-2">
                                            <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                                                <img
                                                    src={row['Car Owner'].profilePic}
                                                    className='w-4 h-4 rounded-full'
                                                    alt="Customer Pic"
                                                    onLoad={handleImageLoad}
                                                    style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                                                />
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                {row['Car Owner'].fullName}
                                                <Link to={`/admin/user-management/${row['Car Owner'].userID}`}><button className='text-success underline text-[14px]'>See Profile</button></Link>
                                            </div>
                                        </div>
                                    ) : header === 'Car Model' ? (
                                        <div className="flex flex-col gap-3">
                                            <p className='text-custom-secondary text-[16px]'> {row['Car Model']}</p>
                                            <p className='text-custom-secondary text-xs capitalize '> {row['Plate Number']}</p>
                                        </div>
                                    ) : header === 'Payment Details' ? (
                                        <div className="flex flex-col gap-3">
                                            {row['Payment Details'].map((detail, index) => (
                                            <div key={index} className='flex flex-col items-end gap-2'>
                                                <p className='text-custom-secondary text-[16px]'>{detail.bankName}</p>
                                                <p className='text-custom-secondary text-[16px]'>{detail.accountNumber}</p>
                                            </div>
                                            ))}
                                        </div>
                                        
                                    ) : header === 'Total Cost to be Paid' ? (
                                        <div className="flex flex-col items-end gap-3">
                                            <p className='text-custom-secondary text-[16px]'> {row['Total Cost to be Paid']}</p>
                                        </div>
                                    ) : header === 'More Actions' ? (
                                        <div className="flex flex-col items-end gap-3">
                                            <p className='text-custom-secondary text-[16px]'>I have made Payment to <span className='font-semibold'></span></p>
                                            {selectedTab === 'pending' ? (
                                                <button className={`py-1.5 px-4 bg-brand text-white rounded-[4px]`} onClick={() => openModal(row['More Actions'].paymentID)}>
                                                    Yes, Payment Paid
                                                </button>
                                            ) : (
                                                <button className={`py-1.5 px-4 border border-brand text-custom-secondary rounded-[4px]`}>
                                                    Payment Successful
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        row[header]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <UpdateModal isOpen={isModalOpen} onClose={closeModal}>
                <UpdatePaymentForm selectedPaymentData={selectedPaymentData} setSelectedTab={setSelectedTab} onClose={closeModal}/>
            </UpdateModal>
        </div>
    );
};

export default PaymentTable;
