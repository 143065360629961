import React from 'react'
import CityLists from '../../containers/MasterData/CityLists'

const Cities = () => {
  return (
    <CityLists/>
    
  )
}

export default Cities