import React from 'react';
import { Link } from 'react-router-dom';

const ProfileInfo = ({ userProfile }) => {
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    };

    if (!userProfile) {
        return null;
    }

    const [userProfileObj = {}] = userProfile;
    const { emailAddress, fullName, phoneNumber, gender, homeAddress, dateOfBirth, occupation } = userProfileObj;

    return (
        <div className='border-[1px] rounded-[4px] px-4 py-4 flex flex-col gap-3 h-full'>
            <h4 className='text-[18px] text-grey-7 font-semibold'>PROFILE INFORMATION</h4>
            <div className='flex flex-col md:justify-between'>
                {/* <p className='text-brand p-2 rounded-sm whitespace-nowrap bg-[#fef3d8] font-bold shadow-sm border border-primary'>
                Car uploaded: 23 | Total Transaction: 40
                </p> */}
                <Link to="trips" className='text-brand whitespace-nowrap'>See all trips </Link>
            </div>
            {fullName && (
                <p className='text-grey-7 font-bold'>
                    Full Name:   
                    <span className='font-normal ml-2 capitalize'>{fullName}</span>
                </p>
            )}
            {phoneNumber && (
                <p className='text-grey-7 font-bold'>
                    Mobile:   
                    <span className='font-normal ml-2'>{phoneNumber}</span>
                </p>
            )}
            {emailAddress && (
                <p className='text-grey-7 font-bold'>
                    Email:   
                    <span className='font-normal ml-2'>{emailAddress}</span>
                </p>
            )}
            {gender && (
                <p className='text-grey-7 font-bold'>
                    Gender:   
                    <span className='font-normal ml-2'>{gender}</span>
                </p>
            )}
            {homeAddress && (
                <p className='text-grey-7 font-bold'>
                    Location:   
                    <span className='font-normal ml-2'>{homeAddress}</span>
                </p>
            )}
            {dateOfBirth && (
                <p className='text-grey-7 font-bold'>
                    DOB:   
                    <span className='font-normal ml-2'>{formatDateTime(dateOfBirth)}</span>
                </p>
            )}
            {occupation && (
                <p className='text-grey-7 font-bold'>
                    Occupation:   
                    <span className='font-normal ml-2'>{occupation}</span>
                </p>
            )}
        </div>
    );
};

export default ProfileInfo;
