import React from 'react'
import YearLists from '../../containers/MasterData/YearLists'

const Years = () => {
  return (
    <YearLists/>
    
  )
}

export default Years