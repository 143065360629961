import axios from 'axios';
import axiosInstance, { baseURL } from './axiosInstance';


// AUTH STARTS HERE
export const createAdminApi = async (fullName, email, password, role, phoneNumber) => {
  try {
    
    const response = await axios.post(`${baseURL}/auth/register`, {
      fullName: fullName,
      emailAddress: email,
      password: password,
      role: role,
      phoneNumber: phoneNumber
    }, {
      headers: {
          'Content-Type': 'application/json'
      }
    });
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const authenticateUserApi = async (email, password) => {
  try {

    const response = await axios.post(`${baseURL}/auth/login`, {
      user: email,
      password: password
    }, {
      headers: {
          'Content-Type': 'application/json'
      }
    });
    
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


export const verifyOtpApi = async (email, otp) => {
    try {
  
      const response = await axios.post(`${baseURL}/auth/verifyOTP`, {
        user: email,
        otp: otp
      }, {
        headers: {
            'Content-Type': 'application/json'
        }
      });

      console.log("find response here: ",response)
      
      return response;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else if (error.request) {
        throw new Error('No response received. Please check your internet connection.');
      } else {
        throw new Error('An error occurred while sending the request.');
      }
    }
  };

  export const resendOtpApi = async (email) => {
    try {
  
      const response = await axios.post(`${baseURL}/auth/resendOTP`, {
        user: email,
      }, {
        headers: {
            'Content-Type': 'application/json'
        }
      });
      
      return response;
    } catch (error) {
      if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
    }
  };

  export const forgotPasswordApi = async (email) => {
    try {
  
      const response = await axios.post(`${baseURL}/auth/resetPassword`, {
        user: email,
      }, {
        headers: {
            'Content-Type': 'application/json'
        }
      });
      
      return response;
    } catch (error) {
      if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
    }
  };


  export const resetPasswordApi = async (password) => {
    try {
  
      const response = await axios.post(`${baseURL}/auth/resetPassword`, {
        password: password,
      }, {
        headers: {
            'Content-Type': 'application/json'
        }
      });
      
      return response;
    } catch (error) {
      if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
    }
  };

  export const changePasswordApi = async (password) => {
    try {
  
      const response = await axiosInstance.post(`${baseURL}/auth/addNewPassword`, {
        password: password,
      }, {
        headers: {
            'Content-Type': 'application/json'
        }
      });
      
      return response;
    } catch (error) {
      if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
    }
  };


//USER CALLS START HERE
export const getAllUsers = async (queryParams) => {
  try {
    console.log("i got here")
    const response = await axiosInstance.get(`${baseURL}/user/getUsers`, {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getUserProfile = async (userID) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/user/getUserProfile`, {
      params: { userID },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getUserReview = async (userID, queryParams) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/user/getUserReview`, {
      params: { userID, ...queryParams },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getUserKYC = async (userID) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/user/getUserKYC`, {
      params: { userID },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getUserPaymentBank = async (userID) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/user/getUserPaymentBank`, {
      params: { userID },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getUserCars = async (userID, queryParams) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/user/getUserCars`, {
      params: { userID, ...queryParams },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getUserCarReview = async (userID, queryParams) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/user/getUserCarReview`, {
      params: { userID, ...queryParams },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getUserDrivers = async (userID, queryParams) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/user/getUserDrivers`, {
      params: { userID, ...queryParams },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

//USER CALLS END HERE


//MANAGE CAR CALLS START HERE
export const getAllCarsApi = async ({queryParams}) => {
  try {
    const response = await axiosInstance.get(`/car/getAllCars`, {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getOneCarApi = async (carID) => {
  try {
    const response = await axiosInstance.get(`/car/getOneCar`, {
      params: { carID },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const updateCarStatusApi = async (carID, status) => {
  try {
    const response = await axiosInstance.put(`/car/updateCarStatus`, {status}, {
      params: {
        carID: carID
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getOneCarReviewApi = async (carID) => {
  try {
    const response = await axiosInstance.get(`/car/getOneCarReview`, {
      params: { carID },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getOneCarTripsApi = async (carID) => {
  try {
    const response = await axiosInstance.get(`/car/getOneCarTrips`, {
      params: { carID },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const countAllCarsApi = async () => {
  try {
    const response = await axiosInstance.get('/car/countCars', {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};



// VEHICLE DATA API
// Add Brand API
export const addBrandApi = async (name, icon) => {
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('icon', icon);

    const response = await axiosInstance.post('/vehicle/addBrand', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Brand API
export const getBrandApi = async () => {
  try {
    const response = await axiosInstance.get('/vehicle/getBrand');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};



// Update Brand API
export const updateBrandApi = async (brandCode, name, icon) => {
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('icon', icon);

    const response = await axiosInstance.post(`/vehicle/editBrand?brandCode=${brandCode}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Brand API
export const deleteBrandApi = async (brandCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteBrand`, {
      params: { brandCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


// Add Brand Model API
export const addBrandModelApi = async (name, brandCode) => {
  try {
    const response = await axiosInstance.post('/vehicle/addBrandModel', {
      name: name,
      brandCode: brandCode
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Brand Model API
export const getBrandModelApi = async (brandCode) => {
  try {
    const response = await axiosInstance.get(`/vehicle/getBrandModel?brandCode=${brandCode}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


export const updateBrandModelApi = async ( modelCode, name, brandCode ) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editBrandModel?modelCode=${modelCode}`, {
      name: name, 
      brandCode: brandCode
     });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Brand Model API
export const deleteBrandModelApi = async (modelCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteBrandModel`, {
      params: { modelCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};



// Add Vehicle Year API
export const addVehicleYearApi = async (year, advicePrice, brandCode, brandModelCode, cautionFee) => {
  try {
    const response = await axiosInstance.post('/vehicle/addVehicleYear', {
      year: year,
      advicePrice: advicePrice,
      brandCode: brandCode,
      brandModelCode: brandModelCode,
      cautionFee: cautionFee
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Vehicle Year API
export const getVehicleYearApi = async (brandCode, modelCode) => {
  try {
    const response = await axiosInstance.get(`/vehicle/getVehicleYear?brandCode=${brandCode}&brandModelCode=${modelCode}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


// Update Vehicle Year API
export const updateVehicleYearApi = async (year, advicePrice, yearCode, brandModelCode, cautionFee) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editVehicleYear?yearCode=${yearCode}`, {
      year: year,
      brandModelCode: brandModelCode,
      advicePrice: advicePrice,
      cautionFee: cautionFee
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Vehicle Year API
export const deleteVehicleYearApi = async (yearCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteVehicleYear`, {
      params: { yearCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


// Add State API
export const addStateApi = async (stateData) => {
  try {
    const response = await axiosInstance.post('/vehicle/addState', {...stateData});
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get State API
export const getStateApi = async () => {
  try {
    const response = await axiosInstance.get('/vehicle/getState');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Update State API
export const updateStateApi = async (stateCode, name) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editState?stateCode=${stateCode}`, { name });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete State API
export const deleteStateApi = async (stateCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteState`, {
      params: { stateCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};



// Add City API
export const addCityApi = async (name, stateCode) => {
  try {
    const response = await axiosInstance.post('/vehicle/addCity', {
      name: name,
      stateCode: stateCode
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get City API
export const getCityApi = async (stateCode) => {
  try {
    const response = await axiosInstance.get(`/vehicle/getCity?stateCode=${stateCode}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


// Update City API
export const updateCityApi = async (cityCode, name, stateCode) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editCity?cityCode=${cityCode}`, {
      name: name,
      stateCode: stateCode
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete City API
export const deleteCityApi = async (cityCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteCity`, {
      params: { cityCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Add Features API
export const addFeaturesApi = async (featuresData) => {
  try {
    const response = await axiosInstance.post('/vehicle/addFeatures', {...featuresData});
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Features API
export const getFeaturesApi = async () => {
  try {
    const response = await axiosInstance.get('/vehicle/getFeatures');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


// Update Feature API
export const updateFeatureApi = async ({ featuresCode, name }) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editFeatures?featuresCode=${featuresCode}`, { name });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Feature API
export const deleteFeatureApi = async (featuresCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteFeatures`, {
      params: { featuresCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


// Add Transmission API
export const addTransmissionApi = async (transmissionData) => {
  try {
    const response = await axiosInstance.post('/vehicle/addTransmission', {...transmissionData});
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Transmission API
export const getTransmissionApi = async () => {
  try {
    const response = await axiosInstance.get('/vehicle/getTransmission');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Update Transmission API
export const updateTransmissionApi = async ({ transmissionCode, name }) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editTransmission?transmissionCode=${transmissionCode}`, { name });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Transmission API
export const deleteTransmissionApi = async (transmissionCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteTransmission`, {
      params: { transmissionCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
}

// Add Seat API
export const addSeatApi = async (seatData) => {
  try {
    const response = await axiosInstance.post('/vehicle/addSeat', seatData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Seat API
export const getSeatApi = async () => {
  try {
    const response = await axiosInstance.get('/vehicle/getSeat');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Update Seat API
export const updateSeatApi = async ({ seatCode, name }) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editSeat?seatCode=${seatCode}`, { name });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Seat API
export const deleteSeatApi = async (seatCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteSeat`, {
      params: { seatCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Add Vehicle Type API
export const addVehicleTypeApi = async (vehicleTypeData) => {
  try {
    const response = await axiosInstance.post('/vehicle/addVehicleType', vehicleTypeData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Vehicle Type API
export const getVehicleTypeApi = async () => {
  try {
    const response = await axiosInstance.get('/vehicle/getVehicleType');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Update Vehicle Type API
export const updateVehicleTypeApi = async ({ typeCode, name }) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editVehicleType?typeCode=${typeCode}`, { name });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Vehicle Type API
export const deleteVehicleTypeApi = async (typeCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteVehicleType`, {
      params: { typeCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Add Insurance Type API
export const addTripsDataApi = async (tripsData) => {
  try {
    const response = await axiosInstance.post('/trips/addTripsData', tripsData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Insurance Type API
export const getTripsDataApi = async () => {
  try {
    const response = await axiosInstance.get('/trips/getTripsData');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Update Insurance Type API
export const updateInsuranceTypeApi = async ({ insuranceCode, name }) => {
  try {
    const response = await axiosInstance.post(`/vehicle/editInsuranceType?insuranceCode=${insuranceCode}`, { name });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Delete Insurance Type API
export const deleteInsuranceTypeApi = async (insuranceCode) => {
  try {
    const response = await axiosInstance.delete(`/vehicle/deleteInsuranceType`, {
      params: { insuranceCode },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Add Trips Data API
export const addInsuranceTypeApi = async (insuranceTypeData) => {
  try {
    const response = await axiosInstance.post('/vehicle/addInsuranceType', insuranceTypeData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// Get Trips Data API
export const getInsuranceTypeApi = async () => {
  try {
    const response = await axiosInstance.get('/vehicle/getInsuranceType');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


//VEHICLE DATA API END


// ADMIN MANAGEMENT API STARTS HERE
export const getAllAdminApi = async () => {
  try {
    const response = await axiosInstance.get(`/auth/getAllAdmin`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const getAdminProfileApi = async (userID) => {
  try {
    const response = await axiosInstance.get(`/auth/getProfile`, {
      params: { userID },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const updateAdminStatusApi = async (userID, status) => {
  try {
    const response = await axiosInstance.put(`/auth/updateAdminStatus`, { status }, {
      params: {
        userID: userID
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};



export const updateAdminProfileApi = async (userID, fullName, emailAddress, phoneNumber, profilePic) => {
  try {
    let response;
    if (profilePic) {
      const formData = new FormData();
      formData.append('profilePic', profilePic);

      response = await axiosInstance.put('/auth/updateAdminProfile', formData, {
        params: {
          userID: userID
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Send JSON data for other fields
      await axiosInstance.put('/auth/updateAdminProfile', {
        fullName,
        emailAddress,
        phoneNumber,
      }, {
        params: {
          userID: userID
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      response = await axiosInstance.put('/auth/updateAdminProfile', {
        fullName,
        emailAddress,
        phoneNumber,
      }, {
        params: {
          userID: userID
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};



// MANAGEMENT API ENDS HERE


//TRIPS SLICE API STARTS HERE
export const getAllTripsApi = async ({ queryParams }) => {
  try {
    const response = await axiosInstance.get('/trip/getAllTrips', {
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


export const approveOrDeclineTripApi = async ({ tripID, status, tripOrderID }) => {
  try {
    const response = await axiosInstance.put('/trip/updateTripStatus', {
      tripID,
      status,
      tripOrderID
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

export const countAllTripsApi = async () => {
  try {
    const response = await axiosInstance.get('/car/countAllTrips', {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};


// PAYMENT API CALL STARTS HERE
export const getPaymentListApi = async ({queryParams}) => {
  try {
    const response = await axiosInstance.get('/trips/getPaymentList', {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const countPaymentListApi = async () => {
  try {
    const response = await axiosInstance.get('/trips/countPaymentList', {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const addPaymentListApi = async ({ paymentID, paymentReference, paymentDate, paymentStatus }) => {
  try {
    const response = await axiosInstance.post('/trips/addPaymentList', {
      paymentID,
      paymentReference,
      paymentDate,
      paymentStatus
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};


const handleError = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message);
  } else if (error.request) {
    throw new Error('No response received. Please check your internet connection.');
  } else {
    throw new Error('An error occurred while sending the request.');
  }
};

//PAYMENT API CALLS ENDS HERE


// DASHBOARD COUNTERS API

export const getDashboardCountersApi = async () => {
  try {
    const response = await axiosInstance.get(`/dashbaord/dashboardCounter`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error('No response received. Please check your internet connection.');
    } else {
      throw new Error('An error occurred while sending the request.');
    }
  }
};

// DASHBOARD COUNTERS API CLLS ENDS HERE







