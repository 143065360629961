import React from 'react';
import useImageLoader from '../components/useImageLoader';
import { customerPic } from '../../assets';

const ListedDrivers = ({userDrivers}) => {
  const { imageLoaded, handleImageLoad } = useImageLoader();

  if (!userDrivers) {
      return null;
  }

  

  return (
    <div className='w-full relative pb-10'>
      <h4 className='text-grey-7 font-semibold mb-4 text-[18px]'>Listed Drivers({userDrivers.length})</h4>
        <div className='flex flex-col gap-4'>
            {userDrivers.map((driver, index) => (
                <div key={index} className="flex items-center gap-3">
                <div className='w-8 h-8 flex justify-center items-center text-white rounded-full'>
                    <img
                    src={customerPic}
                    className='w-8 h-8 rounded-full'
                    alt="Driver Pic"
                    onLoad={handleImageLoad}
                    style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                    />
                </div>
                <div className='flex flex-col text-[14px] '>
                    <p className='font-semibold text-grey-6'>{driver.fullName}</p>
                    <p className='text-custom-secondary'>{driver.driverNumber} | {driver.driverEmail}</p>
                </div>
                </div>
            ))}
        </div>
    
    </div>
  );
};

export default ListedDrivers;
