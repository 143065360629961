import React from 'react'
import OtpForm from '../../containers/Auth/OtpForm'

const Otp = () => {
  return (
    <div className='w-full flex flex-col gap-12 px-6'>
      <div className=''>
          <h4 className='font-semibold text-grey-4 text-[16px] py-4'>Profile / <span className='text-grey-7'> Get Otp</span></h4>
          <OtpForm />
      </div>
    </div>
  )
}

export default Otp