import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDispatch, useSelector } from 'react-redux';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { addPaymentList } from '../../store/slices/paymentSlice';

const UpdatePaymentForm = ({ selectedPaymentData, onClose, setSelectedTab }) => {
    const dispatch = useDispatch();
    const { status } = useSelector((state) => state.vehicle);

    const initialValues = {
        paymentDate: selectedPaymentData?.paymentDate ? new Date(selectedPaymentData.paymentDate) : '',
        paymentReference: selectedPaymentData?.paymentReference || '',
    };

    const validationSchema = Yup.object().shape({
        paymentDate: Yup.date().required('Payment Date is required'),
        paymentReference: Yup.string().required('Payment Reference is required'),
    });

    const handleSubmit = async (values) => {
        const paymentDateUTC = new Date(values.paymentDate).toISOString();
        const payload = { 
            paymentID: selectedPaymentData, 
            paymentReference: values.paymentReference, 
            paymentDate: paymentDateUTC, 
            paymentStatus: 'paid' 
        };
        await dispatch(addPaymentList(payload));
        if (status === 'succeeded') {
            onClose();
            setSelectedTab('paid')
        }
    };

    const tripsState = useSelector(state => state.vehicle);
    const loading = tripsState.isLoading;

    return (
        <div className="w-full">
            <h4 className='font-extrabold text-xl text-center'>Update Payment Status</h4>
            <Formik 
                initialValues={initialValues} 
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="w-full flex flex-col gap-4 mt-6">
                        <div className="flex flex-col w-full gap-2">
                            <label className="text-[14px] font-semibold text-custom-secondary">Payment Reference</label>
                            <Field
                                type="text"
                                id="paymentReference"
                                name="paymentReference"
                                placeholder="Enter Payment Reference"
                                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
                            />
                            <ErrorMessage name="paymentReference" component="div" className="text-red-500 text-sm" /> 
                        </div>
                        <div className="flex flex-col w-full gap-2 custom-datepicker">
                            <label className="text-[14px] font-semibold text-custom-secondary">Payment Date</label>
                            <DateTime
                                value={values.paymentDate}
                                onChange={(date) => setFieldValue('paymentDate', date)}
                                dateFormat="YYYY-MM-DD"
                                timeFormat="HH:mm:ss"
                                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7"
                                inputProps={{ placeholder: 'Select Payment Date and Time' }}
                            />
                            <ErrorMessage name="paymentDate" component="div" className="text-red-500 text-sm" />
                        </div>
                        <button
                            type="submit"
                            className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                            disabled={loading}
                        >
                            {loading ? (
                                <ClipLoader
                                    color="custom-primary"
                                    loading="loading"
                                    css="override"
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            ) : (
                                'Update Data'
                            )}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdatePaymentForm;
