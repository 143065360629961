import { customerPic } from "../assets";

export const orderData = [
    {
      'Request Type': 'Chauffer',
      'Status': 'Active',
      'Timer': '40:00',
      'Car Brand': { icon: 'toyota-icon.png', name: 'Toyota Camry 2012 XLE' },
      'Duration': {
        dateTimeFrom: '2023-11-01T08:00:00Z',
        dateTimeTo: '2023-11-04T17:30:00Z',
      },
      'Total Cost': '₦300,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'John Dumelu',
        'email': 'Johndumelu@gmail.com',
        'phoneNumber': '08123456789'
      },
      'Request Profile': {
        customerName: 'John Doe',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Self Drive',
      'Status': 'Completed',
      'Timer': '30:00',
      'Car Brand': { icon: 'honda-icon.png', name: 'Honda' },
      'Duration': {
        dateTimeFrom: '2023-11-05T10:00:00Z',
        dateTimeTo: '2023-11-07T15:45:00Z',
      },
      'Total Cost': '₦250,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'Jane Doe',
        'email': 'Janedoe@gmail.com',
        'phoneNumber': '08123456788'
      },
      'Request Profile': {
        customerName: 'Jane Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Self Drive',
      'Status': 'Pending',
      'Timer': '25:00',
      'Car Brand': { icon: 'ford-icon.png', name: 'Ford' },
      'Duration': {
        dateTimeFrom: '2023-11-10T15:00:00Z',
        dateTimeTo: '2023-11-12T20:30:00Z',
      },
      'Total Cost': '₦200,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'Alice Johnson',
        'email': 'alicejohnson@gmail.com',
        'phoneNumber': '08123456787'
      },
      'Request Profile': {
        customerName: 'Alice Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Self Drive',
      'Status': 'Active',
      'Timer': '35:00',
      'Car Brand': { icon: 'tesla-icon.png', name: 'Tesla' },
      'Duration': {
        dateTimeFrom: '2023-11-15T12:00:00Z',
        dateTimeTo: '2023-11-18T18:45:00Z',
      },
      'Total Cost': '₦350,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'Bob Anderson',
        'email': 'bobanderson@gmail.com',
        'phoneNumber': '08123456786'
      },
      'Request Profile': {
        customerName: 'Bob Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Chauffer',
      'Status': 'Completed',
      'Timer': '45:00',
      'Car Brand': { icon: 'chevrolet-icon.png', name: 'Chevrolet' },
      'Duration': {
        dateTimeFrom: '2023-11-20T09:30:00Z',
        dateTimeTo: '2023-11-23T14:15:00Z',
      },
      'Total Cost': '₦280,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'Chris Brown',
        'email': 'chrisbrown@gmail.com',
        'phoneNumber': '08123456785'
      },
      'Request Profile': {
        customerName: 'Chris Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Self Drive',
      'Status': 'Pending',
      'Timer': '28:00',
      'Car Brand': { icon: 'bmw-icon.png', name: 'BMW' },
      'Duration': {
        dateTimeFrom: '2023-11-25T11:00:00Z',
        dateTimeTo: '2023-11-28T16:30:00Z',
      },
      'Total Cost': '₦300,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'Diana Johnson',
        'email': 'dianajohnson@gmail.com',
        'phoneNumber': '08123456784'
      },
      'Request Profile': {
        customerName: 'Diana Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Chauffer',
      'Status': 'Active',
      'Timer': '32:00',
      'Car Brand': { icon: 'audi-icon.png', name: 'Audi' },
      'Duration': {
        dateTimeFrom: '2023-12-01T08:00:00Z',
        dateTimeTo: '2023-12-04T17:30:00Z',
      },
      'Total Cost': '₦320,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'David Williams',
        'email': 'davidwilliams@gmail.com',
        'phoneNumber': '08123456783'
      },
      'Request Profile': {
        customerName: 'David Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Self Drive',
      'Status': 'Approved',
      'Timer': '38:00',
      'Car Brand': { icon: 'mercedes-icon.png', name: 'Mercedes' },
      'Duration': {
        dateTimeFrom: '2023-12-05T10:00:00Z',
        dateTimeTo: '2023-12-07T15:45:00Z',
      },
      'Total Cost': '₦280,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'Emma Davis',
        'email': 'emmadavis@gmail.com',
        'phoneNumber': '08123456782'
      },
      'Request Profile': {
        customerName: 'Emma Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
      'Request Type': 'Chauffer',
      'Status': 'Declined',
      'Timer': '22:00',
      'Car Brand': { icon: 'volkswagen-icon.png', name: 'Volkswagen' },
      'Duration': {
        dateTimeFrom: '2023-12-10T12:00:00Z',
        dateTimeTo: '2023-12-12T17:30:00Z',
      },
      'Total Cost': '₦180,000,000',
      'Assigned Driver': {
        'img': customerPic,
        'name': 'Fiona Brown',
        'email': 'fionabrown@gmail.com',
        'phoneNumber': '08123456781'
      },
      'Request Profile': {
        customerName: 'Fiona Smith',
        profilePic: customerPic,
        text: 'See Profile',
      },
      'Others': 'See full details',
    },
    {
        'Request Type': 'Self Drive',
        'Status': 'Declined',
        'Timer': '38:00',
        'Car Brand': { icon: 'mercedes-icon.png', name: 'Mercedes' },
        'Duration': {
          dateTimeFrom: '2023-12-05T10:00:00Z',
          dateTimeTo: '2023-12-07T15:45:00Z',
        },
        'Total Cost': '₦280,000,000',
        'Assigned Driver': {
          'img': customerPic,
          'name': 'Emma Davis',
          'email': 'emmadavis@gmail.com',
          'phoneNumber': '08123456782'
        },
        'Request Profile': {
          customerName: 'Emma Smith',
          profilePic: customerPic,
          text: 'See Profile',
        },
        'Others': 'See full details',
      },
  ];

  export const userData = [
    {
      "user": {
        "fullName": "John Sholekan",
        "accountCreated": "2023-12-07T15:45:00Z",
        "accountNumber": "09877888930",
        "email": "johnsholekan@gmail.com",
        "opayAccount": "Opay",
        "profileInformation": {
          "carsUploaded": 23,
          "totalTransactions": 40,
          "seeAllTrips": "See all Trip",
          "mobile": "(234) 8135 962 940",
          "gender": "Male",
          "location": "Lagos, Nigeria",
          "dob": "Friday, 6th November 2023",
          "occupation": "Realtor"
        },
        "otherInfoKYC": {
          "homeAddress": "1, Sholekan Ajacin Street, Abula Egba, Lagos State",
          "proofOfAddress": "document",
          "officeAddress": "1, Sholekan Ajacin Street, Abula Egba, Lagos State",
          "meansOfIdentification": "National Identity card"
        },
        "gtiRidesInfo": {
          "dashboard": {
            "totalUsers": 2300,
            "todayUsers": "+5%"
          },
          "adminManagement": {
            "name": "John Sholekan",
            "email": "johnsholekan@gmail.com",
            "accountNumber": "09877888930"
          },
          "userManagement": "Message User, Email User, Suspend User",
          "opay": "Rent Order, Send Broadcast"
        },
        "carListings": [
          {
            "carName": "Toyota Camry TRD",
            "availability": "Available",
            "percentage": 97,
            "trips": 66,
            "price": "100,000",
            "pricePerDay": "100,000/day"
          }
          // Add more car listings as needed
        ],
        "reviews": [
          {
            "userName": "Gift Joy",
            "date": "Wed, 1 Nov, 9:00am",
            "comment": "The car I rented through the app was in great condition and the booking process was effortless. I had a smooth and enjoyable experience."
          }
          // Add more reviews as needed
        ],
        "listedDrivers": [
          {
            "fullName": "John Dumelu",
            "mobile": "08135962940",
            "email": "Johndumelu@gmail.com"
          }
          // Add more listed drivers as needed
        ]
      }
    },
    {
      "user": {
        "fullName": "John Sholekan",
        "accountCreated": "2023-10-07T15:45:00Z",
        "accountNumber": "09877888930",
        "email": "johnsholekan@gmail.com",
        "opayAccount": "Opay",
        "profileInformation": {
          "carsUploaded": 23,
          "totalTransactions": 40,
          "seeAllTrips": "See all Trip",
          "mobile": "(234) 8135 962 940",
          "gender": "Male",
          "location": "Lagos, Nigeria",
          "dob": "Friday, 6th November 2023",
          "occupation": "Realtor"
        },
        "otherInfoKYC": {
          "homeAddress": "1, Sholekan Ajacin Street, Abula Egba, Lagos State",
          "proofOfAddress": "document",
          "officeAddress": "1, Sholekan Ajacin Street, Abula Egba, Lagos State",
          "meansOfIdentification": "National Identity card"
        },
        "gtiRidesInfo": {
          "dashboard": {
            "totalUsers": 2300,
            "todayUsers": "+5%"
          },
          "adminManagement": {
            "name": "John Sholekan",
            "email": "johnsholekan@gmail.com",
            "accountNumber": "09877888930"
          },
          "userManagement": "Message User, Email User, Suspend User",
          "opay": "Rent Order, Send Broadcast"
        },
        "carListings": [
          {
            "carName": "Toyota Camry TRD",
            "availability": "Available",
            "percentage": 97,
            "trips": 66,
            "price": "100,000",
            "pricePerDay": "100,000/day"
          }
          // Add more car listings as needed
        ],
        "reviews": [
          {
            "userName": "Gift Joy",
            "date": "Wed, 1 Nov, 9:00am",
            "comment": "The car I rented through the app was in great condition and the booking process was effortless. I had a smooth and enjoyable experience."
          }
          // Add more reviews as needed
        ],
        "listedDrivers": [
          {
            "fullName": "John Dumelu",
            "mobile": "08135962940",
            "email": "Johndumelu@gmail.com"
          }
          // Add more listed drivers as needed
        ]
      }
    },
    {
      "user": {
        "fullName": "John Sholekan",
        "accountCreated": "2023-11-07T15:45:00Z",
        "accountNumber": "09877888930",
        "email": "johnsholekan@gmail.com",
        "opayAccount": "Opay",
        "profileInformation": {
          "carsUploaded": 23,
          "totalTransactions": 40,
          "seeAllTrips": "See all Trip",
          "mobile": "(234) 8135 962 940",
          "gender": "Male",
          "location": "Lagos, Nigeria",
          "dob": "Friday, 6th November 2023",
          "occupation": "Realtor"
        },
        "otherInfoKYC": {
          "homeAddress": "1, Sholekan Ajacin Street, Abula Egba, Lagos State",
          "proofOfAddress": "document",
          "officeAddress": "1, Sholekan Ajacin Street, Abula Egba, Lagos State",
          "meansOfIdentification": "National Identity card"
        },
        "gtiRidesInfo": {
          "dashboard": {
            "totalUsers": 2300,
            "todayUsers": "+5%"
          },
          "adminManagement": {
            "name": "John Sholekan",
            "email": "johnsholekan@gmail.com",
            "accountNumber": "09877888930"
          },
          "userManagement": "Message User, Email User, Suspend User",
          "opay": "Rent Order, Send Broadcast"
        },
        "carListings": [
          {
            "carName": "Toyota Camry TRD",
            "availability": "Available",
            "percentage": 97,
            "trips": 66,
            "price": "100,000",
            "pricePerDay": "100,000/day"
          }
          // Add more car listings as needed
        ],
        "reviews": [
          {
            "userName": "Gift Joy",
            "date": "Wed, 1 Nov, 9:00am",
            "comment": "The car I rented through the app was in great condition and the booking process was effortless. I had a smooth and enjoyable experience."
          }
          // Add more reviews as needed
        ],
        "listedDrivers": [
          {
            "fullName": "John Dumelu",
            "mobile": "08135962940",
            "email": "Johndumelu@gmail.com"
          }
          // Add more listed drivers as needed
        ]
      }
    },
    
  ]
  
  export const carData = [
    {
      'Car Brand': { icon: 'toyota-icon.png', name: 'Toyota Camry 2012 XLE' },
      'Trips Completed': 100,
      'Price/Day': '₦300,000,000',
      'Rating': '40:00',
      'Status': 'Approved',
      'ApprovedStatus': 'Available',
      'Car Owner': {
        'img': customerPic,
        'name': 'John Dumelu',
        'email': 'Johndumelu@gmail.com',
        'phoneNumber': '08123456789'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'honda-icon.png', name: 'Honda' },
      'Trips Completed': 80,
      'Price/Day': '₦250,000,000',
      'Rating': '30:00',
      'Status': 'Approved',
      'ApprovedStatus': 'Unavailable',
      'Car Owner': {
        'img': customerPic,
        'name': 'Jane Doe',
        'email': 'Janedoe@gmail.com',
        'phoneNumber': '08123456788'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'ford-icon.png', name: 'Ford' },
      'Trips Completed': 40,
      'Price/Day': '₦200,000,000',
      'Rating': '25:00',
      'Status': 'Approved',
      'ApprovedStatus': 'Suspended',
      'Car Owner': {
        'img': customerPic,
        'name': 'Alice Johnson',
        'email': 'alicejohnson@gmail.com',
        'phoneNumber': '08123456787'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'tesla-icon.png', name: 'Tesla' },
      'Trips Completed': 100,
      'Price/Day': '₦350,000,000',
      'Rating': '35:00',
      'Status': 'Pending',
      'Car Owner': {
        'img': customerPic,
        'name': 'Bob Anderson',
        'email': 'bobanderson@gmail.com',
        'phoneNumber': '08123456786'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'chevrolet-icon.png', name: 'Chevrolet' },
      'Trips Completed': 0,
      'Price/Day': '₦280,000,000',
      'Rating': '45:00',
      'Status': 'Declined',
      'Car Owner': {
        'img': customerPic,
        'name': 'Chris Brown',
        'email': 'chrisbrown@gmail.com',
        'phoneNumber': '08123456785'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'bmw-icon.png', name: 'BMW' },
      'Trips Completed': 50,
      'Price/Day': '₦300,000,000',
      'Rating': '28:00',
      'Status': 'Pending',
      'Car Owner': {
        'img': customerPic,
        'name': 'Diana Johnson',
        'email': 'dianajohnson@gmail.com',
        'phoneNumber': '08123456784'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'audi-icon.png', name: 'Audi' },
      'Trips Completed': 0,
      'Price/Day': '₦320,000,000',
      'Rating': '32:00',
      'Status': 'Declined',
      'Car Owner': {
        'img': customerPic,
        'name': 'David Williams',
        'email': 'davidwilliams@gmail.com',
        'phoneNumber': '08123456783'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'mercedes-icon.png', name: 'Mercedes' },
      'Trips Completed': 80,
      'Price/Day': '₦280,000,000',
      'Rating': '38:00',
      'Status': 'Approved',
      'ApprovedStatus': 'Active',
      'Car Owner': {
        'img': customerPic,
        'name': 'Emma Davis',
        'email': 'emmadavis@gmail.com',
        'phoneNumber': '08123456782'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'volkswagen-icon.png', name: 'Volkswagen' },
      'Trips Completed': 0,
      'Price/Day': '₦180,000,000',
      'Rating': '22:00',
      'Status': 'Declined',
      'Car Owner': {
        'img': customerPic,
        'name': 'Fiona Brown',
        'email': 'fionabrown@gmail.com',
        'phoneNumber': '08123456781'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
    {
      'Car Brand': { icon: 'mercedes-icon.png', name: 'Mercedes' },
      'Trips Completed': 0,
      'Price/Day': '₦280,000,000',
      'Rating': '38:00',
      'Status': 'Declined',
      'Car Owner': {
        'img': customerPic,
        'name': 'Emma Davis',
        'email': 'emmadavis@gmail.com',
        'phoneNumber': '08123456782'
      },
      'Location': 'Lagos State',
      'Others': 'See full details',
    },
  ];

  export const paymentData = [
    {
      payment: {
        carBrand: { icon: 'toyota-icon.png', name: 'Toyota Camry 2012 XLE' },
        duration: {
          dateTimeFrom: '2023-11-01T08:00:00Z',
          dateTimeTo: '2023-11-04T17:30:00Z',
        },
        dateOfPayment: '2023-11-01T08:00:00Z',
        totalCost: '₦300,000,000',
        carOwner: {
          img: customerPic,
          name: 'John Dumelu',
          email: 'Johndumelu@gmail.com',
          phoneNumber: '08123456789'
        },
        paymentDetails: {
          bankName: 'First Bank',
          accountNumber: '8197655432'
        },
        moreActions: 'See full details',
        status: 'Pending Payment',
      }
    },
    {
      payment: {
        carBrand: { icon: 'toyota-icon.png', name: 'Toyota Camry 2012 XLE' },
        duration: {
          dateTimeFrom: '2023-11-01T08:00:00Z',
          dateTimeTo: '2023-11-04T17:30:00Z',
        },
        dateOfPayment: '2023-11-01T08:00:00Z',
        totalCost: '₦300,000,000',
        carOwner: {
          img: customerPic,
          name: 'John Dumelu',
          email: 'Johndumelu@gmail.com',
          phoneNumber: '08123456789'
        },
        paymentDetails: {
          bankName: 'First Bank',
          accountNumber: '8197655432'
        },
        moreActions: 'See full details',
        status: 'Pending Payment',
      }
    },
    {
      payment: {
        carBrand: { icon: 'toyota-icon.png', name: 'Toyota Camry 2012 XLE' },
        duration: {
          dateTimeFrom: '2023-11-01T08:00:00Z',
          dateTimeTo: '2023-11-04T17:30:00Z',
        },
        dateOfPayment: '2023-11-01T08:00:00Z',
        totalCost: '₦300,000,000',
        carOwner: {
          img: customerPic,
          name: 'John Dumelu',
          email: 'Johndumelu@gmail.com',
          phoneNumber: '08123456789'
        },
        paymentDetails: {
          bankName: 'First Bank',
          accountNumber: '8197655432'
        },
        moreActions: 'See full details',
        status: 'Paid Payment',
      }
    }
  ];
  

export const referralData = [
  {
    'User Profile': {
      'img': customerPic,
      'name': 'John Doe',
      'email': 'johndoe@gmail.com',
      'phoneNumber': '08123456789'
    },
    'Total Referral Done': 15,
    'Total Paid Referral': 10,
    'Unpaid Referral': 5,
    'More Actions': 'View Details'
  },
  {
    'User Profile': {
      'img': customerPic,
      'name': 'Jane Smith',
      'email': 'janesmith@gmail.com',
      'phoneNumber': '08123456788'
    },
    'Total Referral Done': 20,
    'Total Paid Referral': 18,
    'Unpaid Referral': 2,
    'More Actions': 'View Details'
  },
];


  
  

  
export const data = {
  labels: ['Red', 'Orange', 'Blue'],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
      {
        label: 'Popularity of colours',
        data: [55, 23, 96],
        // you can set indiviual colors for each bar
        backgroundColor: [
          'rgba(255, 255, 255, 0.6)'
        ],
        borderWidth: 1,
      }
  ]
}