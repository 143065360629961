import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TripsDataTable from '../../components/MasterData/TripsDataTable';
import TripsDataForm from '../../components/MasterData/TripsDataForm';

const TripLists = () => {
    const [selectedTab, setSelectedTab] = useState('listed');
    const tripsData = useSelector(state => state.vehicle.tripsData);
    
    console.log("this is my trips data", tripsData)
    const loading = useSelector(state => state.vehicle.isLoading); 

  return (
    <div className="w-full">
      <div className='overflow-x-scroll hide-scrollbar'>
        <div className='flex gap-4 mb-4'>
          <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'listed' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => {
                setSelectedTab('listed');
              }}
            >
              Trips Data List <span className='bg-success text-white rounded-md px-3 py-1 text-[12px]'>{tripsData.length}</span>
          </button>
          {/* <button
            type='button'
            className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'new' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
            onClick={() => {
              setSelectedTab('new');
            }}
          >
            Add New Trips Data
          </button> */}
        </div>
      </div>
      {selectedTab === 'listed' ? (
            <div className='overflow-x-scroll hide-scrollbar'>
                <TripsDataTable dataList={tripsData} loading={loading} />
            </div>
      ):(
        <div className='w-[90%] lg:w-1/3 mx-auto md:mx-0'>
          <TripsDataForm setSelectedTab={setSelectedTab} dataList={tripsData}/>
        </div>
      )}
      
    </div>
  );
};

export default TripLists;
