import React, { useState, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import { useLocation } from 'react-router-dom';
import { getPaymentList } from '../store/slices/paymentSlice';
import PaymentTable from '../components/PaymentTable';

const PaymentLists = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const getDefaultHeaders = () => {
    return ['Trip ID', 'Car Brand', 'Car Model', 'Duration', 'Total Cost to be Paid', 'Car Owner', 'Payment Status', 'Payment Details', 'More Actions'];
  };

  const getHeaders = (tab) => {
    if (tab === 'paid') {
      return getDefaultHeaders();
    } else if (tab === 'Pending') {
      return getDefaultHeaders(); 
    } else if (tab === 'Declined') {
      return getDefaultHeaders();
    }
    return getDefaultHeaders();
  };

  const location = useLocation();
  const updatedStatus = location.state?.status || 'pending';
  const [selectedTab, setSelectedTab] = useState(updatedStatus);

  const tableHeaders = getHeaders(selectedTab);

  useEffect(() => {
    dispatch(getPaymentList({
      queryParams: {
        paymentStatus: selectedTab, 
        limit: 100,
        skip: 0
      }
    }));
  }, [dispatch, selectedTab]);

  const paymentState = useSelector(state => state.payment);
  const allPayments = paymentState.paymentList;
  const loading = paymentState.loading;

  const filterPaymentsByStatus = (payments, status) => {
    return payments.filter(payment => payment.paymentStatus === status);
  };

  const filteredPayments = filterPaymentsByStatus(allPayments, selectedTab);

  // Paginate the data according to the current page
  const paginatedData = filteredPayments.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const tableData = paginatedData.map((payment, index) => ({
    'S/N': index + 1 + (currentPage - 1) * pageSize,
    'Trip ID': payment.tripID,
    'Car Brand': payment.car.carBrand,
    'Car Model': payment.car.carModel,
    'Plate Number': payment.car.plateNumber,
    'Duration': {
      startDate: payment.trip.startDate,
      endDate: payment.trip.endDate,
    },
    'Payment Status': payment.paymentStatus,
    'Total Cost to be Paid': payment.paymentAmount,
    'Car Owner': {
      userID: payment.partner.userID,
      fullName: payment.partner.userName,
      profilePic: payment.partner.userProfileUrl
    },
    'Payment Details': (payment.paymentMethod),
    'Location': payment.state,
    'More Actions': {
      paymentID: payment.paymentID,
      paymentReference: payment.paymentReference,
      paymentDate: payment.paymentDate
    },
  }));

  const handlePageChange = (direction) => {
    if (direction === 'next') {
      setCurrentPage(prevPage => prevPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  console.log(tableData)

  return (
    <div className="w-full py-4">
      <div className='flex gap-4 items-center mb-8'>
        <div className="w-[150px] lg:w-[200px] relative">
          <input
            className="w-full py-2.5 pe-2 ps-10 outline-none text-sm bg-transparent border border-grey-3 rounded-lg"
            type="text"
            placeholder="Search Order by Id..."
          />
          <AiOutlineSearch className=" absolute top-3.5 left-3 text-black" />
        </div>
      </div>
      <div className='overflow-x-scroll hide-scrollbar'>
        <div className='flex gap-4 mb-4'>
          <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'pending' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              onClick={() => {
                setSelectedTab('pending');
                setCurrentPage(1); // Reset to first page when tab changes
              }}
            >
              Pending Payment
          </button>
          <button
            className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'paid' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
            onClick={() => {
              setSelectedTab('paid');
              setCurrentPage(1); // Reset to first page when tab changes
            }}
          >
            Paid Payment
          </button>
        </div>
      </div>
      {loading ? (
          <Loader loading={loading}/>
      ) : (
        tableData.length > 0 ? (
          <div className='overflow-x-scroll scrollbar flex flex-col justify-between min-h-[60vh]'>
            <PaymentTable data={tableData} headers={tableHeaders} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
          </div>
        ):(
          <div className='flex flex-col h-[60vh] items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" width="145" height="144" viewBox="0 0 145 144" fill="none">
                <path opacity="0.2" d="M126.5 31.5V112.5C126.5 113.693 126.026 114.838 125.182 115.682C124.338 116.526 123.193 117 122 117H23C21.8065 117 20.6619 116.526 19.818 115.682C18.9741 114.838 18.5 113.693 18.5 112.5V31.5C18.5 30.3065 18.9741 29.1619 19.818 28.318C20.6619 27.4741 21.8065 27 23 27H122C123.193 27 124.338 27.4741 125.182 28.318C126.026 29.1619 126.5 30.3065 126.5 31.5Z" fill="#DAC1D9"/>
                <path d="M122 22.5H23C20.6131 22.5 18.3239 23.4482 16.636 25.136C14.9482 26.8239 14 29.1131 14 31.5V112.5C14 114.887 14.9482 117.176 16.636 118.864C18.3239 120.552 20.6131 121.5 23 121.5H122C124.387 121.5 126.676 120.552 128.364 118.864C130.052 117.176 131 114.887 131 112.5V31.5C131 29.1131 130.052 26.8239 128.364 25.136C126.676 23.4482 124.387 22.5 122 22.5ZM122 112.5H23V31.5H122V112.5ZM99.5 49.5C99.5 56.6608 96.6554 63.5284 91.5919 68.5919C86.5284 73.6554 79.6608 76.5 72.5 76.5C65.3392 76.5 58.4716 73.6554 53.4081 68.5919C48.3446 63.5284 45.5 56.6608 45.5 49.5C45.5 48.3065 45.9741 47.1619 46.818 46.318C47.6619 45.4741 48.8065 45 50 45C51.1935 45 52.3381 45.4741 53.182 46.318C54.0259 47.1619 54.5 48.3065 54.5 49.5C54.5 54.2739 56.3964 58.8419 59.8447 62.2903C63.293 65.7386 67.861 67.635 72.635 67.635C77.409 67.635 81.977 65.7386 85.4253 62.2903C88.8736 58.8419 90.77 54.2739 90.77 49.5C90.77 48.3065 91.2441 47.1619 92.088 46.318C92.9319 45.4741 94.0765 45 95.27 45C96.4635 45 97.6081 45.4741 98.452 46.318C99.2959 47.1619 99.77 48.3065 99.77 49.5H99.5ZM68 94.5H77V99H68V94.5ZM68 104.5H77V109.5H68V104.5Z" fill="#BDBDBD"/>
            </svg>
            <p className='text-xl mt-4'>No Payments Found!</p>
          </div>
        )
      )}
      <div className="flex justify-center gap-4 mt-4">
        <button
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
          className="py-2 px-4 bg-brand text-white rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange('next')}
          disabled={currentPage * pageSize >= filteredPayments.length}
          className="py-2 px-4 bg-brand text-white rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaymentLists;
