import React from 'react'
import StateLists from '../../containers/MasterData/StateList'

const States = () => {
  return (
    <StateLists/>
    
  )
}

export default States