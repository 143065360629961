import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  getUserProfile, 
  getUserReview, 
  getUserKYC, 
  getUserPaymentBank, 
  getUserCars, 
  getUserCarReview, 
  getUserDrivers, 
  getAllUsers
} from '../../helpers/api';


const initialState = {
  llUsers: [],
  userProfile: [],
  userReview: [],
  userKYC: null,
  userPaymentBank: [],
  userCars: [],
  userCarReview: [],
  userDrivers: [],
  userCount: null,
  status: 'idle',
  error: null,
  isLoading: false,
};


export const fetchAllUsers = createAsyncThunk('user/fetchAllUsers', async ({queryParams}) => {
  const response = await getAllUsers(queryParams);

  const responseData = {
    data: response.data,
    count: response.count
  };

  return responseData;
});




export const fetchUserProfile = createAsyncThunk('user/fetchUserProfile', async ({ userID }) => {
  try {
    const response = await getUserProfile(userID);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchUserReview = createAsyncThunk('user/fetchUserReview', async ({ userID, limit, skip }) => {
  try {
    const response = await getUserReview(userID, { limit, skip });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchUserKYC = createAsyncThunk('user/fetchUserKYC', async ({ userID }) => {
  try {
    const response = await getUserKYC(userID);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchUserPaymentBank = createAsyncThunk('user/fetchUserPaymentBank', async ({ userID }) => {
  try {
    const response = await getUserPaymentBank(userID);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchUserCars = createAsyncThunk('user/fetchUserCars', async ({ userID, limit, skip }) => {
  try {
    const response = await getUserCars(userID, { limit, skip });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchUserCarReview = createAsyncThunk('user/fetchUserCarReview', async ({ userID, limit, skip }) => {
  try {
    const response = await getUserCarReview(userID, { limit, skip });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchUserDrivers = createAsyncThunk('user/fetchUserDrivers', async ({ userID, limit, skip }) => {
  try {
    const response = await getUserDrivers(userID, { limit, skip });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    resetUserState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allUsers = action.payload.data;
        state.userCount = action.payload.count;
        state.isLoading = false;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userProfile = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserReview.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserReview.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userReview = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserReview.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserKYC.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserKYC.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userKYC = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserKYC.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserPaymentBank.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserPaymentBank.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userPaymentBank = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserPaymentBank.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserCars.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserCars.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userCars = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserCars.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserCarReview.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserCarReview.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userCarReview = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserCarReview.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserDrivers.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserDrivers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userDrivers = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserDrivers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});

export const { resetUserState } = userSlice.actions;

export default userSlice.reducer;
