import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar } from 'react-icons/bi';

const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className='w-[150px] flex justify-center bg-transparent outline-none border border-brand text-brand items-center px-1 py-3 h-full' onClick={onClick}>
    <span className='mr-2'>{value}</span>
    <BiCalendar />
  </div>
));


const LineGraph = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(getSampleData(selectedDate));
  }, [selectedDate]);

  const getSampleData = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    
    const sampleData = {
      '2023-12': [
        { day: 1, count: 100 },
        { day: 5, count: 500 },
        { day: 10, count: 350 },
        { day: 15, count: 2200 },
        { day: 20, count: 2500 },
        { day: 25, count: 2800 },
        { day: 31, count: 3000 },
      ],
      '2023-11': [
        { day: 1, count: 520 },
        { day: 5, count: 480 },
        { day: 10, count: 400 },
        { day: 15, count: 2100 },
        { day: 20, count: 2400 },
        { day: 25, count: 2600 },
        { day: 30, count: 2800 },
      ],
      '2023-10': [
        { day: 1, count: 80 },
        { day: 5, count: 450 },
        { day: 10, count: 300 },
        { day: 15, count: 2000 },
        { day: 20, count: 2300 },
        { day: 25, count: 2500 },
        { day: 31, count: 2900 },
      ],
      '2022-12': [
        { day: 1, count: 90 },
        { day: 5, count: 550 },
        { day: 10, count: 370 },
        { day: 15, count: 2400 },
        { day: 20, count: 2700 },
        { day: 25, count: 2900 },
        { day: 31, count: 3100 },
      ],
    };

    return sampleData[`${year}-${month + 1}`] || [];
  };

  const options = {
    chart: {
      height: 320,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#ECAE00'],
    },
    title: {
      align: 'left',
    },
    grid: {
      borderColor: '#FFD970',
      strokeDashArray: 0,
      position: 'back',
    },
    xaxis: {
      categories: data.map((row) => row.day),
      tickAmount: 7,
    },
    yaxis: {
      tickAmount: 7,
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    tooltip: {
      enabled: false,
      style: {
        background: '#FFD970',
        color: '#ECAE00',
      },
    },
    toolbar: {
      show: false,
    },
  };

  return (
    <div className="w-full">
      <div className=" flex justify-between items-center">
        <div className="w-full">
          <h4 className="text-gray-500 font-semibold">Car Uploads (Approved)</h4>
        </div>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          customInput={<CustomDatePickerInput />}
        />
      </div>


      <div id="chart">
        <ReactApexChart options={options} series={[{ data: data.map((row) => row.count) }]} type="line" height={350} />
      </div>
    </div>
  );
};

export default LineGraph;
