import React, { useState, useEffect } from 'react';
import DateTime from './DateTime'; 
import { wheel } from '../../assets';
import { Link } from 'react-router-dom';
import OrderConfirmationModal from './OrderConfirmationModal';
import useImageLoader from './useImageLoader';

const OrderTable = ({ headers, data }) => {
  const [showModal, setShowModal] = useState(false);
  const { imageLoaded, handleImageLoad } = useImageLoader();
  const [orderData, setOrderData] = useState({});
  const [countdown, setCountdown] = useState([]);

  const handleClick = async (selectedOrder) => {
    setShowModal(true);
    setOrderData(selectedOrder);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const calculateCountdown = (endDateTime) => {
    const end = new Date(endDateTime);
    const currentTime = new Date();
    const difference = end.getTime() - currentTime.getTime(); 

    if (difference <= 0) {
      return '00:00';
    }

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedCountdown = data.map(row => calculateCountdown(row?.tripEndDate));
      setCountdown(updatedCountdown);
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <>
      <table className="w-full table-auto text-grey-7">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header} className="text-left px-8 py-4 uppercase text-[12px] whitespace-nowrap text-gray-500">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className={`${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'} hover:cursor-pointer hover:bg-comp-7`} >
              {headers.map((header, headerIndex) => (
                <td key={header} className="py-2 px-8 whitespace-nowrap">
                  {header === 'S/N' ? (
                    <p className="font-normal">
                    {row?.serialNumber}
                  </p>
                  ) : header === 'Trip ID' ? (
                    <p className="text-grey-7 font-medium">
                      {row?.tripID}
                    </p>
                  ) : header === 'Duration' ? (
                    <DateTime
                      dateTimeFrom={row?.tripStartDate}
                      dateTimeTo={row?.tripEndDate}
                    />
                  ) : header === 'Timer' ? (
                    <p className="text-brand font-semibold">
                      {countdown[index]}
                    </p>
                  ) : header === 'Car Brand' ? (
                    <div className="flex items-center gap-2">
                      <div className='w-6 h-6 flex justify-center items-center bg-black text-white rounded-sm'>
                        <img
                          src={wheel}
                          alt="Wheel"
                          onLoad={handleImageLoad}
                          style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                      </div>
                      {row?.carBrand}
                    </div>
                  ) : header === 'Car Model' ? (
                    <div className="text-grey-7 font-medium flex flex-col gap-1">
                      {row?.carModel}
                      <p className='text-xs'>{row?.carPlateNumber}</p>
                    </div>
                  ) : header === 'Assigned Driver' ? (
                    <div className="flex  gap-4 items-center">
                      <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                        <img
                          src={row?.carProfilePic}
                          className='w-4 h-4 rounded-full'
                          alt="Car Pic"
                          onLoad={handleImageLoad}
                          style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                      </div>
                      <div className='flex flex-col'>
                        {/* <p className='text-[14px] font-semibold text-grey-6'>{row['Assigned Driver'].name}</p> */}
                        {/* <p className='text-sm whitespace-nowrap font-[500]'>{row['Assigned Driver'].phoneNumber}  |  {row['Assigned Driver'].email}</p> */}
                      </div>
                    </div>
                  ) : header === 'Request Profile' ? (
                    <div className="flex items-center gap-2">
                      <div className='w-5 h-5 flex justify-center items-center bg-black text-white rounded-full'>
                        <img
                          src={row?.renter.userProfileUrl}
                          className='w-4 h-4 rounded-full'
                          alt="Profile Pic"
                          onLoad={handleImageLoad}
                          style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                        />
                      </div>
                      <div className='flex flex-col gap-1'>
                        {row?.renter.userName}
                          <Link to={`/user-management/${row?.renter.userID}`}>
                          <button className='text-success underline text-[14px]'>See Profile</button>
                        </Link>
                      </div>
                    </div>
                  ) : header === 'Trip Status' ? (
                    <div className="flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <circle cx="9" cy="9" r="9" fill="#1A1300"/>
                        <path d="M5 8.5L7.3564 12.7415C7.42515 12.8653 7.59719 12.8804 7.68646 12.7705L14 5" stroke="white" strokeLinecap="round"/>
                      </svg>
                      <p>{row?.status}</p>
                    </div>
                  ) : header === 'Admin Approval' ? (
                    <div className="flex items-center gap-2">
                      
                      <p className='capitalize'>{row?.adminStatus}</p>
                    </div>
                  ) : header === 'Others' ? (
                      <button className='text-warning text-[14px]' onClick={() => handleClick(row)}>
                        See full details
                      </button>
                      
                  ) : header === 'Total Cost' ? (
                    <p className="text-grey-7 font-medium">
                      {row?.totalFee}
                    </p>
                  ) : header === 'Trip Type' ? (
                    <p className="text-grey-7 font-normal">
                      {row?.tripType}
                    </p>
                  ) : header === 'Status' ? (
                    <p className="text-grey-7 font-normal">
                      {row?.status}
                    </p>
                  ) : (
                    row?.hasOwnProperty(header) ? row[header] : 'N/A'
                  )}
                </td>
                
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <OrderConfirmationModal
        isOpen={showModal}
        onClose={closeModal}
        data={orderData}
      />
    </>
  );
};

export default OrderTable;
