import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVehicleYear } from '../../store/slices/vehicleSlice';
import YearTable from '../../components/MasterData/YearTable';
import YearForm from '../../components/MasterData/YearForm';

const YearLists = () => {
    const [selectedTab, setSelectedTab] = useState('listed');
    const { brandCode, modelCode } = useParams();
    const vehicles = useSelector(state => state.vehicle);
    const years = vehicles.vehicleYears;
    const loading = vehicles.isLoading;

  
    const dispatch = useDispatch();

    useEffect(() => {
      const fetchModel = async () => {
              try {
                  await dispatch(getVehicleYear({ brandCode, modelCode}));
                  
              } catch (error) {
                  console.error(`Error fetching models for brand`, error);
              }
      };

      fetchModel();
  }, [brandCode, dispatch, modelCode]);

  



    

  return (
    <div className="w-full">
      <div className='overflow-x-scroll hide-scrollbar'>
        <div className='flex gap-4 mb-4'>
          <button
              className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'listed' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
              type='button'
              onClick={() => {
                setSelectedTab('listed');
              }}
            >
              Listed Vehicle Years <span className='bg-success text-white rounded-md px-3 py-1 text-[12px]'>{years.length}</span>
          </button>
          <button
            type='button'
            className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'new' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
            onClick={() => {
              setSelectedTab('new');
            }}
          >
            Add New Year
          </button>
        </div>
      </div>
      {selectedTab === 'listed' ? (
            <div className='overflow-x-scroll hide-scrollbar'>
                <YearTable dataList={years} loading={loading} />
            </div>
      ):(

        <YearForm setSelectedTab={setSelectedTab}/>
      )}
      
    </div>
  );
};

export default YearLists;
