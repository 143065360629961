import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReferralTable from '../components/ReferralTable';
import { fetchReferrals } from '../store/slices/referralSlice';
import Loader from '../components/Loader';

const ReferralLists = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const apiLimit = 10;

  const tableHeaders = ['User Profile', 'Total Referral Done', 'Total Paid Referral', 'Unpaid Referral', 'More Actions'];

  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        await dispatch(fetchReferrals({ skip: (currentPage - 1) * apiLimit, limit: apiLimit }));
      } catch (error) {
        console.error('Error fetching referrals:', error.message);
      }
    };

    fetchReferralData();
  }, [dispatch, currentPage, apiLimit]);

  const referralState = useSelector((state) => state.referral);
  const referrals = referralState.referrals?.data || []; // Ensure referrals is an array

  const tableData = referrals.map((ref, index) => ({
    'User Profile': "User",
    'Total Referral Done': ref?.referralCount,
    'Total Paid Referral': ref?.referrals,
    'Unpaid Referral': ref?.pendingReferrals,
    'User': {
      name: ref?.user?.fullName,
      userId: ref?.user?.userID,
    },
    'More Actions': {
      paid: ref?.user.paidRefs,
    },
  }));

  const handlePageChange = (direction) => {
    if (direction === 'next') setCurrentPage((prev) => prev + 1);
    else if (direction === 'prev' && currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="w-full py-4">
      {/* Render ReferralTable and other components here */}

      {referralState.isLoading ? (
        <Loader loading={referralState.isLoading} />
      ) : tableData.length > 0 ? (
        <div className="overflow-x-scroll hide-scrollbar">
          <ReferralTable data={tableData} headers={tableHeaders} />
        </div>
      ) : (
        <div className="flex flex-col h-[60vh] items-center justify-center">
          <p>No referrals found</p>
        </div>
      )}

      <div className="flex justify-center mt-4">
        <button
          className={`py-2 px-4 rounded mr-2 font-bold 
            ${currentPage === 1 ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-brand hover:bg-comp-2 text-white'}
          `}
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className={`py-2 px-4 rounded font-bold 
            ${tableData.length < apiLimit ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-brand hover:bg-comp-2 text-white'}
          `}
          onClick={() => handlePageChange('next')}
          disabled={tableData.length < apiLimit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ReferralLists;
