import React from 'react'
import PaymentSummary from '../containers/PaymentSummary'
import PaymentLists from '../containers/PaymentLists'

const Payments = () => {
  return (
    <div className='w-full flex flex-col gap-8'>
      <PaymentSummary/>
      <div className='w-full flex flex-col lg:flex-row gap-6'>
        <PaymentLists/>
      </div>
    </div>
  )
}

export default Payments


    