import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCarsApi, getOneCarApi, updateCarStatusApi, getOneCarReviewApi, getOneCarTripsApi, countAllCarsApi } from '../../helpers/api';
import { toast } from 'react-toastify';

export const fetchAllCars = createAsyncThunk('car/fetchAllCars', async ({ queryParams }) => {
  try {
    const response = await getAllCarsApi({queryParams});
    const responseData = {
      data: response.data,
      count: response.count
    };

  return responseData;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchOneCar = createAsyncThunk('car/fetchOneCar', async ({ carID }) => {
  try {
    const response = await getOneCarApi(carID);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const updateCarStatus = createAsyncThunk(
  'car/updateCarStatus',
  async ({ carID, status, navigate }, { dispatch }) => {
    try {
      const response = await updateCarStatusApi(carID, status);
      if (response.status_code === 200) {
        toast.success(response.message);

        dispatch(fetchOneCar({ carID }));
        navigate("/manage-cars/", { state: { status }})
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const fetchOneCarReview = createAsyncThunk('car/fetchOneCarReview', async ({ carID }) => {
  try {
    const response = await getOneCarReviewApi(carID);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchOneCarTrips = createAsyncThunk('car/fetchOneCarTrips', async ({ carID }) => {
  try {
    const response = await getOneCarTripsApi(carID);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const countAllCars = createAsyncThunk(
  'payment/countAllCars',
  async (_, { dispatch }) => {
    try {
      const response = await countAllCarsApi();
      return response.data;
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

const carSlice = createSlice({
  name: 'car',
  initialState: {
    allCars: [],
    oneCar: null,
    oneCarReview: [],
    oneCarTrips: [],
    carCount: null,
    allCarsCount: null,
    status: 'idle',
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCars.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchAllCars.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allCars = action.payload.data;
        state.carCount = action.payload.count;
        state.isLoading = false;
      })
      .addCase(fetchAllCars.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchOneCar.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchOneCar.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.oneCar = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchOneCar.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateCarStatus.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(updateCarStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(updateCarStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchOneCarReview.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchOneCarReview.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.oneCarReview = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchOneCarReview.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchOneCarTrips.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchOneCarTrips.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.oneCarTrips = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchOneCarTrips.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(countAllCars.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(countAllCars.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allCarsCount = action.payload;
        state.isLoading = false;
      })
      .addCase(countAllCars.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});


export default carSlice.reducer;
