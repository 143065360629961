import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useImageLoader from '../../../../components/useImageLoader';
import { customerIcon } from '../../../../assets';
import { ClipLoader } from 'react-spinners';
import Loader from '../Loader';
import { deleteBrandModel } from '../../store/slices/vehicleSlice';
import { useDispatch } from 'react-redux';
import ModelForm from './ModelForm';
import UpdateModal from './UpdateModal';
import { showCustomConfirmAlert } from '../CustomConfirmAlert';
import { toast } from 'react-toastify';

const ModelTable = ({ dataList, loading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const openModal = (brand) => {
      setSelectedBrand(brand);
      setIsModalOpen(true);
  };
  
  const closeModal = () => {
      setSelectedBrand(null);
      setIsModalOpen(false);
  };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { brandName } = location.state;

    const handleViewYears = (brandCode, modelCode, brandName, modelName) => {
      navigate(`/admin/master-data/models/years/${brandCode}/${modelCode}`, { state: { brandName, modelName } });
  };


  const handleDeleteClick = ({ modelCode, brandCode }) => {
    showCustomConfirmAlert(() => {
      dispatch(deleteBrandModel({ modelCode, brandCode }))
        .unwrap()
        .catch(error => {
          toast.error(error.message);
        });
    });
  };


  return (
    <div className='w-full'>
      {loading ? (
          <Loader loading={loading}/>
      ) : (
        <table className='w-full table-auto text-grey-6'>
          <thead>
            <tr>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                S/N
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                Vehicle Brand
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                Vehicle Model
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                
              </th>
              <th className='px-6 py-3 text-left whitespace-nowrap'>
                
              </th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((data, index) => (
              <tr key={index} className={`px-6 ${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}`}>
                <td className='px-6 py-3 whitespace-nowrap'>{index + 1}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{brandName}</td>
                <td className='px-6 py-3 whitespace-nowrap'>{data.modelName}</td>
                {/* <td className='px-6 py-3 whitespace-nowrap'></td> */}
                <td className='px-6 py-3 whitespace-nowrap'>
                  <button className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit' onClick={() => handleViewYears(data.brandCode, data.modelCode, brandName, data.modelName)}>
                      See Vehicle Years
                  </button>
                </td>
                <td className='px-6 py-3 whitespace-nowrap'>
                  <button
                      className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                      onClick={() => handleDeleteClick({ modelCode: data.modelCode, brandCode: data.brandCode })}
                  >
                      Delete Model
                  </button>
                </td>
                <td className='px-6 py-3 whitespace-nowrap'>
                  <button
                      className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                      onClick={() => openModal(data)}
                  >
                      Edit Model
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <UpdateModal isOpen={isModalOpen} onClose={closeModal}>
          <ModelForm selectedBrand={selectedBrand} onClose={closeModal}/>
      </UpdateModal>
    </div>
  );
};

export default ModelTable;
