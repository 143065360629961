import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReferralTable from '../components/ReferralTable';
import { referralData } from '../data';

const ReferralLists = () => {
  const tableHeaders = ['User Profile', 'Total Referral Done', 'Total Paid Referral', 'Unpaid Referral', 'More Actions'];


  return (
    <div className="w-full py-4">
      <div className='overflow-x-scroll hide-scrollbar'>
        <ReferralTable data={referralData} headers={tableHeaders} />
      </div>
    </div>
  );
};

export default ReferralLists;
