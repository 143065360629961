import React from 'react'
import ForgetPassForm from '../../containers/Auth/ForgetPassForm'

const ForgetPassword = () => {
  return (
    <div>
        <ForgetPassForm/>
    </div>
  )
}

export default ForgetPassword