import React from 'react';

const SummaryCardOne = ({ number, text, includeNairaSign, icon }) => {

  // const formattedNumber = includeNairaSign ? `₦${number}` : `${number}k`;

  return (
    <div className="bg-primary rounded-lg p-4 relative">
      <div className="flex items-center relative z-10">
        <span className="text-[14px] flex-1">{text}</span>
        <div className='flex-1 flex justify-center'>
          {icon}
        </div>
        <span className="text-3xl font-semibold flex-1 flex justify-end">{number}</span>
      </div>
    </div>
  );
};

export default SummaryCardOne;
