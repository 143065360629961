import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const baseURL = 'https://urchin-app-2-y4xho.ondigitalocean.app/admin';


const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.log(error.response.data.status_code)
    if (error.response && error.response.data.status_code === 401) {
      const token = Cookies.get('token');
      if (token) {
        try {
          const newAccessToken = await refreshAccessToken(token);
          Cookies.set('token', newAccessToken);
          const originalRequest = error.config;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          window.location.href = '';
          toast.error("Your session has ended. Please login again")
        }
      } else {
        window.location.href = '';
        toast.error("Your session has ended. Please login again")

      }
    }
    return Promise.reject(error);
  }
);


async function refreshAccessToken(token) {
  try {
    const response = await axios.get(`${baseURL}/auth/refreshToken`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const newAccessToken = response.data.accessToken;
    return newAccessToken;
  } catch (error) {
    throw new Error('Failed to refresh access token');
  }
}

export default axiosInstance;
