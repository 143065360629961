import React, { useState } from 'react';
import ReviewCard from '../components/ReviewCard';

const ListedReview = ({ userReview }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % userReview.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? userReview.length - 1 : prevIndex - 1));
  };

  if (!userReview || userReview.length === 0) {
    return <div>No reviews available.</div>;
  }

  const isLastCard = currentIndex === userReview.length - 1;
  const isFirstCard = currentIndex === 0;

  return (
    <div className='w-full relative pb-10 border-b-[1px]'>
      <button
        className={`absolute top-1/2 -left-5 transform -translate-y-1/2 bg-brand px-4 py-2 rounded-[100%] z-20 ${isFirstCard ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={prevSlide}
        disabled={isFirstCard}
      >
        {'<'}
      </button>

      <h4 className='text-grey-7 font-semibold mb-4 text-[18px]'>Reviews ({userReview.length})</h4>
      <div className='w-full overflow-hidden'>
        <div
          className='grid grid-cols-4 gap-6 transition-transform duration-300 ease-in-out'
          style={{
            transform: `translateX(-${currentIndex * 25}%)`,
            display: 'flex',
          }}
        >
          {userReview.map((card, index) => (
            <div
              key={index}
              style={{
                flex: '0 0 25%', 
              }}
            >
              <ReviewCard {...card} />
            </div>
          ))}
        </div>
      </div>
      <button
        className={`absolute top-1/2 -right-5 transform -translate-y-1/2 bg-brand px-4 py-2 rounded-full ${isLastCard ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={nextSlide}
        disabled={isLastCard}
      >
        {'>'}
      </button>
    </div>
  );
};

export default ListedReview;
