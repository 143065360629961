import { useSelector } from 'react-redux';
import { selectAuthenticated, selectUserRole } from '../store/slices/authSlice';

const useRequireAuth = () => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const userRole = useSelector(selectUserRole);

  return { isAuthenticated, userRole };
};

export default useRequireAuth;
