import React from 'react';

const AmountFormatter = ({ amount }) => {
  const formatAmount = (value) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    } else {
      return value.toString();
    }
  };

  return (
    <span className='text-[12px] text-green-5'>
      {formatAmount(amount)}
    </span>
  );
};

export default AmountFormatter;
