import React from 'react';
import { FaThumbsUp } from 'react-icons/fa';
import { BiMoney } from 'react-icons/bi';
import useImageLoader from './useImageLoader';

const CarCard1 = ({ status, availability, photoUrl, brandName, brandModelName, pricePerDay }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();

    return (
        <div className='w-fit h-full flex flex-col'>
            <img
                src={photoUrl}
                className='w-full h-full rounded-md'
                alt="proof"
                onLoad={handleImageLoad}
                style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
            />
            <div className='flex justify-between mt-4'>
                <h4 className='text-[14px] font-semibold text-grey-7'>{brandName} {brandModelName}</h4>
                <div className='flex items-center gap-1 text-custom-secondary whitespace-nowrap'>
                    <span className='text-grey-7'><FaThumbsUp /></span>
                    {/* <span className=''>{rating}%</span> */}
                    <span className='text-grey-4 capitalize'>{availability}</span>
                </div>
            </div>
            <div  className='flex items-center gap-1 text-grey-4 mt-2'>
                <span className='text-grey-7'><BiMoney /></span>
                <span className='ml-1'>₦{pricePerDay}</span>
                <span className='text-grey-7'>₦{pricePerDay}/day</span>
            </div>
        </div>
    );
};

export default CarCard1;
