export {default as logoDark} from "./logo-dark.png"
export {default as logoLight} from "./logo-light.png"
export {default as logoSmall} from "./logo-small.svg"
export {default as car} from "./car.svg"
export {default as variety} from "./variety.svg"
export {default as left} from "./left-cut.svg"
export {default as right} from "./right-cut.svg"
export {default as appShot} from "./appshot.png"
export {default as curveImg} from "./curve.svg"
export {default as wheel} from "./wheel.svg"
export {default as rocket} from "./rocket.svg"
export {default as wallet} from "./wallet.svg"
export {default as phone} from "./phone.svg"
export {default as profilePic} from "./profile.svg"
export {default as customerPic} from "./customer-pic.svg"
export {default as auth1} from "./auth1.png"
export {default as auth2} from "./auth2.png"
export {default as auth3} from "./auth3.png"
export {default as stars} from "./stars.png"
export {default as tick} from "./tick.png"
export {default as proof} from "./proof.png"
export {default as vehicle} from "./vehicle.png"
export {default as document} from "./document.png"
export {default as documentIcon} from "./document-icon.png"
export {default as listedCar} from "./listed-car.png"
export {default as statusIcon} from "./statusIcon.png"
export {default as customerIcon} from "./customerIcon.png"
export {default as ProfileIcon} from "./default-profile.png"
export {default as pen} from "./pen.png"
export {default as heroImg1} from "./hero-img-1.png"
export {default as heroImg2} from "./hero-img-2.png"
export {default as heroImg3} from "./hero-img-3.png"
export {default as toyotaIcon} from "./toyota-icon.png"
export {default as accraIcon} from "./accra-icon.png"
export {default as hondaIcon} from "./honda-icon.png"
export {default as audiIcon} from "./audi-icon.png"
export {default as nissanIcon} from "./nissan-icon.png"
export {default as toyota} from "./toyota.png"
export {default as accra} from "./accra.png"
export {default as audi} from "./audi.png"
export {default as honda} from "./honda.png"
export {default as nissan} from "./nissan.png"
export {default as howToRent} from "./how-to-rent.png"
export {default as banner} from "./banner.png"
export {default as downloadApp} from "./download-app.png"
export {default as relieveJourney} from "./relieve-journey.png"
export {default as testimonialBg} from "./testimonial-bg.png"
export {default as browse1} from "./browse-1.png"
export {default as browse2} from "./browse-2.png"
export {default as appShot1} from "./app-shot-1.png"
export {default as appShot2} from "./app-shot-2.png"
export {default as emailIcon} from "./email-icon.png"
export {default as banner2} from "./banner-2.png"
export {default as learnAboutBg} from "./about-bg.png"
export {default as learnAboutImg} from "./learn-about.png"
export {default as missionVisionImg} from "./mission-vision.png"
export {default as member1} from "./member-1.png"
export {default as member3} from "./member-3.png"
export {default as member4} from "./member-4.png"
export {default as member5} from "./member-5.png"
export {default as member6} from "./member-6.png"
export {default as member7} from "./member7.jpg"
export {default as member8} from "./member8.jpg"
export {default as howItWorksBg} from "./how-it-works-bg.png"
export {default as howItWorksImg} from "./how-it-works-img.png"
export {default as howItWorksCar} from "./how-it-works-car.png"
export {default as howItWorksBanner} from "./how-it-works-banner.png"
export {default as personIcon} from "./person-icon.png"
export {default as partnerFeature1} from "./features-for-partner-1.png"
export {default as partnerFeature2} from "./features-for-partner-2.png"
export {default as partnerFeature3} from "./features-for-partner-3.png"
export {default as partnerFeatureBanner} from "./features-for-partner-banner.png"
export {default as partnerFeatureCar} from "./features-for-partner-car.png"
export {default as partnerFeatureBg} from "./features-for-partner-bg.png"
export {default as blogBg} from "./blog-bg.png"
export {default as blog1} from "./blog-1.png"
export {default as blog2} from "./blog-2.png"
export {default as blog3} from "./blog-3.png"
export {default as blog4} from "./blog-4.png"
export {default as blog5} from "./blog-5.png"
export {default as blog6} from "./blog-6.png"
export {default as enquiries} from "./enquiries.png"
export {default as contactImg} from "./contact-img.png"
export {default as carFloor} from "./car-floor.png"
export {default as carIcon} from "./car-icon.png"
export {default as line} from "./line.png"
export { default as brand1 } from "./brand-1.png";
export { default as brand2 } from "./brand-2.png";
export { default as brand3 } from "./brand-3.png";
export { default as brand4 } from "./brand-4.png";
export { default as brand5 } from "./brand-5.png";
export { default as brand6 } from "./brand-6.png";
export { default as brand7 } from "./brand-7.png";
export { default as brand8 } from "./brand-8.png";
export { default as brand9 } from "./brand-9.png";
export { default as brand10 } from "./brand-10.png";
export { default as brand11 } from "./brand-11.png";
export { default as brand12 } from "./brand-12.png";
export { default as brand13 } from "./brand-13.png";
export { default as brand14 } from "./brand-14.png";
export { default as brand15 } from "./brand-15.png";
export { default as brand16 } from "./brand-16.png";
export { default as brand17 } from "./brand-17.png";
export { default as brand18 } from "./brand-18.png";
export { default as brand19 } from "./brand-19.png";
export { default as brand20 } from "./brand-20.png";
export { default as brandIcon1 } from "./brand-icon-1.png";
export { default as brandIcon2 } from "./brand-icon-2.png";
// export { default as brandIcon3 } from "./brand-icon-3.png";
export { default as brandIcon4 } from "./brand-icon-4.png";
export { default as brandIcon5 } from "./brand-icon-5.png";
export { default as brandIcon6 } from "./brand-icon-6.png";
export { default as brandIcon7 } from "./brand-icon-7.png";
export { default as brandIcon8 } from "./brand-icon-8.png";
export { default as brandIcon9 } from "./brand-icon-9.png";
export { default as brandIcon10 } from "./brand-icon-10.png";
export { default as brandIcon11 } from "./brand-icon-11.png";
export { default as brandIcon12 } from "./brand-icon-12.png";
export { default as brandIcon13 } from "./brand-icon-13.png";
// export { default as brandIcon14 } from "./brand-icon-14.png";
export { default as brandIcon15 } from "./brand-icon-15.png";
export { default as brandIcon16 } from "./brand-icon-16.png";
export { default as brandIcon17 } from "./brand-icon-17.png";
export { default as brandIcon18 } from "./brand-icon-18.png";
export { default as brandIcon19 } from "./brand-icon-19.png";
export { default as brandIcon20 } from "./brand-icon-20.png";




