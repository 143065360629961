import React from 'react';
import useImageLoader from '../../../components/useImageLoader';

const TripStatusPart = ({ number, statusType, svgPath }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();
  return (
    <div className="bg-transaprent relative flex flex-col gap-2">
      <div className='flex justify-between gap-4'>
        <div className='p-2 bg-primary rounded-md'>
        <img
            src={svgPath}
            alt="icon"
            onLoad={handleImageLoad}
            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
        />
        </div>
        <p className='font-medium text-[14px] text-grey-4'>{statusType}</p>
      </div>
      <p className='text-[18px] font-semibold text-grey-6'>{number}</p>
    </div>
  );
};

export default TripStatusPart;
