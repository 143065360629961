import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux';
import { auth1 } from '../../../../assets';
import { Link, useNavigate } from 'react-router-dom';
import { authenticateUser } from '../../store/slices/authSlice';

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isLoading = useSelector(state => state.auth.isLoading);


    const handleLogin = (email, password) => {
        dispatch(authenticateUser({ email, password, navigate }))
         
      };

    const initialValues = {
        email: '',
        password: '',
      };

    

    //   const passwordRegex =
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required("Password is required")
    });

    const handleSubmit = async (values) => {
        try {
            const response = await handleLogin(values.email, values.password);
            
        } catch (error) {
            console.error('An error occurred. Please try again later.');
        }
    };

    const divStyle = {
    backgroundImage: `url(${auth1})`,
    };
  return (
    <div className='w-full flex h-screen'>
        <div className='w-full h-full py-12 lg:w-1/2 bg-main-bg'>
            <div className="flex w-4/5 mx-auto mb-32">
                <svg width="44" height="22" viewBox="0 0 44 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.7338 21.8089V10.7622H16.2174L20.1525 14.9344H24.1349V17.0204L11.5237 17.3049C9.53247 17.5103 4.64917 16.6885 4.64917 10.7622C4.91783 8.66034 6.66887 4.44712 11.5237 4.40919H38.8797L34.6601 0H9.15318C5.78703 0.237053 0.154645 3.40408 0.00293124 10.7622C-0.09189 14.3022 2.07952 21.4675 11.5237 21.8089H28.7338Z" fill="#ECAE00"/>
                    <path d="M12.5193 6.21079L15.7907 9.71918H29.7768V21.8089H33.8067V9.71918H34.8497C34.3914 8.96061 33.8067 7.5857 34.8497 6.21079H12.5193Z" fill="#1A1300"/>
                    <path d="M38.8797 9.71918C39.4012 8.99222 40.0649 7.72793 38.8797 6.21079H40.4916L44 9.71918H38.8797Z" fill="#1A1300"/>
                    <path d="M34.8497 10.7622H38.8797V21.8089H34.8497V10.7622Z" fill="#ECAE00"/>
                    <circle cx="36.9583" cy="7.89241" r="1.53938" fill="#ECAE00"/>
                </svg>
            </div>
            <div className='flex w-4/5 md:w-3/5 mx-auto flex-col items-center justify-center '>
                <h3 className='text-[24px] lg:text-[40px] text-tertiary font-semibold'>Welcome Back</h3>
                <p className='text-[16px] text-tertiary font-medium'>Log in to your GTI Ride</p>

                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                >
                    <Form className='w-full flex flex-col gap-4 mt-6'>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Email</label>
                            <Field
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter your email address"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <div className={`flex flex-col w-full gap-2 `}>
                            <label className='text-[14px] font-semibold text-custom-secondary'>Password</label>
                            <Field
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Enter your password"
                                className={`pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2  sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light`}
                            />
                        </div>
                        <Link to="auth/forget-password"><button type='button' className='bg-transaprent text-primary text-[14px] text-left mb-3'>Forgot password?</button></Link>
                            <button
                                type="submit"
                                className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                            >
                                {isLoading ? 
                                <ClipLoader
                                    color="custom-primary"
                                    loading="loading"
                                    css="override"
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                                :
                                'Login'
                                }
                            </button>
                        

                    </Form>
                </Formik>
            </div>
        </div>
        <div className='hidden lg:block lg:w-1/2 bg-black bg-cover bg-center bg-no-repeat h-screen' style={divStyle}/>
    </div>
  )
}

export default LoginForm