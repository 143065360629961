import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getReferrals, payUserReferral } from '../../helpers/api';
import { toast } from 'react-toastify';

// Fetch referrals action (unchanged)
export const fetchReferrals = createAsyncThunk(
  'referral/fetchReferrals',
  async ({ skip = 0, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await getReferrals(skip, limit);
      const responseData = {
        data: response.data,
        status: response.status_code,
        
      };
  
      if (responseData.status === 200) {
        toast.success("Referral data updated successfully");
      }
      return responseData.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

// Updated payReferral action to handle userID, numberOfRefs, and override
export const payReferral = createAsyncThunk(
  'referral/payReferral',
  async ({ userID, numberOfRefs, override, dispatch }, { rejectWithValue }) => {
    try {
      const response = await payUserReferral({ userID, numberOfRefs, override });
      if (response.status_code === 200) {
        toast.success("Referral data updated successfully");
        dispatch(fetchReferrals());
        return response.data;
      } else {
        throw new Error("Payment failed");
      }
    } catch (error) {
      toast.error(error.message || "Payment failed");
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);


const referralSlice = createSlice({
  name: 'referral',
  initialState: {
    referrals: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling fetchReferrals states
      .addCase(fetchReferrals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.referrals = action.payload;
      })
      .addCase(fetchReferrals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      
      // Handling payReferral states
      .addCase(payReferral.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(payReferral.fulfilled, (state, action) => {
        state.isLoading = false;
        // Update the referral list if needed, you can adjust this part
        state.referrals = state.referrals.map((ref) =>
          ref.userId === action.payload.userId
            ? { ...ref, paid: true, numberOfRefs: action.payload.numberOfRefs } // Update with new data
            : ref
        );
      })
      .addCase(payReferral.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default referralSlice.reducer;
