import React from 'react';
import { Navigate } from 'react-router-dom';
import useRequireAuth from '../helpers/useRequireAuth';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, userRole } = useRequireAuth();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/dashboard" />; // Redirect unauthorized users
  }

  return React.cloneElement(children, { userRole });
};

export default ProtectedRoute;
