import React from 'react'
import ChangePassForm from '../../containers/Auth/ChangePassForm'

const ChangePassword = () => {
  return (
    <div className='w-full flex flex-col gap-12'>
      <div className=''>
          <h4 className='font-semibold text-grey-4 text-[16px] py-4'>Profile / <span className='text-grey-7'> Reset Password</span></h4>
          <ChangePassForm />
      </div>
    </div>
  )
}

export default ChangePassword