import React from 'react'
import ModelLists from '../../containers/MasterData/ModelLists'

const Models = () => {
  return (
    <ModelLists/>
    
  )
}

export default Models