import React from 'react';
import { BiMessage } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { customerPic } from '../../assets';
import useImageLoader from './useImageLoader';


const UserTable = ({ headers, data }) => {
    const { imageLoaded, handleImageLoad } = useImageLoader();
    const handleSendEmail = (email) => {
      window.location.href = `mailto:${email}?subject=Message from GTIRides&body=Hello,`;
  };
  return (
    <table className="w-full table-auto text-grey-6 mb-20">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header} className="px-6 py-3 text-left whitespace-nowrap">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className={`px-6 ${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'} hover:cursor-pointer hover:bg-comp-7`} >
            {headers.map((header) => (
              <td key={header} className="px-6 py-3">
                {header === 'Name' ? (
                    <div className="flex items-center gap-4">
                        <div className='w-8 h-8 flex justify-center items-center text-white rounded-md'>
                        <img
                            src={customerPic}
                            className='w-8 h-8 rounded-md'
                            alt="Customer Pic"
                            onLoad={handleImageLoad}
                            style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s' }}
                            />
                        </div>
                        <div className='flex flex-col'>
                          <Link to={`/user-management/${row['User ID']}`}>
                            <p className='text-[14px] font-semibold text-grey-6'>{row['Name'].fullName}</p>
                        
                            <p className='text-sm'>{row['Name'].email}</p>
                          </Link>
                        </div>
                    </div>
                ) : header === 'Role' ? (
                    <div className='flex flex-col gap-1'>
                    <p className='text-[14px] font-semibold text-grey-6'>{row['Role'].position}</p>
                    <p className='text-sm'>{row['Role'].organization}</p>
                </div>
                 ) : header === 'Requested Date' ? (
                  <div>
                    {row['Requested Date'] && (
                      <p className='text-sm whitespace-nowrap'>
                        {new Date(row['Requested Date']).toLocaleString('en-US', {
                          weekday: 'short',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        })}
                      </p>
                    )}
                  </div>
                ) : header === 'Others' ? (
                    <div className='flex items-center gap-2'>
                        <button
                            className="flex items-center gap-2 text-primary whitespace-nowrap"
                            onClick={() => handleSendEmail(row['Name'].email)}
                        >
                            <BiMessage />
                            {row['Others']}
                        </button>
                    </div>
                ) : header === 'Role' ? (
                    <div className='flex flex-col gap-1'>
                    <p className='text-[14px] font-semibold text-grey-6'>{row['Role'].position}</p>
                    <p className='text-sm'>{row['Role'].organization}</p>
                </div>
                ) : (
                  row[header]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserTable;
