import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import 'react-datepicker/dist/react-datepicker.css';
import ConfirmationModal from '../components/ConfirmationModal';
import Dropdown2 from '../components/Dropdown2';
import { useDispatch } from 'react-redux';
import { sendBroadcastMessage } from '../store/slices/broadcastSlice';

const BroadcastForm = () => {
  const [messageFromServer] = useState('');
  const [errorFromServer] = useState('');
  const [buttonSubmitting, setButtonSubmitting] = useState(false);
  const [status] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Push Notification');
  const [dropDown, setDropDown] = useState('all'); // Default value is 'all'
  const options = ['all', 'owner', 'renter'];

  // Fetch loading, success, and error states from Redux
  const dispatch = useDispatch();

  const initialValues = {
    titleOfMessage: '',
    bodyOfMessage: '',
  };

  const handleSubmit = async (values) => {
    setButtonSubmitting(true);

    try {
      // Dispatch the action to send the broadcast message
      await dispatch(sendBroadcastMessage({
        title: values.titleOfMessage,
        type: selectedTab === 'Push Notification' ? 'push' : 'email', // Dynamically set type
        to: dropDown, // Pass selected dropdown value (all, owner, renter)
        body: values.bodyOfMessage, // Body in HTML format
      })).unwrap();
      
      setShowModal(true);
      setButtonSubmitting(false);
    } catch (error) {
      setButtonSubmitting(false);
      // Handle error if dispatch fails
    }
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleDropDownChange = (unit) => {
    setDropDown(unit);
  };

  return (
    <>
      <div className="w-full">
        <div className="">
          <div className="flex gap-4 mb-8">
            <button
              className={`border-b-[3px] whitespace-nowrap ${
                selectedTab === 'Push Notification' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'
              } py-2 px-2 text-sm md:text-[18px] w-full`}
              onClick={() => {
                setSelectedTab('Push Notification');
              }}
            >
              Push Notification
            </button>
            <button
              className={`border-b-[3px] whitespace-nowrap ${
                selectedTab === 'Email' ? 'text-brand border-brand' : 'text-grey-5'
              } py-2 px-2 text-sm md:text-[18px] w-full`}
              onClick={() => {
                setSelectedTab('Email');
              }}
            >
              Email
            </button>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Form className="w-full flex flex-col gap-4 mt-6">
            <div className="flex flex-col gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                {selectedTab === 'Email'
                  ? 'Select who receives email'
                  : 'Select who receives notifications'}
              </label>
              <div className="w-full capitalize">
                <Dropdown2
                  dropDown={dropDown}
                  handleDropDownChange={handleDropDownChange}
                  options={options}
                />
              </div>
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                Title of Message
              </label>
              <Field
                type="text"
                id="titleOfMessage"
                name="titleOfMessage"
                placeholder="Input Title"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-[14px] font-semibold text-custom-secondary">
                Body of Message (Maximum of 50 words, HTML format required)
              </label>
              <Field
                as="textarea"
                id="bodyOfMessage"
                name="bodyOfMessage"
                rows="6"
                placeholder="Input Body"
                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
              />
            </div>

            <button
              type="submit"
              className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
            >
              {buttonSubmitting ? (
                <ClipLoader
                  color="custom-primary"
                  loading="loading"
                  css="override"
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : selectedTab === 'Email' ? (
                'Send Email'
              ) : (
                'Send Notification'
              )}
            </button>
          </Form>
        </Formik>
      </div>
      <ConfirmationModal
        message={messageFromServer || errorFromServer}
        isOpen={showModal}
        onClose={closeModal}
        status={status}
      />
    </>
  );
};

export default BroadcastForm;
