import React, { useState } from 'react';
import ReviewCard from '../components/ReviewCard';

const VehicleReviews = ({carReview}) => {
      
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carReview.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? carReview.length - 1 : prevIndex - 1));
    };

    const isLastCard = currentIndex === carReview.length - 1;
    const isFirstCard = currentIndex === 0;

      

    if (!carReview) {
        return null;
    }

    return (
        <div className='w-full relative pb-10 border-b-[1px]'>
            <button
                className={`absolute top-1/2 -left-5 transform -translate-y-1/2 bg-brand px-4 py-2 rounded-[100%] z-20 ${isFirstCard ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={prevSlide}
                disabled={isFirstCard}
            >
                {'<'}
            </button>

            <h4 className='text-grey-7 font-semibold mb-4 text-[18px]'>Reviews ({carReview.length})</h4>
            <div className='w-full overflow-hidden'>
                <div
                    className='grid grid-cols-4 gap-6 transition-transform duration-300 ease-in-out'
                    style={{
                        transform: `translateX(-${currentIndex * 25}%)`,
                        display: 'flex',
                    }}
                >
                    {carReview.map((card, index) => (
                        <div
                            key={index}
                            style={{
                                flex: '0 0 25%', 
                            }}
                        >
                            <ReviewCard {...card} />
                        </div>
                    ))}
                </div>
            </div>
            <button
                className={`absolute top-1/2 -right-5 transform -translate-y-1/2 bg-brand px-4 py-2 rounded-full ${isLastCard ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={nextSlide}
                disabled={isLastCard}
            >
                {'>'}
            </button>
        </div>
    );
};

export default VehicleReviews;
