import React from 'react';
import SummaryCardTwoPart from '../partials/SummaryCardTwoPart';

const SummaryCardTwo = ({ data }) => {
  return (
    <div className='flex flex-col lg:flex-row w-full gap-2'>
      {data.map((item, index) => (
        <SummaryCardTwoPart key={index} number={item.number} text={item.text} bgColor={item.bgColor} icon={item.icon} />
      ))}
    </div>
  );
};

export default SummaryCardTwo;
