import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import Loader from '../Loader';
import EmptyData from '../EmptyData';
import { useLocation, useParams } from 'react-router-dom';
import { deleteVehicleYear } from '../../store/slices/vehicleSlice';
import { useDispatch } from 'react-redux';
import UpdateModal from './UpdateModal';
import YearForm from './YearForm';
import { showCustomConfirmAlert } from '../CustomConfirmAlert';
import { toast } from 'react-toastify';

const YearTable = ({ dataList, loading }) => {
  const location = useLocation();
  const { brandName, modelName } = location.state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  const openModal = (brand) => {
      setSelectedYear(brand);
      setIsModalOpen(true);
  };
  
  const closeModal = () => {
      setSelectedYear(null);
      setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const {brandCode, modelCode} = useParams();
  if (loading) {
    return (
      <Loader loading={loading}/>
    );
  }

  if (!dataList) {
    return null;
  }

  if (!dataList || dataList.length === 0) {
    return <EmptyData/>;
  }
  const filteredDataList = dataList.filter(data => data !== undefined);

  const handleDeleteClick = ({ modelCode, brandCode, yearCode }) => {
    showCustomConfirmAlert(() => {
      dispatch(deleteVehicleYear({ modelCode, brandCode, yearCode }))
        .unwrap()
        .catch(error => {
          toast.error(error.message);
        });
    });
  };

  return (
    <div className='w-full'>
      <table className='w-full table-auto text-grey-6'>
        <thead>
          <tr>
            <th className='px-6 py-3 text-left whitespace-nowrap'>S/N</th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>Vehicle Brand</th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>Vehicle Model</th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>Year Name</th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>Proposed Price</th>
            <th className='px-6 py-3 text-left whitespace-nowrap'>Caution fee</th>
            <th className='px-6 py-3 text-left whitespace-nowrap'></th>
            <th className='px-6 py-3 text-left whitespace-nowrap'></th>
          </tr>
        </thead>
        <tbody>
          {filteredDataList.map((data, index) => (
            <tr key={index} className={`px-6 ${index % 2 === 0 ? 'bg-comp-6' : 'bg-transparent'}`}>
              <td className='px-6 py-3 whitespace-nowrap'>{index + 1}</td>
              <td className='px-6 py-3 whitespace-nowrap'>{brandName}</td>
              <td className='px-6 py-3 whitespace-nowrap'>{modelName}</td>
              <td className='px-6 py-3 whitespace-nowrap'>{data?.yearName}</td>
              <td className='px-6 py-3 whitespace-nowrap'>{data?.advicePrice}</td>
              <td className='px-6 py-3 whitespace-nowrap'>{data?.cautionFee}</td>
              <td className='px-6 py-3 whitespace-nowrap'>
                  <button
                      className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                      onClick={() => handleDeleteClick({ modelCode: modelCode, brandCode: brandCode, yearCode: data.yearCode })}
                  >
                      Delete Year
                  </button>
                </td>
                <td className='px-6 py-3 whitespace-nowrap'>
                  <button
                      className='text-brand bg-transparent font-bold py-2 px-4 rounded w-fit'
                      onClick={() => openModal(data)}
                  >
                      Edit Year
                  </button>

                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <UpdateModal isOpen={isModalOpen} onClose={closeModal}>
          <YearForm selectedYear={selectedYear} onClose={closeModal}/>
      </UpdateModal>
    </div>
  );
};

export default YearTable;
