import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrand, getFeatures, getTransmission, getState, getCity, getVehicleType, getSeat, getInsuranceType, getTripsData } from '../../store/slices/vehicleSlice';
import MasterDataTable from '../../components/MasterData/MasterDataTable';
import Loader from '../../components/Loader';

const MasterDataLists = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.vehicle.brands);
  const states = useSelector((state) => state.vehicle.states);
  const features = useSelector((state) => state.vehicle.features);
  const transmissions = useSelector((state) => state.vehicle.transmissions);
  const seats = useSelector((state) => state.vehicle.seats);
  const insuranceTypes = useSelector((state) => state.vehicle.insuranceTypes);
  const vehicleTypes = useSelector((state) => state.vehicle.vehicleTypes);
  const tripsData = useSelector((state) => state.vehicle.tripsData);
  const loading = useSelector((state) => state.vehicle.isLoading);


  useEffect(() => {
    dispatch(getBrand());
    dispatch(getState());
    dispatch(getCity());
    dispatch(getFeatures());
    dispatch(getTransmission());
    dispatch(getVehicleType());
    dispatch(getSeat());
    dispatch(getInsuranceType());
    dispatch(getTripsData());
  }, [dispatch]);

  const dataList = [
    { name: 'State', total: states?.length },
    { name: 'Brand', total: brands?.length },
    { name: 'Features', total: features?.length },
    { name: 'Transmission', total: transmissions?.length },
    { name: 'Seats', total: seats?.length },
    { name: 'Vehicle Types', total: vehicleTypes?.length },
    { name: 'Insurance Types', total: insuranceTypes?.length },
    { name: 'Trips Data', total: tripsData?.length },
  ];

  return (
    <>
      {loading ? (
        <Loader loading={loading}/>
      ) : (
        <MasterDataTable dataList={dataList} />
      )}
    </>
  );
};

export default MasterDataLists;
