import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModelTable from '../../components/MasterData/ModelTable';
import ModelForm from '../../components/MasterData/ModelForm';
import { useParams } from 'react-router-dom';
import { getBrandModel } from '../../store/slices/vehicleSlice';

const ModelLists = () => {
    const [selectedTab, setSelectedTab] = useState('listed');
    const { brandCode } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchModel = async () => {
            try {
                await dispatch(getBrandModel({ brandCode }));
            } catch (error) {
                console.error(`Error fetching models for brand`, error);
            }
        };

        fetchModel();
    }, [brandCode, dispatch]);

    const vehicles = useSelector(state => state.vehicle);
    const models = vehicles.brandModels;
    const loading = vehicles.isLoading;
    

    return (
        <div className="w-full">
            <div className='overflow-x-scroll hide-scrollbar'>
                <div className='flex gap-4 mb-4'>
                    <button
                        className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'listed' ? 'text-brand border-brand' : 'text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
                        type='button'
                        onClick={() => {
                            setSelectedTab('listed');
                        }}
                    >
                        Listed Models <span className='bg-success text-white rounded-md px-3 py-1 text-[12px]'>{models ? models.length : 0}</span>
                    </button>
                    <button
                        type='button'
                        className={`border-b-[3px] whitespace-nowrap ${selectedTab === 'new' ? 'text-brand border-brand' : 'bg-transparent text-grey-5'} py-2 px-2 text-sm md:text-[18px]`}
                        onClick={() => {
                            setSelectedTab('new');
                        }}
                    >
                        Add New Model
                    </button>
                </div>
            </div>
            {selectedTab === 'listed' && models ? (
                <div className='overflow-x-scroll hide-scrollbar'>
                    <ModelTable dataList={models} loading={loading}  />
                </div>
            ) : (
                <ModelForm setSelectedTab={setSelectedTab}/>
            )}
        </div>
    );
};

export default ModelLists;
