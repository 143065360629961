import React from 'react'
import ReferralLists from '../containers/ReferralLists'

const Referrals = () => {
  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='w-full flex flex-col lg:flex-row gap-6'>
        <ReferralLists/>
      </div>
    </div>
  )
}

export default Referrals


    